import * as React from "react";
import {
    ActionButton,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    Layer, mergeStyleSets,
    Overlay, PersonaPresence,
    Popup, Rating, RatingSize,
    SelectionMode,
    Stack
} from "@fluentui/react";
import {useEffect, useRef, useState} from "react";
import {useBoolean} from "@fluentui/react-hooks";
import {Persona, PersonaSize} from "@fluentui/react/lib/Persona";
import getFreelancersCollectionMethod from "../../../../api/getFreelancersCollectionMethod";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import rateFreelancerMethod from "../../../../api/rateFreelancerMethod";

const popupStyles = mergeStyleSets({
    root: {
        background: 'rgba(0, 0, 0, 0.2)',
        bottom: '0',
        left: '0',
        position: 'fixed',
        right: '0',
        top: '0',
    },
    content: {
        background: 'white',
        left: '50%',
        maxWidth: '80%',
        padding: '0 2em 3em',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
});

export default function RateFreelancers({projectId, passedScores, specializations, participants, reloadData}) {
    const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
    const popupRef = useRef(null);
    const [scores, setScores] = useState([]);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.classList.contains('ms-Button-flexContainer') && !event.target.classList.contains('ms-Dropdown-optionText') && event.target.nodeName !== 'INPUT') {
            hidePopup();
        }
    };

    useEffect(() => {
        setScores(passedScores);

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [passedScores]);

    if (!isPopupVisible) {
        if (scores.length > 0) {
            return <ActionButton iconProps={{ iconName: 'FavoriteStarFill' }} allowDisabledFocus onClick={showPopup} style={{marginTop: 6}}>
                Изменить оценку исполнителей
            </ActionButton>;
        }

        return <ActionButton iconProps={{ iconName: 'FavoriteStarFill' }} allowDisabledFocus onClick={showPopup} style={{marginTop: 6}}>
            Оценить исполнителей
        </ActionButton>;
    }

    const setScoresValue = (userId, key, value) => {
        let exist = false;

        const newScores = [];
        for (const item of scores) {
            if (item.userId === userId) {
                exist = true;
                item[key] = value;
            }
            newScores.unshift(item);
        }

        if (!exist) {
            const item = {
                userId: userId,
                specialization: null,
                qualityScore: null,
                communicationScore: null,
                speedScore: null,
            };
            item[key] = value;
            newScores.unshift(item);
        }

        setScores(newScores);

        // const newScores = scores;
        // if (!newScores[id]) {
        //     newScores[id] = [];
        // }
        // newScores[id][key] = value;
        // setScores(newScores);
    };

    const setScoresSpecialization = (id, option) => {
        const newScores = scores;
        if (!newScores[id]) {
            newScores[id] = [];
            newScores[id]['specialization'] = [];
        }
        newScores[id]['specialization'] = option.selected ? [...newScores[id]['specialization'], option.key] : newScores[id]['specialization'].filter(key => key !== option.key)
        setScores(newScores);
    };

    const columns = [
        {
            key: 'name',
            name: 'Имя',
            fieldName: 'name',
            minWidth: 260,
            maxWidth: 320,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                const selectedSpecializations = specializations.data.filter((el) => el.key === item.profile.specialization);
                const secondaryText = selectedSpecializations.length > 0 ? selectedSpecializations[0].text : null;
                const text = item.firstName + ' ' + (item.lastName ?? '');

                return <Persona text={text} secondaryText={secondaryText} size={PersonaSize.size40} />
            }
        },
        {
            key: 'specialization',
            name: 'Специализация',
            fieldName: 'specialization',
            maxWidth: 200,
            minWidth: 180,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                const currentValue = scores.filter((score) => score.userId === item.id);
                return <Dropdown
                    placeholder="Не выбрана"
                    options={[
                        {key: 'арт-директринг', text: 'арт-директринг'},
                        {key: 'айдентика', text: 'айдентика'},
                        {key: 'упаковка', text: 'упаковка'},
                        {key: 'web', text: 'web'},
                        {key: 'иллюстрация', text: 'иллюстрация'},
                        {key: 'бренд-стратегия', text: 'бренд-стратегия'},
                        {key: 'нейминг', text: 'нейминг'},
                        {key: 'копирайт', text: 'копирайт'},
                    ]}
                    selectedKey={currentValue.length === 1 ? currentValue[0].specialization : null}
                    styles={{dropdown: { width: 180 }}}
                    onChange={(e, option) => setScoresValue(item.id, 'specialization', option.key)}
                    // multiSelect={true}
                />
            }
        },
        {
            key: 'qualityScore',
            name: 'Качество',
            fieldName: 'qualityScore',
            maxWidth: 200,
            minWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                const currentValue = scores.filter((score) => score.userId === item.id);
                return <Rating
                    max={5}
                    size={RatingSize.Large}
                    rating={currentValue.length === 1 ? currentValue[0].qualityScore : null}
                    allowZeroStars
                    ariaLabel="Оценка качества"
                    onChange={async (e, rating) => {
                        setScoresValue(item.id, 'qualityScore', rating);
                    }}
                />;
            }
        },
        {
            key: 'communicationScore',
            name: 'Коммуникация',
            fieldName: 'communicationScore',
            maxWidth: 200,
            minWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                const currentValue = scores.filter((score) => score.userId === item.id);
                return <Rating
                    max={5}
                    size={RatingSize.Large}
                    rating={currentValue.length === 1 ? currentValue[0].communicationScore : null}
                    allowZeroStars
                    ariaLabel="Оценка коммуникации"
                    onChange={async (e, rating) => {
                        setScoresValue(item.id, 'communicationScore', rating);
                    }}
                />;
            }
        },
        {
            key: 'speedScore',
            name: 'Скорость',
            fieldName: 'speedScore',
            maxWidth: 200,
            minWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                const currentValue = scores.filter((score) => score.userId === item.id);
                return <Rating
                    max={5}
                    size={RatingSize.Large}
                    rating={currentValue.length === 1 ? currentValue[0].speedScore : null}
                    allowZeroStars
                    ariaLabel="Оценка скорости"
                    onChange={async (e, rating) => {
                        setScoresValue(item.id, 'speedScore', rating);
                    }}
                />;
            }
        },
    ];

    return <>
        <Layer>
            <Popup
                className={popupStyles.root}
                role="dialog"
                aria-modal="true"
                onDismiss={hidePopup}
                enableAriaHiddenSiblings={true}
            >
                <Overlay />
                {/*<FocusTrapZone>*/}
                <div ref={popupRef} role="document" className={popupStyles.content} style={{overflowY: 'auto', maxHeight: '90vh'}}>
                    <Stack horizontal tokens={{ childrenGap: 20 }} style={{marginBottom: 1, alignItems: 'center', justifyContent: 'space-between'}}>
                        <h2>Оценить исполнителей</h2>
                        <DefaultButton onClick={hidePopup}>Закрыть</DefaultButton>
                    </Stack>
                    <DetailsList
                        items={participants}
                        compact={false}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        getKey={(item, index) => item.key}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                    />
                    <PrimaryButton text="Сохранить оценку" style={{marginTop: 40}} onClick={async () => {
                        for (const [key, item] of Object.entries(scores)) {
                            const response = await rateFreelancerMethod(item.userId, projectId, item.specialization, item.qualityScore, item.communicationScore, item.speedScore);
                            reloadData();
                            hidePopup();
                        }
                    }} />
                </div>
                {/*</FocusTrapZone>*/}
            </Popup>
        </Layer>
    </>;
};
