import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import getPaymentMethod from "../../api/getPaymentMethod";
import {Breadcrumb, DetailsList, DetailsListLayoutMode, SelectionMode, TooltipHost} from "@fluentui/react";

export default function ViewOnlinePayment() {
    const navigate = useNavigate();
    const params = useParams();
    const [payment, setPayment] = useState({
        type: 'Предоплата',
        projectId: '',
        projectCode: null,
        title: 'Предоплата за услугу',
        amount: '00.00 ₽',
        customerFullName: '',
        status: 'PAID',
        externalId: null,
        error: null,
        response: null,
        createdAt: null,
        paidAt: null,
    });

    const breadcrumbs = [
        { text: 'Финансы', key: 'financeCollection', href: '/finance#online' },
        { text: 'Платёж к проекту ' + (payment.projectCode ?? ''), key: 'payments', href: '/finance/payments/' + params.id, isCurrentItem: true },
    ];

    useEffect(() => {
        if (!payment.projectId.length > 0) {
            getPaymentMethod(params.id, params.onlinePaymentId).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setPayment(response);
            });
        }
    });

    const columns = [
        {
            key: 'param',
            name: 'Параметр',
            fieldName: 'param',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'value',
            name: 'Значение',
            fieldName: 'value',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    ];

    let status = <div>Не оплачен</div>;

    if (payment.status === 'PAID') {
        status = <div style={{color: 'green'}}>Оплачен</div>
    } else if (payment.status === 'CANCELED') {
        status = <div style={{color: 'red'}}>Отменён</div>
    } else if (payment.status === 'PENDING') {
        status = <div style={{color: 'blue'}}>В обработке</div>
    }

    let projectCode;
    if (payment.projectCode === null) {
        projectCode = <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate('/projects/' + payment.projectId)}>Указать номер</div>;
    } else {
        projectCode = <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate('/projects/' + payment.projectId)}>{payment.projectCode}</div>;
    }

    const items = [
        {
            param: 'Назначение платежа',
            value: payment.title,
        },
        {
            param: 'Статус',
            value: status,
        },
        {
            param: 'Номер проекта',
            value: projectCode,
        },
        {
            param: 'YooMoney ID',
            value: payment.externalId,
        },
        {
            param: 'Сумма',
            value: payment.amount,
        },
        {
            param: 'ФИО клиента',
            value: payment.customerFullName,
        },
        {
            param: 'Создано',
            value: payment.createdAt,
        },
        {
            param: 'Оплачено',
            value: payment.paidAt ?? 'Ещё не оплачено',
        },
    ];

    return (
        <div>
            <Breadcrumb
                items={breadcrumbs}
                maxDisplayedItems={10}
                ariaLabel="Навигация"
                overflowAriaLabel="Показать больше"
                style={{marginBottom: 20}}
            />
            <DetailsList
                items={items}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
            />
        </div>
    );
};
