import * as React from "react";
import {ActionButton, DatePicker, DayOfWeek, Separator, Stack, TextField} from "@fluentui/react";
import addPaymentStepMethod from "../../../../../api/addPaymentStepMethod";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import MoneyField from "../../../../MoneyField";
import {datePickerStringsRu} from "../../../../data/datePickerStringsRu";
import changePaymentStepMethod from "../../../../../api/changePaymentStepMethod";
import removePaymentStepMethod from "../../../../../api/removePaymentStepMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";

export default function PaymentSteps({project, setProject, setNotification, reloadData}) {

    const changePaymentStep = async (id, key, value) => {
        const paymentSteps = project.data.finance.paymentSteps.map((payment) => {
            if (payment.id === id) {
                payment[key] = value;
            }
            return payment;
        });
        setProject({...project, data: {...project.data, finance: {...project.data.finance, paymentSteps: paymentSteps}}});
        await changePaymentStepMethod(project.data.id, id, key, value);
        setNotification({type: 'success', text: 'Данные сохранены'});
    };

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    return <>
        {project.data.finance.paymentSteps.map((payment, i) => {
            return <>
                <Separator vertical />
                <div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
                        <ActionButton style={{position: 'absolute', top: -20}} iconProps={{ iconName: 'Delete' }} allowDisabledFocus onClick={async () => {await removePaymentStepMethod(project.data.id, payment.id); reloadData();}} />
                    </div>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <TextField label="Название" value={payment.title} onChange={(e) => changePaymentStep(payment.id, 'title', e.target.value)} />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <Dropdown
                                placeholder="Выберите"
                                label="Тип"
                                options={[
                                    { key: 'ONLINE', text: 'Онлайн' },
                                    { key: 'CLASSIC', text: 'По документам' },
                                ]}
                                selectedKey={payment.type}
                                styles={{ textField: {color: 'black'}}}
                                onChange={(e, option) => changePaymentStep(payment.id, 'type', option.key)}
                            />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField label="Сумма платежа" value={payment.sum} onChange={(e) => changePaymentStep(payment.id, 'sum', e.target.value)} />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <Dropdown
                                placeholder="Выберите"
                                label="Статус"
                                options={[
                                    { key: 'NOT_NEED', text: 'Не нужна' },
                                    { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },
                                    { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },
                                    { key: 'VERIFYING', text: 'Клиент сказал, что оплатил, проверяем' },
                                    { key: 'PAID', text: 'Оплачено' },
                                ]}
                                selectedKey={payment.status}
                                onChange={(e, option) => changePaymentStep(payment.id, 'status', option.key)}
                            />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <DatePicker
                                firstDayOfWeek={DayOfWeek.Monday}
                                showWeekNumbers={false}
                                firstWeekOfYear={1}
                                showMonthPickerAsOverlay={true}
                                placeholder="Выберите дату"
                                ariaLabel="Выберите дату"
                                label="Дата оплаты"
                                strings={datePickerStringsRu}
                                formatDate={formatDate}
                                value={payment.paidAt !== null ? new Date(payment.paidAt) : null}
                                onSelectDate={(date) => changePaymentStep(payment.id, 'paidAt',  date.toDateString())}
                                disabled={payment.status === 'NOT_NEED'}
                            />
                            {payment.type === 'ONLINE' && payment.status !== 'PAID'
                                && <DefaultButton text="Скопировать ссылку на оплату" iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + payment.onlinePayment.id) && setNotification({type: 'success', text: 'Ссылка на оплату скопирована'})} />
                            }
                        </Stack>
                    </Stack>
                </div>
            </>;
        })}
        <Separator vertical />
        <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={async () => {await addPaymentStepMethod(project.data.id); reloadData();}} />
    </>;
};
