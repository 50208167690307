import * as React from "react";
import {Stack, DayOfWeek, DatePicker} from "@fluentui/react";
import {datePickerStringsRu} from "../../../data/datePickerStringsRu";
import setProjectDeadlineMethod from "../../../../api/setProjectDeadlineMethod";

export default function Deadlines({userType, project, setNotification, reloadData}) {
    const isChangeDisabled = userType === 'FREELANCER';

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    }

    const getPresentationDate = () => {
        if (!project.data.presentationDate) {
            return null;
        }

        return new Date(project.data.presentationDate);
    };

    const getDueDate = () => {
        if (!project.data.dueDate) {
            return null;
        }

        return new Date(project.data.dueDate);
    };

    const getStartDate = () => {
        if (!project.data.startedAt) {
            return null;
        }

        return new Date(project.data.startedAt);
    };

    const handleDeadlineChange = async (type, date) => {
        const response = await setProjectDeadlineMethod(project.data.id, type, date);

        if (response.status === 'ok') {
            setNotification({type: 'success', text: 'Дата сохранена'});
            reloadData();
        }
    };

    return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
        <DatePicker
            firstDayOfWeek={DayOfWeek.Monday}
            showWeekNumbers={false}
            firstWeekOfYear={1}
            showMonthPickerAsOverlay={true}
            placeholder="Выберите дату"
            ariaLabel="Выберите дату"
            label="Дата старта"
            strings={datePickerStringsRu}
            formatDate={formatDate}
            value={getStartDate()}
            onSelectDate={(date) => handleDeadlineChange('START_DATE', date.toDateString())}
            disabled={isChangeDisabled}
            styles={{ textField: {color: 'black'}}}
        />
        {userType === 'FREELANCER'
            ? <></>
            : <>
                <DatePicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers={false}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay={true}
                    placeholder="Выберите дату"
                    ariaLabel="Выберите дату"
                    label="Дата презентации"
                    strings={datePickerStringsRu}
                    formatDate={formatDate}
                    value={getPresentationDate()}
                    onSelectDate={(date) => handleDeadlineChange('PRESENTATION_DATE', date.toDateString())}
                />
                <DatePicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers={false}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay={true}
                    placeholder="Выберите дату"
                    ariaLabel="Выберите дату"
                    label="Дата сдачи работы"
                    strings={datePickerStringsRu}
                    formatDate={formatDate}
                    value={getDueDate()}
                    onSelectDate={(date) => handleDeadlineChange('DUE_DATE', date.toDateString())}
                />
            </>
        }
    </Stack>;
}
