import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function addTeamMemberMethod(id, type, contact, profile) {
    const url = new URL(serverUrl + '/api/v2/pc/add-team-member');

    return fetch(url, {
        method: 'PATCH',
        body: JSON.stringify({id: id, type: type, contact: contact, profile: profile}),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
