import * as React from "react";
import {Stack, TextField} from "@fluentui/react";
import changeRequestCompanyMethod from "../../../../api/changeRequestCompanyMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";
import exportRequisitesToExcelMethod from "../../../../api/exportRequisitesToExcelMethod";
import {datePickerStringsRu} from "../../../data/datePickerStringsRu";
import {RussianName} from "../../../data/RussianName";

export default function Company({project, setProject, setNotification, reloadData}) {
    const change = (key, value) => {
        const company = {...project.data.company, [key]: value};

        if (key !== 'taxIdentificationNumber' && key !== 'bandIdentificationCode') {
            setProject({...project, data: {...project.data, company: company}});

            changeRequestCompanyMethod(project.data.id, company).then(response => {
                if (response.exception || response.error) {
                    console.log(response.error ? response.error.message : response.exception);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    return;
                }

                setNotification({type: 'success', text: 'Данные компании сохранены'});
            });
        } else if ((key === 'taxIdentificationNumber' && (value.length === 10 || value.length === 12 || value.length === 0)) || (key === 'bandIdentificationCode' && (value.length === 9 || value.length === 0))) {
            changeRequestCompanyMethod(project.data.id, company).then(response => {
                if (response.exception || response.error) {
                    console.log(response.error ? response.error.message : response.exception);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    return;
                }

                setNotification({type: 'success', text: 'Данные компании сохранены'});
                reloadData();
            });
        } else {
            setProject({...project, data: {...project.data, company: company}});
        }
    };

    const formatDate = (date) => {
        if (!date) return '';
        const month = `${date.getMonth()}`.padStart(2, "0");
        const day = `${date.getDate()}`.padStart(2, "0");
        const year = date.getFullYear();

        return `${day}.${month}.${year} г.`;
    }

    let fullRussianName = '';
    if (project.data.company.type === 'LEGAL' && project.data.company.managerName) {
        const nameArr = project.data.company.managerName.split(' ');
        const name = nameArr[1]; const surname = nameArr[0]; const patronymic = nameArr[2];
        const russianName = new RussianName(surname, name, patronymic);
        fullRussianName = russianName.lastName('genitive') + ' ' + russianName.firstName('genitive') + ' ' + russianName.middleName('genitive');
    }

    return  <Stack horizontal tokens={{ childrenGap: 50 }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
            <TextField label="ИНН" value={project.data.company.taxIdentificationNumber} onChange={(e) => change('taxIdentificationNumber', e.target.value)} />
            <div>Заполните ИНН, данные организации подтянутся автоматически</div>
            <TextField style={{color: 'black'}} disabled={true} label="Наименование" value={project.data.company.name} onChange={(e) => change('name', e.target.value)} />
            {project.data.company.type !== 'INDIVIDUAL'
                ? <TextField style={{color: 'black'}} disabled={true} label="КПП" value={project.data.company.registrationReasonCode} onChange={(e) => change('registrationReasonCode', e.target.value)} />
                : <></>
            }
            <TextField style={{color: 'black'}} disabled={true} label={project.data.company.type !== 'INDIVIDUAL' ? 'ОГРН' : 'ОГРНИП'} value={project.data.company.mainRegistrationNumber} onChange={(e) => change('mainRegistrationNumber', e.target.value)} />
            <TextField style={{color: 'black'}} disabled={true} label="ОКПО" value={project.data.company.organizationsClassifierCode} onChange={(e) => change('organizationsClassifierCode', e.target.value)} />
            <TextField style={{color: 'black'}} multiline={true} rows={2} resizable={false} label="Юридический адрес" value={project.data.company.legalAddress} onChange={(e) => change('legalAddress', e.target.value)} />
            <TextField multiline={true} rows={2} resizable={false} label="Почтовый адрес" value={project.data.company.mailingAddress} onChange={(e) => change('mailingAddress', e.target.value)} />
            {/*<div style={{marginTop: 30}}>*/}
            {/*    <DefaultButton text="Экспорт реквизитов в Excel" iconProps={{ iconName: 'ExcelDocument' }} onClick={async () => await exportRequisitesToExcelMethod(project.data.id)} />*/}
            {/*</div>*/}
        </Stack>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
            <TextField label="БИК" value={project.data.company.bandIdentificationCode} onChange={(e) => change('bandIdentificationCode', e.target.value)} />
            <div>Заполните БИК, данные банка подтянутся автоматически</div>
            <TextField style={{color: 'black'}} disabled={true} label="Банк" value={project.data.company.bankName} onChange={(e) => change('bankName', e.target.value)} />
            <TextField style={{color: 'black'}} disabled={true} label="Корреспондентский счёт" value={project.data.company.correspondentAccount} onChange={(e) => change('correspondentAccount', e.target.value)} />
            <TextField label="Рассчётный счёт" value={project.data.company.checkingAccount} onChange={(e) => change('checkingAccount', e.target.value)} />
        </Stack>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }} style={{color: 'gray'}}>
            {project.data.company.type !== null && project.data.company.type === 'LEGAL' && project.data.company.managerName
                ? <>
                    {project.data.company.name.replace('ООО', 'Общество с ограниченной ответственностью')} в лице Генерального директора {fullRussianName}, действующего на основании Устава
                </>
                : <></>
            }
            {project.data.company.type !== null && project.data.company.type === 'INDIVIDUAL'
                ? <>
                    Индивидуальный предприниматель {project.data.company.entrepreneurName} действующий на основании листа записи ЕГРИП, ОГРНИП {project.data.company.mainRegistrationNumber} от {formatDate(new Date(project.data.company.registrationDate))}
                </>
                : <></>
            }
        </Stack>
    </Stack>;
}
