import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {DetailsList, DetailsListLayoutMode, SelectionMode, Spinner, SpinnerSize, Stack} from "@fluentui/react";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import getTeamCollectionMethod from "../../api/getTeamCollectionMethod";
import {FontSizes} from "@fluentui/theme";
import getCurrentUserMethod from "../../api/getCurrentUserMethod";

export default function TeamCollection() {
    const navigate = useNavigate();
    const [teamCollection, setTeamCollection] = useState({loaded: false, data: []});
    const [page, setPage] = useState(1);
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[]}});

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    useEffect(() => {
        if (!teamCollection.loaded) {
            getTeamCollectionMethod(page).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setTeamCollection({loaded: true, data: response});
            });
        }
    });

    let teamId = null;
    user.security.permissions.forEach((permission) => {
        if (teamId === null && permission.key === 'see_teams' && permission.type === 'TEAM') {
            teamId = permission.teamId;
        }
    });

    if (user.contact.email.length === 0) {
        return <Spinner size={SpinnerSize.large} />;
    }

    if (teamId !== null) {
        navigate('/teams/' + teamId);
    }

    const renewPage = (page) => {
        setPage(page);
        getTeamCollectionMethod(page).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setTeamCollection({loaded: true, data: response});
        });
    };

    const columns = [
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'membersCount',
            name: 'Кол-во участников',
            fieldName: 'membersCount',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <div>{item.members.length === 0 ? 'Нет' : (item.members.length + ' чел.')}</div>;
            },
        },
    ];

    return (<>
        <div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Команды</div>
        <Stack horizontal tokens={{ childrenGap: 20 }} style={{marginBottom: 10}}>
            <div style={{display: 'flex', alignItems: 'end'}}>
                <DefaultButton iconProps={{iconName: 'Add'}} text="Добавить команду" onClick={() => navigate('/teams/new')} />
            </div>
        </Stack>
        <div style={{marginTop: 0}}>
            <DetailsList
                items={teamCollection.data}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onActiveItemChanged={(item) => navigate('/teams/' + item.id)}
            />
        </div>
        {teamCollection.data.length < 20
            ? <></>
            : <div style={{marginTop: 20}}>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <DefaultButton text="Назад" onClick={() => renewPage(page - 1)} disabled={page === 1} />
                    <PrimaryButton text="Далее" onClick={() => renewPage(page + 1)} />
                </Stack>
            </div>
        }
    </>);
};
