import * as React from "react";
import {
    ActionButton,
    CommandBarButton, DetailsList, DetailsListLayoutMode,
    mergeStyleSets,
    PrimaryButton,
    SelectionMode,
    Stack,
    TextField,
    TooltipHost
} from "@fluentui/react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useState} from "react";
import makePrepaymentMethod from "../../../api/makePrepaymentMethod";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeCommercialOfferMethod from "../../../api/removeCommercialOfferMethod";
import {FontSizes} from "@fluentui/theme";
import {DefaultButton} from "@fluentui/react/lib/Button";
import storeFileMethod from "../../../api/storeFileMethod";
import attachCommercialOfferMethod from "../../../api/attachCommercialOfferMethod";
import removeDocumentMethod from "../../../api/removeDocumentMethod";
import attachDocumentMethod from "../../../api/attachDocumentMethod";
import sendCommercialOfferMethod from "../../../api/sendCommercialOfferMethod";
import sendPrepaymentDocumentsMethod from "../../../api/sendPrepaymentDocumentsMethod";
import classicPrepaymentPaidMethod from "../../../api/classicPrepaymentPaidMethod";
import MoneyField from "../../MoneyField";
import setWaitingDocsMethod from "../../../api/setWaitingDocsMethod";

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});

export default function Prepayment({request, reloadData}) {
    // const [serviceFields, setServiceFields] = useState([]);
    const [prepaymentForm, setPrepaymentForm] = useState({id: request.data.id, summaryPrice: null, prepaymentSum: null, paymentType: request.data.hasInCustomerCabinet ? 'ONLINE' : null, services: []});
    const [updateForm, setUpdateForm] = useState(
        request.data.finance.prepayment
            ? {id: request.data.id, summaryPrice: request.data.finance.summaryPrice, prepaymentSum: request.data.finance.prepayment.sum, paymentType: request.data.finance.prepayment.type, services: request.data.services.map((item) => item.name)}
            : {id: request.data.id, summaryPrice: null, prepaymentSum: null, paymentType: null, services: []}
    );
    const [editing, setEditing] = useState(false);
    const [comment, setComment] = useState('');

    const services = [
        { key: 'Бренд-платформа', text: 'Бренд-платформа' },
        { key: 'Название', text: 'Название' },
        { key: 'Логотип и фирменный стиль', text: 'Логотип и фирменный стиль' },
        { key: 'Дизайн упаковки', text: 'Дизайн упаковки' },
        { key: 'Дизайн сайта', text: 'Дизайн сайта' },
        { key: 'Разработка сайта', text: 'Разработка сайта' },
        { key: 'Дизайн презентации', text: 'Дизайн презентации' },
        { key: 'Гайд по фирменному стилю', text: 'Гайд по фирменному стилю' },
        { key: 'Оформление фирменных носителей', text: 'Оформление фирменных носителей' },
        { key: 'PR', text: 'PR' },
    ];

    const paymentTypes = [
        { key: 'ONLINE', text: 'Онлайн' },
        { key: 'CLASSIC', text: 'По документам' },
    ];

    // let serviceFieldIndex = 0;

    // const addServiceField = () => {
    //     const localServiceFieldIndex = serviceFieldIndex;
    //     setServiceFields(serviceFields.concat([<TextField key={serviceFields.length} iconProps={{ iconName: 'Delete', style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => {setServiceFields([...serviceFields].splice(localServiceFieldIndex, 1))}} } />]));
    //     serviceFieldIndex++;
    // };

    const makePrepayment = async () => {
        const response = await makePrepaymentMethod(prepaymentForm.id, prepaymentForm.paymentType, prepaymentForm.summaryPrice, prepaymentForm.prepaymentSum, prepaymentForm.services);

        if (response.status === 'ok') {
            reloadData();
        }
    };

    const update = async () => {
        const response = await makePrepaymentMethod(updateForm.id, updateForm.paymentType, updateForm.summaryPrice, updateForm.prepaymentSum, updateForm.services);

        if (response.status === 'ok') {
            reloadData();
            setEditing(false);
        }
    };

    const details = [
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            minWidth: 50,
            maxWidth: 200,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'value',
            name: 'Значение',
            fieldName: 'value',
            minWidth: 150,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <span style={{fontWeight: 'bold'}}>{item.value}</span>
            }
        },
    ];

    const onlinePayments = [
        {
            key: 'status',
            name: 'Статус',
            fieldName: 'status',
            minWidth: 150,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item.status === 'NEW') {
                    return 'Ожидает оплату';
                } else if (item.status === 'PENDING') {
                    return 'Платёж в обработке';
                } else if (item.status === 'CANCELED') {
                    return 'Платеж отменён';
                }

                return 'Оплачено';
            }
        },
        {
            key: 'id',
            name: 'Ссылка',
            fieldName: 'id',
            minWidth: 210,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <CommandBarButton iconProps={{ iconName: 'Copy' }} allowDisabledFocus onClick={(e) => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-prepayment/' + request.data.id)}>
                    Скопировать ссылку
                </CommandBarButton>;
            }
        },
    ];

    // switch (request.data.request.status) {
    //     case 'PENDING':
    //         // fileInput -> "Отправить КП"
    //         break;
    //     case 'WIN':
    //         // if finances.prepayment === null:
    //         // Текущий проект -> выбрать группу услуг, добавить textField с более конкретными, общая стоимость, сумма предоплаты, формат оплаты
    //         // elseif finances.prepayment.status === WAITING_DOCS
    //         // fileInput -> "Отправить документы"
    //         // elseif finances.prepayment.status === WAITING_PAYMENT && finances.prepayment.type === CLASSIC
    //         // "Деньги поступили на счёт"
    //         // elseif finances.prepayment.status === WAITING_PAYMENT && finances.prepayment.type === ONLINE
    //         // Ссылка для оплаты: <link> (отправляется клиенту сразу), подождите пока платеж зафиксируется, инфо о платеже в виже таблицы
    //         break;
    //     case 'PAID':
    //         // Услуги, цены, сумма внесенной предоплаты, даты в виде простой таблицы
    //         break;
    //     default:
    //         // change status button with modal
    //         break;
    // }

    if (request.data.finance.summaryPrice === null || request.data.finance.prepayment === null) {
        if (request.data.hasInCustomerCabinet) {
            return (<>
                <Stack horizontal tokens={{ childrenGap: 30 }}>
                    <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: 300 } }}>
                        <Dropdown
                            placeholder="Выберите"
                            label="Услуги"
                            selectedKeys={prepaymentForm.services}
                            multiSelect
                            options={services}
                            styles={{dropdown: { width: 300 }}}
                            onChange={(e, option) => setPrepaymentForm({...prepaymentForm, services: option.selected ? [...prepaymentForm.services, option.key] : prepaymentForm.services.filter(key => key !== option.key)})}
                        />
                        <MoneyField label="Стоимость проекта" value={prepaymentForm.summaryPrice} onChange={(e) => setPrepaymentForm({...prepaymentForm, summaryPrice: e.target.value})} />
                        <MoneyField label="Сумма предоплаты" value={prepaymentForm.prepaymentSum} onChange={(e) => setPrepaymentForm({...prepaymentForm, prepaymentSum: e.target.value})} />
                        <PrimaryButton style={{marginTop: 30, height: 40}} text="Далее" onClick={makePrepayment} />
                    </Stack>
                </Stack>
            </>);
        }

        return (<>
            <Stack horizontal tokens={{ childrenGap: 30 }}>
                <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: 300 } }}>
                    <Dropdown
                        placeholder="Выберите"
                        label="Услуги"
                        selectedKeys={prepaymentForm.services}
                        multiSelect
                        options={services}
                        styles={{dropdown: { width: 300 }}}
                        onChange={(e, option) => setPrepaymentForm({...prepaymentForm, services: option.selected ? [...prepaymentForm.services, option.key] : prepaymentForm.services.filter(key => key !== option.key)})}
                    />
                    <MoneyField label="Стоимость проекта" value={prepaymentForm.summaryPrice} onChange={(e) => setPrepaymentForm({...prepaymentForm, summaryPrice: e.target.value})} />
                    <PrimaryButton style={{marginTop: 30, height: 40}} text="Далее" onClick={makePrepayment} />
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: 300 } }}>
                    <Dropdown
                        placeholder="Онлайн или по документам"
                        label="Тип платежа"
                        options={paymentTypes}
                        selectedKey={prepaymentForm.paymentType}
                        onChange={(e, option) => setPrepaymentForm({...prepaymentForm, paymentType: option.key})}
                    />
                    <MoneyField label="Сумма предоплаты" value={prepaymentForm.prepaymentSum} onChange={(e) => setPrepaymentForm({...prepaymentForm, prepaymentSum: e.target.value})} />
                </Stack>
            </Stack>
        </>);
    } else if (editing) {
        return <Stack horizontal tokens={{ childrenGap: 30 }}>
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: 300 } }}>
                <Dropdown
                    placeholder="Выберите"
                    label="Услуги"
                    selectedKeys={updateForm.services}
                    multiSelect
                    options={services}
                    styles={{dropdown: { width: 300 }}}
                    onChange={(e, option) => setUpdateForm({...updateForm, services: option.selected ? [...updateForm.services, option.key] : updateForm.services.filter(key => key !== option.key)})}
                />
                <MoneyField label="Стоимость проекта" value={updateForm.summaryPrice} onChange={(e) => setUpdateForm({...updateForm, summaryPrice: e.target.value})} />
                <MoneyField label="Сумма предоплаты" value={updateForm.prepaymentSum} onChange={(e) => setUpdateForm({...updateForm, prepaymentSum: e.target.value})} />
                <PrimaryButton style={{marginTop: 30, height: 40}} text="Обновить данные" onClick={update} />
            </Stack>
        </Stack>;
    } else if (request.data.finance.prepayment !== null && request.data.finance.prepayment.type === 'ONLINE') {
        // request.data.finance.prepayment.status === 'WAITING_PAYMENT'
        // show request.data.finance.prepayment.onlinePayments <- has link paymentSystem, status, paymentUrl, error
        // request.data.finance.prepayment.status === 'PAID', request status PAID
        // Статус: оплата произведена: сумма, список онлайн платежей [кнопок нет]

        let status;

        if (request.data.finance.prepayment.status === 'WAITING_PAYMENT') {
            status = 'Ожидает оплату';
        } else if (request.data.finance.prepayment.status === 'WAITING_DOCS') {
            status = 'Ожидает загрузки документов';
        } else if (request.data.finance.prepayment.status === 'PAID') {
            status = 'Предоплата оплачена';
        }

        return (<>
            <div style={{ fontSize: FontSizes.size18, marginBottom: 15, fontWeight: 'bold' }}>Стоимость проекта: {new Intl.NumberFormat().format(request.data.finance.summaryPrice)} руб.</div>
            {request.data.request.status === 'PAID'
                ? <></>
                : <DefaultButton text="Редактировать стоимость и услуги" onClick={() => setEditing(true)} />
            }
            <div style={{ fontSize: FontSizes.size18, marginBottom: 15, marginTop: 20, fontWeight: 'bold' }}>{request.data.finance.prepayment.title ?? 'Предоплата'}</div>
            <DetailsList
                items={[
                    {
                        name: 'Тип',
                        value: request.data.finance.prepayment.type === 'ONLINE' ? 'Онлайн (по ссылке)' : 'По документам'
                    },
                    {
                        name: 'Сумма предоплаты',
                        value: new Intl.NumberFormat().format(request.data.finance.prepayment.sum) + ' руб.'
                    },
                    {
                        name: 'Статус',
                        value: status
                    },
                ]}
                compact={false}
                columns={details}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
            />
            <div style={{marginTop: 20}}>
                {request.data.finance.prepayment.status === 'PAID'
                    ? <></>
                    : <Stack horizontal tokens={{ childrenGap: 20 }}>
                        {/*<PrimaryButton text="Отправить ссылку клиенту" iconProps={{ iconName: 'Mail' }} />*/}
                        <DefaultButton text="Скопировать ссылку" iconProps={{ iconName: 'Copy' }} onClick={(e) => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-prepayment/' + request.data.id)} />
                    </Stack>
                }
            </div>
        </>);
    } else if (request.data.finance.prepayment !== null && request.data.finance.prepayment.type === 'CLASSIC') {

        const getDocuments = () => request.data.files.filter((file) => file.type === 'DOCUMENT');

        const classicPrepaymentPaid = async () => {
            const response = await classicPrepaymentPaidMethod(request.data.id);

            if (response.status === 'ok') {
                reloadData();
            }
        };

        // request.data.finance.prepayment.status === 'WAITING_DOCS'
        // Button: addDocument
        // if request.data.files.map((file) => file.type === 'DOCUMENT'): Button: addDocument, sendToCustomer
        // request.data.finance.prepayment.status === 'WAITING_PAYMENT'
        // Статус: ожидает платеж от клиента, Button: customerPaid
        // request.data.finance.prepayment.status === 'VERIFYING'
        // Статус: подтвердите, что деньги зачислены на счёт, Button: moneyEnrolled
        // request.data.finance.prepayment.status === 'PAID', request status PAID
        // Статус: оплата произведена: сумма, список документов, история смены статусов [кнопок нет]

        let status;

        if (request.data.finance.prepayment.status === 'WAITING_PAYMENT') {
            status = 'Ожидает оплату';
        } else if (request.data.finance.prepayment.status === 'INITIALIZED') {
            status = 'Не оплачено';
        } else if (request.data.finance.prepayment.status === 'WAITING_DOCS') {
            status = 'Ожидает загрузки документов';
        } else if (request.data.finance.prepayment.status === 'PAID') {
            status = 'Предоплата оплачена';
        }

        return (<>
            <div style={{ fontSize: FontSizes.size18, marginBottom: 15, fontWeight: 'bold' }}>Стоимость проекта: {request.data.finance.summaryPrice} руб.</div>
            {request.data.request.status === 'PAID'
                ? <></>
                : <DefaultButton text="Редактировать стоимость и услуги" onClick={() => setEditing(true)} />
            }
            <div style={{ fontSize: FontSizes.size18, marginBottom: 15, marginTop: 20, fontWeight: 'bold' }}>{request.data.finance.prepayment.title ?? 'Предоплата'}</div>
            <DetailsList
                items={[
                    {
                        name: 'Тип',
                        value: request.data.finance.prepayment.type === 'ONLINE' ? 'Онлайн (по ссылке)' : 'По документам'
                    },
                    {
                        name: 'Сумма предоплаты',
                        value: request.data.finance.prepayment.sum + ' руб.'
                    },
                    {
                        name: 'Статус',
                        value: status
                    },
                ]}
                compact={false}
                columns={details}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
            />
            {getDocuments().length > 0 && request.data.finance.prepayment.status === 'WAITING_PAYMENT'
                ? <div style={{marginTop: 30}}>
                    <PrimaryButton
                        text="Деньги поступили на счёт"
                        iconProps={{ iconName: 'Accept' }}
                        allowDisabledFocus
                        onClick={classicPrepaymentPaid}
                    />
                </div>
                : <></>}
            {request.data.finance.prepayment.status === 'INITIALIZED' && <div style={{marginTop: 20}}>
                <Stack styles={{ root: { width: 600 } }}>
                    <TextField multiline rows={10} placeholder="Комментарий" value={comment} onChange={(e) => setComment(e.target.value)} style={{whiteSpace: 'break-spaces'}} />
                </Stack>
                <PrimaryButton
                    style={{marginTop: 10}}
                    text="Ожидаем документы"
                    iconProps={{ iconName: 'Clock' }}
                    allowDisabledFocus
                    onClick={async () => {
                        await setWaitingDocsMethod(request.data.id, comment.replace(/\n/g, '<br/>'));
                        reloadData();
                    }}
                />
            </div>}
        </>);
    }
};
