import * as React from "react";
import {
    ActionButton,
    DetailsList, DetailsListLayoutMode,
    NormalPeoplePicker,
    PersonaPresence, SelectionMode,
    Separator,
    Stack,
} from "@fluentui/react";
import {useEffect, useState} from "react";
import getUserCollectionMethod from "../../../../api/getUserCollectionMethod";
import removeProjectParticipantMethod from "../../../../api/removeProjectParticipantMethod";
import addProjectParticipantMethod from "../../../../api/addProjectParticipantMethod";
import getSpecializationsMethod from "../../../../api/getSpecializationsMethod";
import getSingleTeamMethod from "../../../../api/getSingleTeamMethod";
import { useBoolean } from '@fluentui/react-hooks';
import FreelancersPopup from "./FreelancersPopup";
import {Persona, PersonaSize} from "@fluentui/react/lib/Persona";
import {FontIcon} from "@fluentui/react/lib/Icon";
import RateFreelancers from "./RateFreelancers";

export default function Participants({project, reloadData, setNotification, canChangeArtDirector, canChangeWorkers, canChangeManager, canSeeFreelancers, currentUser}) {
    const [users, setUsers] = useState({loaded: false, data: []});
    const [artdirs, setArtdirs] = useState({loaded: false, data: []});
    const [managers, setManagers] = useState({loaded: false, data: []});
    const [teamMembers, setTeamMembers] = useState({loaded: false, data: {collection: []}});
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);

    useEffect(() => {
        if (!artdirs.loaded) {
            getUserCollectionMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUsers({loaded: true, data: response});
            });

            getUserCollectionMethod('ART_DIRECTOR').then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setArtdirs({loaded: true, data: response});
            });

            getUserCollectionMethod('MANAGER').then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setManagers({loaded: true, data: response});
            });

            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });
        }
    });

    useEffect(() => {
        if (!canSeeFreelancers && currentUser.teamId !== null) {
            getSingleTeamMethod(currentUser.teamId).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setTeamMembers({loaded: true, data: {collection: response.members}});
            });
        }
    }, [canSeeFreelancers, currentUser]);

    const addParticipant = async (type, userId) => {
        const response = await addProjectParticipantMethod(project.data.id, type, userId);

        if (response.status === 'ok') {
            setNotification({type: 'success', text: 'Изменения сохранены'});
            reloadData();
        }
    };

    const removeParticipantByType = async (type) => {
        let response = {status: 'none'};

        for (const participant of project.data.participants) {
            if (type === participant.type) {
                response = await removeProjectParticipantMethod(project.data.id, participant.userId);
            }
        }

        if (response.status === 'ok') {
            setNotification({type: 'success', text: 'Изменения сохранены'});
            reloadData();
        }
    };

    const removeParticipantById = async (id) => {
        const response = await removeProjectParticipantMethod(project.data.id, id);

        if (response.status === 'ok') {
            setNotification({type: 'success', text: 'Изменения сохранены'});
            reloadData();
        }
    };

    const getCurrentParticipants = (type) => {
        const participants = project.data.participants.filter((participant) => participant.type === type);
        const collection = [];

        for (const participant of participants) {
            for (const user of users.data) {
                if (user.id === participant.userId) {
                    collection.push(user.avatarId !== null
                        ? {
                            isValid: true,
                            key: user.id,
                            imageUrl: process.env.REACT_APP_API_URL + '/files/' + user.avatarId,
                            text: user.firstName + ' ' + (user.lastName ?? ''),
                        }
                        : {
                            isValid: true,
                            key: user.id,
                            text: user.firstName + ' ' + (user.lastName ?? ''),
                        })
                }
            }
        }

        return collection;
    }

    const getCurrentParticipantUsersByType = (type) => {
        const participants = project.data.participants.filter((participant) => participant.type === type);
        const collection = [];

        for (const participant of participants) {
            for (const user of users.data) {
                if (user.id === participant.userId) {
                    collection.push(user)
                }
            }
        }

        return collection;
    }

    const peopleArtdirs = artdirs.data.map((user) => {
        if (user.avatarId !== null) {
            return {
                isValid: true,
                key: user.id,
                imageUrl: process.env.REACT_APP_API_URL + '/files/' + user.avatarId,
                text: user.firstName + ' ' + (user.lastName ?? ''),
            };
        }

        return {
            isValid: true,
            key: user.id,
            text: user.firstName + ' ' + (user.lastName ?? ''),
        };
    });

    const managersFiltered = managers.data.filter((item) => {
        if (currentUser.type === 'BRAND_HUB_EMPLOYEE') { // for brand hub employees show only his managers
            return item.type === 'BRAND_HUB_EMPLOYEE';
        }
        return true;
    });

    const peopleManagers = managersFiltered.map((user) => {
        if (user.avatarId !== null) {
            return {
                isValid: true,
                key: user.id,
                imageUrl: process.env.REACT_APP_API_URL + '/files/' + user.avatarId,
                text: user.firstName + ' ' + (user.lastName ?? ''),
            };
        }

        return {
            isValid: true,
            key: user.id,
            text: user.firstName + ' ' + (user.lastName ?? ''),
        };
    });

    const peopleTeamMembers = teamMembers.data.collection.map((user) => {
        if (user.avatarId !== null) {
            return {
                isValid: true,
                key: user.userId,
                imageUrl: process.env.REACT_APP_API_URL + '/files/' + user.avatarId,
                text: user.firstName + ' ' + (user.lastName ?? ''),
            };
        }

        return {
            isValid: true,
            key: user.userId,
            text: user.firstName + ' ' + (user.lastName ?? ''),
        };
    });

    function doesTextStartWith(text, filterText) {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    }

    function removeDuplicates(personas, possibleDupes) {
        return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
    }

    function listContainsPersona(persona, personas) {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter(item => item.text === persona.text).length > 0;
    }

    const filterPersonasByText = (people, filterText) => {
        return people.filter(item => doesTextStartWith(item.text, filterText));
    };

    const filterPromise = (personasToReturn) => {
        return personasToReturn;
    };

    const onFilterChanged = (people, filterText, currentPersonas, limitResults) => {
        if (filterText) {
            let filteredPersonas = filterPersonasByText(people, filterText);

            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            // filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const columns = [
        {
            key: 'name',
            name: 'Имя',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 220,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                const text = item.firstName + ' ' + (item.lastName ?? '');

                if (item.projectsCount === 1) {
                    return <Persona text={text} size={PersonaSize.size24} presence={PersonaPresence.away} />
                }

                if (item.projectsCount >= 2) {
                    return <Persona text={text} size={PersonaSize.size24} presence={PersonaPresence.busy} />
                }

                return <Persona text={text} size={PersonaSize.size24} />
            }
        },
        {
            key: 'specialization',
            name: 'Профиль',
            fieldName: 'specialization',
            minWidth: 100,
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (user) => {
                const selectedSpecializations = specializations.data.filter((item) => item.key === user.profile.specialization);

                if (selectedSpecializations.length > 0) {
                    return <div style={{color: 'gray'}}>{selectedSpecializations[0].text}</div>;
                }

                return <div style={{color: 'gray'}}>Не указан</div>
            }
        },
        {
            key: 'action',
            name: 'Действие',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (!canChangeWorkers) {
                    return  <></>;
                }

                return <FontIcon aria-label="Cancel" iconName="Cancel" style={{cursor: 'pointer', verticalAlign: 'middle'}} onClick={async () => {
                    await removeParticipantById(item.id);
                    reloadData();
                }} />;
            },
        },
    ];

    if (currentUser.type === 'FREELANCER') {
        const managers = getCurrentParticipantUsersByType('MANAGER');
        const artDirectors = getCurrentParticipantUsersByType('ART_DIRECTOR');

        return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
            <div>
                <div className="ms-fontWeight-semibold" style={{marginBottom: 5}}>Менеджер</div>
                {managers.length > 0
                    ? managers.map((item) => <Persona text={item.firstName + ' ' + (item.lastName ?? '')} size={PersonaSize.size24} styles={{root: {marginTop: 12}}} />)
                    : <div style={{marginTop: 2}}>Не назначен</div>
                }
            </div>
            <div>
                <div className="ms-fontWeight-semibold" style={{marginBottom: 5, marginTop: 10}}>Арт-директор</div>
                {artDirectors.length > 0
                    ? artDirectors.map((item) => <Persona text={item.firstName + ' ' + (item.lastName ?? '')} size={PersonaSize.size24} styles={{root: {marginTop: 12}}} />)
                    : <div style={{marginTop: 2}}>Не назначен</div>
                }
            </div>
        </Stack>
    }

    return  <div>
        <Stack horizontal tokens={{ childrenGap: 30 }}>
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                <div>
                    <div className="ms-fontWeight-semibold" style={{marginBottom: 5}}>Арт-директор</div>
                    <NormalPeoplePicker
                        onResolveSuggestions={(filterText, currentPersonas, limitResults) => onFilterChanged(currentUser.teamId !== null ? peopleTeamMembers : peopleArtdirs, filterText, currentPersonas, limitResults)}
                        onEmptyInputFocus={(currentPersonas) => filterPromise(removeDuplicates(currentUser.teamId !== null ? peopleTeamMembers : peopleArtdirs, currentPersonas))}
                        className={'ms-PeoplePicker'}
                        key={'normal'}
                        selectionAriaLabel={'Selected contacts'}
                        removeButtonAriaLabel={'Remove'}
                        selectedItems={getCurrentParticipants('ART_DIRECTOR')}
                        onChange={async (values) => {
                            const previousParticipantsIds = project.data.participants.filter((participant) => participant.type === 'ART_DIRECTOR').map((value) => value.userId);
                            const actualParticipantsIds = values.map((value) => value.key);
                            for (const previousParticipantsId of previousParticipantsIds) {
                                if (!actualParticipantsIds.includes(previousParticipantsId)) {
                                    await removeParticipantById(previousParticipantsId);
                                }
                            }
                            for (const actualParticipantId of actualParticipantsIds) {
                                if (!previousParticipantsIds.includes(actualParticipantId)) {
                                    await addParticipant('ART_DIRECTOR', actualParticipantId);
                                }
                            }
                        }}
                        itemLimit={2}
                        disabled={!canChangeArtDirector}
                    />
                </div>
                <div>
                    <div className="ms-fontWeight-semibold" style={{marginBottom: 5}}>Менеджер</div>
                    <NormalPeoplePicker
                        onResolveSuggestions={(filterText, currentPersonas, limitResults) => onFilterChanged(currentUser.teamId !== null ? peopleTeamMembers : peopleManagers, filterText, currentPersonas, limitResults)}
                        onEmptyInputFocus={(currentPersonas) => filterPromise(removeDuplicates(currentUser.teamId !== null ? peopleTeamMembers : peopleManagers, currentPersonas))}
                        className={'ms-PeoplePicker'}
                        key={'normal'}
                        selectionAriaLabel={'Selected contacts'}
                        removeButtonAriaLabel={'Remove'}
                        selectedItems={getCurrentParticipants('MANAGER')}
                        onChange={async (values) => {
                            await removeParticipantByType('MANAGER');

                            if (values.length > 0) {
                                await addParticipant('MANAGER', values[0].key);
                            }
                        }}
                        itemLimit={1}
                        disabled={!canChangeManager}
                    />
                </div>
            </Stack>
            <Separator vertical />
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 600 } }}>
                <div>
                    <div className="ms-fontWeight-semibold" style={{marginBottom: 10}}>Исполнители</div>
                    {!canSeeFreelancers && currentUser.teamId !== null
                        ? <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                <NormalPeoplePicker
                                onResolveSuggestions={(filterText, currentPersonas, limitResults) => onFilterChanged(peopleTeamMembers, filterText, currentPersonas, limitResults)}
                                onEmptyInputFocus={(currentPersonas) => filterPromise(removeDuplicates(peopleTeamMembers, currentPersonas))}
                                className={'ms-PeoplePicker'}
                                key={'normal'}
                                selectionAriaLabel={'Selected contacts'}
                                removeButtonAriaLabel={'Remove'}
                                selectedItems={getCurrentParticipants('WORKER')}
                                onChange={async (values) => {
                                    const previousParticipantsIds = project.data.participants.filter((participant) => participant.type === 'WORKER').map((value) => value.userId);
                                    const actualParticipantsIds = values.map((value) => value.key);
                                    for (const previousParticipantsId of previousParticipantsIds) {
                                        if (!actualParticipantsIds.includes(previousParticipantsId)) {
                                            await removeParticipantById(previousParticipantsId);
                                        }
                                    }
                                    for (const actualParticipantId of actualParticipantsIds) {
                                        if (!previousParticipantsIds.includes(actualParticipantId)) {
                                            await addParticipant('WORKER', actualParticipantId);
                                        }
                                    }
                                }}
                                disabled={!canChangeWorkers}
                            />
                        </Stack>
                        : <>
                            <DetailsList
                                items={getCurrentParticipantUsersByType('WORKER')}
                                compact={false}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                getKey={(item, index) => item.key}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={false}
                            />
                            {canChangeWorkers
                                ? <>
                                    <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={showPopup} style={{marginTop: 6}}>
                                        Добавить
                                    </ActionButton>
                                    {getCurrentParticipantUsersByType('WORKER').length === 0
                                        ? <></>
                                        : <RateFreelancers passedScores={project.data.freelancerScores} projectId={project.data.id} specializations={specializations} participants={getCurrentParticipantUsersByType('WORKER')} reloadData={reloadData} />
                                    }
                                    <FreelancersPopup
                                        isPopupVisible={isPopupVisible}
                                        specializations={specializations}
                                        hidePopup={hidePopup}
                                        addFreelancer={async (id) => await addParticipant('WORKER', id)}
                                        canSeeFreelancers={canSeeFreelancers}
                                    />
                                </>
                                : <></>
                            }
                        </>}
                </div>
            </Stack>
        </Stack>
    </div>;
}
