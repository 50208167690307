import * as React from "react";
import {useEffect, useState} from "react";
import getAvailablePermissionsMethod from "../../../api/getAvailablePermissionsMethod";
import {Dropdown, DropdownMenuItemType} from "@fluentui/react/lib/Dropdown";
import {FontSizes} from "@fluentui/theme";
import changeUserPermissionsMethod from "../../../api/changeUserPermissionsMethod";
import {SelectionMode} from "@fluentui/react/lib/Selection";
import {DetailsList} from "@fluentui/react";

export default function ChangePermissions({user, setUser, setNotification}) {
    const [availablePermissions, setAvailablePermissions] = useState([]);

    useEffect(() => {
        if (availablePermissions.length === 0) {
            getAvailablePermissionsMethod(user.data.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    return;
                }

                setAvailablePermissions(response);
            });
        }
    });

    let groups = [];
    availablePermissions.forEach((permission) => {
        if (groups.filter((group) => group.key === permission.groupKey).length === 0) {
            groups.push({
                key: permission.groupKey,
                name: permission.groupName,
                startIndex: groups.length === 0 ? 0 : (groups[groups.length - 1].startIndex + groups[groups.length - 1].count),
                count: 1,
                level: 0
            });
        } else {
            groups[groups.length - 1].count++;
        }
    });

    const generatePermissionId = (key, type, teamId, leadSource) => key + '/' + type + '/' + teamId + '/' + leadSource;

    const columns = [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 500,
            onRender: (item) => {
                return <>
                    <div style={{fontSize: FontSizes.size14, color: '#1b1b1b'}}>{item.name}</div>
                </>;
            }
        },
        {
            key: 'list',
            name: 'List',
            fieldName: 'list',
            minWidth: 100,
            maxWidth: 200,
            onRender: (item) => {
                return <Dropdown
                    options={item.availableValues.map((availableValue) => {
                        return {key: generatePermissionId(item.key, availableValue.type, availableValue.teamId, availableValue.leadSource), text: availableValue.name};
                    })}
                    styles={{dropdown: { width: 300 }}}
                    placeholder="Нет доступа"
                    multiSelect={true}
                    selectedKeys={user.data.permissions.map((item) => generatePermissionId(item.key, item.type, item.teamId, item.leadSource ?? null))}
                    onChange={(event, option) => {
                        const values = option.key.split('/');
                        changeSelectedPermissions(values[0], values[1], values[2], values[3]);
                    }}
                />
            }
        },
    ];

    const changeSelectedPermissions = (key, type, teamId, leadSource) => {
        const permissions = [...user.data.permissions];
        let existentIndex = false;

        permissions.forEach((item, index) => {
            if (item.key === key && item.type === type && (item.teamId === teamId || (teamId === 'null' && item.teamId === null)) && (item.leadSource === undefined || item.leadSource === leadSource)) {
                permissions.splice(index, 1);
                existentIndex = true;
            }
        });

        if (existentIndex) {
            setUser({...user, data: {...user.data, permissions: permissions}});
            changeUserPermissionsMethod(user.data.id, permissions).then(response => {
                if (response.exception || response.error) {
                    console.log(response.error ? response.error.message : response.exception);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    return;
                }

                setNotification({type: 'success', text: 'Доступы сохранены'});
            });
            return;
        }

        permissions.push({key: key, type: type, teamId: teamId, leadSource: leadSource});
        setUser({...user, data: {...user.data, permissions: permissions}});

        changeUserPermissionsMethod(user.data.id, permissions).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Доступы сохранены'});
        });
    };

    return <DetailsList
        items={availablePermissions}
        groups={groups}
        columns={columns}
        selectionMode={SelectionMode.none}
        compact={false}
        isHeaderVisible={false}
    />;
};
