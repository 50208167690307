import * as React from "react";
import {FontSizes} from "@fluentui/theme";
import {Stack, TextField} from "@fluentui/react";

export default function Source({request}) {
    return (<>
        <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 5, fontWeight: 'bold' }}>Источник</div>
        <div style={{marginBottom: 15, color: 'gray'}}>{request.data.request.source.type}</div>
        {request.data.request.source.ymClientId !== null ? <div style={{marginBottom: 15, color: 'gray'}}>Yandex ClientID: {request.data.request.source.ymClientId}</div> : ''}
        <Stack horizontal tokens={{ childrenGap: 50 }}>
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                <div style={{ fontSize: FontSizes.size14, marginBottom: 0, marginTop: 5, fontWeight: 'bold' }}>Страница сайта</div>
                {
                    request.data.request.source.pageName === null
                        ? <div style={{color: 'gray'}}>Нет данных о странице</div>
                        : <>
                            <TextField style={{color: '#1d1d1d'}} disabled label="Страница" value={request.data.request.source.pageName} />
                            <TextField style={{color: '#1d1d1d'}} multiline rows={3} disabled label="Призыв к действию" value={request.data.request.source.callToAction} />
                        </>
                }
            </Stack>
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                <div style={{ fontSize: FontSizes.size14, marginBottom: 0, marginTop: 5, fontWeight: 'bold' }}>UTM-метки</div>
                {
                    request.data.request.source.utmSource === null
                        ? <div style={{color: 'gray'}}>Запрос поступил без UTM меток</div>
                        : <>
                            <TextField style={{color: '#1d1d1d'}} disabled label="SOURCE" value={request.data.request.source.utmSource} />
                            <TextField style={{color: '#1d1d1d'}} disabled label="MEDIUM" value={request.data.request.source.utmMedium} />
                            <TextField style={{color: '#1d1d1d'}} disabled label="CAMPAIGN" value={request.data.request.source.utmCampaign} />
                            <TextField style={{color: '#1d1d1d'}} disabled label="TERM" value={request.data.request.source.utmTerm} />
                            <TextField style={{color: '#1d1d1d'}} disabled label="CONTENT" value={request.data.request.source.utmContent} />
                        </>
                }
            </Stack>
        </Stack>
    </>)
};
