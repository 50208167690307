import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import getDocmasterTypesMethod from "../../api/getDocmasterTypesMethod";
import {Breadcrumb, DefaultButton, DetailsList, DetailsListLayoutMode, SelectionMode} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import getDocmasterDocumentsMethod from "../../api/getDocmasterDocumentsMethod";
import addDocmasterDocument from "../../api/addDocmasterDocument";

export default function DocumentCollection() {
    const navigate = useNavigate();
    const params = useParams();
    const [documents, setDocuments] = useState({loaded: false, data: []});
    const [type, setType] = useState('');

    useEffect(() => {
        if (!documents.loaded) {
            getDocmasterDocumentsMethod(params.type).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setDocuments({loaded: true, data: response.documents});
                setType(response.type);
            });
        }
    });

    const columns = [
        {
            key: 'createdAt',
            name: 'Созданные документы',
            fieldName: 'createdAt',
            maxWidth: 80,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                return 'Документ от ' + item.createdAt;
            },
        },
    ];

    const breadcrumbs = [
        { text: 'DOCMASTER', key: 'docmaster', href: '/docmaster' },
        { text: type, key: 'documents', href: '/docmaster/' + params.type, isCurrentItem: true },
    ];

    const addDocument = async () => {
        addDocmasterDocument(type).then((response) => navigate('/docmaster/documents/' + response.id));
    };

    return <>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 20}}
        />
        <DefaultButton text="Добавить документ" onClick={addDocument} />
        {documents.data.length === 0
            ? <div style={{marginTop: 20}}>Нет документов</div>
            : <DetailsList
                items={documents.data}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderRow={(props, Row) => {
                    return <div style={{cursor: 'pointer'}} onClick={(e) => {
                        if (e.target.classList.contains('archive')) {
                            return;
                        }

                        navigate('/docmaster/documents/' + props.item.id);
                    }}>
                        <Row {...props} />
                    </div>;
                }}
            />}
    </>
};
