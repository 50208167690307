import React from 'react';
import ReactDOM from 'react-dom';
import App from "./components/App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

window.addEventListener('load', async () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(regs => regs.forEach(reg => reg.unregister()));
        // try {
        //     const reg = await navigator.serviceWorker.register('/sw.js')
        //     console.log('Service worker register success', reg)
        // } catch (e) {
        //     console.log('Service worker register fail')
        // }
    }
});
