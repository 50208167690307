import * as React from "react";
import {useState} from "react";
import {Breadcrumb, PrimaryButton, Stack, TextField} from "@fluentui/react";
import addTeamMethod from "../../api/addTeamMethod";
import {useNavigate} from "react-router-dom";

const initialForm = {
    name: null,
    about: null,
    members: [],
    services: [],
};

export default function AddTeam() {
    const [form, setForm] = useState(initialForm);
    const navigate = useNavigate();

    const breadcrumbs = [
        { text: 'Команды', key: 'teams', onClick: () => navigate('/teams') },
        { text: 'Добавить', key: 'team', href: '/teams/new', isCurrentItem: true },
    ];

    const submit = () => {
        addTeamMethod(form.name, form.about, form.members, form.services).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            navigate('/teams');
        });
    };

    return (<>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 20}}
        />
        <Stack horizontal tokens={{ childrenGap: 50 }}>
            <div>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    <TextField label="Название команды" value={form.name} onChange={(e) => setForm({...form, name: e.target.value})} />
                    <TextField label="Описание" multiline rows={3} value={form.about} onChange={(e) => setForm({...form, about: e.target.value})} />
                </Stack>
            </div>
        </Stack>
        <PrimaryButton style={{marginTop: 30, height: 40}} text="Сохранить" onClick={submit} />
    </>);
};
