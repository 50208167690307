import * as React from "react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {DefaultButton, Stack, TextField} from "@fluentui/react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import exportPCUsersToExcelMethod from "../../../api/exportPCUsersToExcelMethod";
import exportFreelancersToExcelMethod from "../../../api/exportFreelancersToExcelMethod";

export default function FreelancerCollectionFilter({specializations, filterSelection, setFilterSelection, reloadCollection}) {
    const [filters, setFilters] = useState({loaded: false, specializations: [], types: [{key: 'Проверенный', text: 'Проверенный'},{key: 'Новый', text: 'Новый'}], competencies: []});
    const navigate = useNavigate();

    filterSelection.competencies = filterSelection.competencies ?? [];

    const specializations2 = [{key: 'ALL', text: 'Все'}, ...specializations.data];

    const dropdownStyles = {
        dropdown: { width: 180 },
    };

    const types = [...filters.types];

    types.unshift({key: 'ALL', text: 'Все'});

    const changeFilter = (newFilterSelection) => {
        localStorage.setItem('FREELANCE_FILTER', JSON.stringify(newFilterSelection));
        setFilterSelection(newFilterSelection);
        reloadCollection(newFilterSelection);
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 20 }} style={{marginBottom: 10}}>
            <TextField styles={{root: { width: 250 }}} label="Поиск" value={filterSelection.name} onChange={(e) => changeFilter({...filterSelection, name: e.target.value})} />
            <Dropdown
                placeholder="Все"
                label="Направление"
                options={[
                    {key: 'ALL', text: 'Все'},
                    {key: 'DESIGNER', text: 'Дизайн'},
                    {key: 'STRATEGIST', text: 'Стратегия'},
                    {key: 'COPYWRITER', text: 'Копирайтинг'},
                ]}
                styles={dropdownStyles}
                selectedKey={filterSelection.specialization}
                onChange={(e, option) => changeFilter({...filterSelection, specialization: option.key})}
            />
            <Dropdown
                placeholder="Не выбрана"
                label="Специализация"
                options={[
                    {key: 'арт-директоринг', text: 'Арт-директоринг'},
                    {key: 'айдентика', text: 'Айдентика'},
                    {key: 'упаковка', text: 'Упаковка'},
                    {key: 'web', text: 'WEB'},
                    {key: 'иллюстрация', text: 'Иллюстрация'},
                    {key: 'бренд-стратегия', text: 'Бренд-стратегия'},
                    {key: 'нейминг', text: 'Нейминг'},
                    {key: 'копирайт', text: 'Копирайт'},
                ]}
                styles={dropdownStyles}
                selectedKeys={filterSelection.competencies}
                onChange={(e, option) => changeFilter({...filterSelection, competencies: option.selected ? [...filterSelection.competencies, option.key] : filterSelection.competencies.filter(key => key !== option.key)})}
                multiSelect={true}
            />
            {/*<Dropdown*/}
            {/*    placeholder="Все"*/}
            {/*    label="Тип"*/}
            {/*    options={types}*/}
            {/*    styles={dropdownStyles}*/}
            {/*    selectedKey={filterSelection.type}*/}
            {/*    onChange={(e, option) => changeFilter({...filterSelection, type: option.key})}*/}
            {/*/>*/}
            <div style={{display: 'flex', alignItems: 'end'}}>
                <DefaultButton iconProps={{iconName: 'Add'}} text="Добавить исполнителя" onClick={() => navigate('/freelancers/new')} />
            </div>
            <div style={{display: 'flex', alignItems: 'end'}}>
                <DefaultButton text="Экспорт Excel" iconProps={{ iconName: 'ExcelDocument' }} onClick={() => exportFreelancersToExcelMethod()} />
            </div>
        </Stack>
    );
};
