import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {DetailsList, DetailsListLayoutMode, SelectionMode, Stack} from "@fluentui/react";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {FontSizes} from "@fluentui/theme";
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import getBrandHubEmployeeCollectionMethod from "../../../api/getBrandHubEmployeeCollectionMethod";
import getSpecializationsMethod from "../../../api/getSpecializationsMethod";
import getCurrentUserMethod from "../../../api/getCurrentUserMethod";
import Pagination from "../../Theme/Pagination";

export default function EmployeeCollection() {
    const navigate = useNavigate();
    const [userCollection, setUserCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [page, setPage] = useState(1);
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[]}});

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    let canChangeEmployees = false;
    user.security.permissions.forEach((permission) => {
        if (permission.key === 'change_employee' && permission.type === 'ALL') {
            canChangeEmployees = true;
        }
    });

    useEffect(() => {
        if (!userCollection.loaded) {
            getBrandHubEmployeeCollectionMethod(page).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUserCollection({loaded: true, data: response});
            });

            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });
        }
    });

    const renewPage = (page) => {
        setPage(page);
        getBrandHubEmployeeCollectionMethod(page).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setUserCollection({loaded: true, data: response});
        });
    };

    const columns = [
        {
            key: 'name',
            name: 'Имя',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 220,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                let name = item.firstName;

                if (item.lastName !== null) {
                    name += ' ' + item.lastName;
                }

                if (item.avatarId) {
                    return <Persona imageUrl={process.env.REACT_APP_API_URL + '/files/' + item.avatarId} text={name} size={PersonaSize.size32} />
                }

                return <Persona text={name} size={PersonaSize.size32} />
            }
        },
        {
            key: 'specialization',
            name: 'Профиль',
            fieldName: 'specialization',
            minWidth: 100,
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (user) => {
                const selectedSpecializations = specializations.data.filter((item) => item.key === user.profile.specialization);

                if (selectedSpecializations.length > 0) {
                    return <div>{selectedSpecializations[0].text}</div>;
                }

                return <div>Не указан</div>
            }
        },
        {
            key: 'telegramUsername',
            name: 'Телеграм',
            fieldName: 'telegramUsername',
            minWidth: 100,
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (user) => {
                if (user.telegramUsername === null) {
                    return <div>Не указан</div>;
                }

                return <div>{'@' + user.telegramUsername}</div>;
            }
        },
        {
            key: 'email',
            name: 'Email',
            fieldName: 'email',
            minWidth: 160,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: 'phoneNumber',
            name: 'Телефон',
            fieldName: 'phoneNumber',
            minWidth: 160,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
        },
    ];

    return (<>
        <div style={{ fontSize: FontSizes.size20, marginBottom: canChangeEmployees ? 20 : 0, fontWeight: 600 }}>Сотрудники Brand Hub</div>
            {canChangeEmployees
                ? <Stack horizontal tokens={{ childrenGap: 20 }} style={{marginBottom: 10}}>
                    <div style={{display: 'flex', alignItems: 'end'}}>
                        <DefaultButton iconProps={{iconName: 'Add'}} text="Новый сотрудник" onClick={() => navigate('/employees/new')} />
                    </div>
                </Stack>
                : <></>
            }
        <DetailsList
            items={userCollection.data.collection}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => item.key}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onActiveItemChanged={(item) => canChangeEmployees ? navigate('/employees/' + item.id) : null}
        />
        <div style={{marginTop: 20}}>
            <Pagination page={page} lastPage={userCollection.data.lastPage} renewPage={renewPage} />
        </div>
    </>);
};
