import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function attachToExistentCustomerCabinetMethod(projectId) {
    const url = new URL(serverUrl + '/api/v2/pc/attach-to-existent-customer-cabinet');

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({projectId: projectId}),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
