const serverUrl = process.env.REACT_APP_API_URL;

export default async function storeFileMethod(file, setPercentComplete = null) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        formData.append('file', file);

        if (setPercentComplete !== null) {
            xhr.upload.addEventListener(
                'progress',
                (event) => {
                    setPercentComplete(event.loaded / event.total);
                },
                false
            );
            xhr.addEventListener('load', () => setPercentComplete(0), false)
        }

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                resolve(JSON.parse(xhr.response));
            }
        };

        xhr.open('POST', new URL(serverUrl + '/api/v2/store-file'));
        xhr.send(formData);
    });
}
