import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {
    DetailsList,
    DetailsListLayoutMode, mergeStyles,
    PersonaPresence, Rating, RatingSize,
    SelectionMode
} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import getFreelancersCollectionMethod from "../../../api/getFreelancersCollectionMethod";
import getSpecializationsMethod from "../../../api/getSpecializationsMethod";
import FreelancerCollectionFilter from "./FreelanceCollectionFilter";
import Pagination from "../../Theme/Pagination";
import {FontIcon} from "@fluentui/react/lib/Icon";

export default function FreelancerCollection({type}) {
    const navigate = useNavigate();
    const [userCollection, setUserCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [page, setPage] = useState(1);
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const storedFilterSelection = localStorage.getItem('FREELANCE_FILTER') ? JSON.parse(localStorage.getItem('FREELANCE_FILTER')) : {specialization: 'ALL', type: 'ALL', name: '', competencies: []};
    const [filterSelection, setFilterSelection] = useState(storedFilterSelection);

    useEffect(() => {
        if (!userCollection.loaded) {
            getFreelancersCollectionMethod(filterSelection.name, filterSelection.specialization, filterSelection.type, page, 15, filterSelection.competencies).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUserCollection({loaded: true, data: response});
            });

            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });
        }
    });

    const reloadCollection = (freshFilterSelection) => {
        getFreelancersCollectionMethod(freshFilterSelection.name, freshFilterSelection.specialization, freshFilterSelection.type, page, 15, freshFilterSelection.competencies).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setUserCollection({loaded: true, data: response});
        });
    };

    const renewPage = (page) => {
        setPage(page);
        getFreelancersCollectionMethod(filterSelection.name, filterSelection.specialization, filterSelection.type, page, 15, filterSelection.competencies).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setUserCollection({loaded: true, data: response});
        });
    };

    const iconClass = mergeStyles({
        fontSize: 18,
        height: 18,
        width: 18,
        marginTop: 6,
        marginRight: 10,
    });

    const columns = [
        {
            key: 'name',
            name: 'Имя',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 220,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                let specialization = '';
                const selectedSpecializations = specializations.data.filter((val) => val.key === item.profile.specialization);
                if (selectedSpecializations.length > 0) {
                    specialization = selectedSpecializations[0].text;
                }

                const text = item.firstName + ' ' + (item.lastName ?? '');

                if (item.projectsCount === 1) {
                    return <Persona text={text} size={PersonaSize.size40} presence={PersonaPresence.away} secondaryText={specialization} />
                }

                if (item.projectsCount >= 2) {
                    return <Persona text={text} size={PersonaSize.size40} presence={PersonaPresence.busy} secondaryText={specialization} />
                }

                return <Persona text={text} size={PersonaSize.size40} presence={PersonaPresence.online} secondaryText={specialization} />
            }
        },
        {
            key: 'specializations',
            name: 'Специализация',
            fieldName: 'specializations',
            minWidth: 100,
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (user) => {
                if (user.specializations.length === 0) {
                    return 'не указана'
                }

                return user.specializations.join(', ');
            }
        },
        {
            key: 'score',
            name: 'Рейтинг',
            fieldName: 'score',
            minWidth: 100,
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                return <Rating
                    max={5}
                    size={RatingSize.Large}
                    rating={item.score}
                    allowZeroStars
                    ariaLabel="Оценка"
                    style={{pointerEvents: 'none'}}
                />;
            }
        },
        {
            key: 'projectsTotalCount',
            name: 'Общее кол-во проектов',
            fieldName: 'projectsTotalCount',
            minWidth: 80,
            maxWidth: 120,
            isRowHeader: true,
            data: 'string',
            onRender: (user) => {
                if (user.projectsTotalCount === 0) {
                    return 'нет проектов';
                }

                const projectsCountLastDigit = parseInt(user.projectsTotalCount.toString().slice(-1));
                let projectsCountWord = '';
                if (projectsCountLastDigit === 1) {
                    projectsCountWord = 'проект';
                } else if (projectsCountLastDigit > 1 && projectsCountLastDigit < 5) {
                    projectsCountWord = 'проекта';
                } else {
                    projectsCountWord = 'проектов';
                }

                return <div>{user.projectsTotalCount} {projectsCountWord}</div>
            }
        },
        {
            key: 'projectsTotalCount',
            name: 'Проекты в работе',
            fieldName: 'projectsCount',
            minWidth: 100,
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (user) => {
                if (user.currentProjectNames.length === 0) {
                    return 'нет проектов';
                }

                return <div>{user.currentProjectNames.join(', ')}</div>
            }
        },
        // {
        //     key: 'telegramUsername',
        //     name: 'Телеграм',
        //     fieldName: 'telegramUsername',
        //     minWidth: 100,
        //     maxWidth: 160,
        //     isRowHeader: true,
        //     data: 'string',
        //     onRender: (user) => {
        //         if (user.telegramUsername === null) {
        //             return <div>Не указан</div>;
        //         }
        //
        //         return <div>{'@' + user.telegramUsername}</div>;
        //     }
        // },
        // {
        //     key: 'email',
        //     name: 'Email',
        //     fieldName: 'email',
        //     minWidth: 100,
        //     maxWidth: 160,
        //     isRowHeader: true,
        //     data: 'string',
        // },
        // {
        //     key: 'phoneNumber',
        //     name: 'Номер телефона',
        //     fieldName: 'phoneNumber',
        //     minWidth: 100,
        //     maxWidth: 160,
        //     isRowHeader: true,
        //     data: 'string',
        // },
    ];

    const freelancersCountLastDigit = parseInt(userCollection.data.count.toString().slice(-1));
    let freelancersCountWord = '';
    if (freelancersCountLastDigit === 1) {
        freelancersCountWord = 'исполнитель';
    } else if (freelancersCountLastDigit > 1 && freelancersCountLastDigit < 5) {
        freelancersCountWord = 'исполнителя';
    } else {
        freelancersCountWord = 'исполнителей';
    }

    return (<>
        <div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Исполнители</div>
        <FreelancerCollectionFilter specializations={specializations} filterSelection={filterSelection} setFilterSelection={setFilterSelection} reloadCollection={reloadCollection} />
        <div style={{marginBottom: 4}}>Найдено <span style={{fontWeight: 600}}>{userCollection.data.count}</span> {freelancersCountWord}</div>
        <div style={{opacity: 0.3}}>Рейтинг исполнителей показывается применимо к выбранной специализации</div>
        <DetailsList
            items={userCollection.data.collection}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => item.key}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderRow={(props, Row) => {
                return <div style={{cursor: 'pointer'}} onClick={(e) => navigate('/freelancers/' + props.item.id)}>
                    <Row {...props} />
                </div>;
            }}
        />
        <div style={{marginTop: 20}}>
            <Pagination page={page} lastPage={userCollection.data.lastPage} renewPage={renewPage} />
        </div>
    </>);
};
