import {getAuthToken} from "../../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function createChatMethod(type, projectId, participantsIds) {
    const url = new URL(serverUrl + '/api/v2/pc/create-chat');

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({type: type, projectId: projectId, participantsIds: participantsIds}),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
