import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function rateFreelancerMethod(id, projectId, specialization, qualityScore, communicationScore, speedScore) {
    const url = new URL(serverUrl + '/api/v2/pc/rate-freelancer');

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({id: id, projectId: projectId, specialization: specialization, qualityScore: qualityScore, communicationScore: communicationScore, speedScore: speedScore}),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
