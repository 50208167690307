import * as React from "react";
import Header from "./Header";
import Menu from "./Menu";
import { initializeIcons } from '@fluentui/font-icons-mdl2';

export default function Template({component}) {
    initializeIcons();

    return (
        <>
            <Header/>
            <div style={{padding: '5px 20px', display: 'flex', columnGap: 30}}>
                <Menu/>
                <div style={{overflowX: 'auto', paddingBottom: 50, width: '100%'}}>
                    {component}
                </div>
            </div>
        </>
    );
};
