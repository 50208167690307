import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function makePrepaymentMethod(id, paymentType, summaryPrice, prepaymentSum, services) {
    const url = new URL(serverUrl + '/api/v2/pc/make-prepayment');

    return fetch(url, {
        method: 'PATCH',
        body: JSON.stringify({
            id: id,
            paymentType: paymentType,
            summaryPrice: summaryPrice,
            prepaymentSum: prepaymentSum,
            services: services,
        }),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
