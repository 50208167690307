import React from 'react';
import AppRouter from "./AppRouter";
import { createTheme, ThemeProvider } from '@fluentui/react';
import {getAuthToken, setAuthToken} from "../functions/authToken";
import * as styles from "./../assets/app.css";
import 'office-ui-fabric-react/dist/css/fabric.css';

const theme = createTheme({
    palette: {
        themePrimary: '#ef5021',
        themeLighterAlt: '#fef8f6',
        themeLighter: '#fde2da',
        themeLight: '#fac9ba',
        themeTertiary: '#f69577',
        themeSecondary: '#f2653b',
        themeDarkAlt: '#d8491e',
        themeDark: '#b63e1a',
        themeDarker: '#862e13',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#b2b2b2',
        neutralSecondary: '#999999',
        neutralSecondaryAlt: '#999999',
        neutralPrimaryAlt: '#808080',
        neutralPrimary: '#1d1d1d',
        neutralDark: '#4e4e4e',
        black: '#353535',
        white: '#ffffff',
    }
});

export default function App() {
    const urlParams = new URLSearchParams(window.location.search);

    const isAuthorized = () => getAuthToken() !== null;

    const attemptToLoginByLinkBuiltinToken = () => {
        const passedAuthToken = urlParams.get('authToken');
        if (passedAuthToken !== null) {
            setAuthToken(passedAuthToken);
            return true;
        }

        return false;
    };

    const isRequestedLoginPage = () => window.location.pathname === '/login';

    if (!isAuthorized() && attemptToLoginByLinkBuiltinToken()) {
        window.location.href = window.location.origin + window.location.pathname;
        return;
    }

    if (!isAuthorized() && !isRequestedLoginPage()) {
        window.location.pathname = '/login';
        return;
    }

    if (isAuthorized() && isRequestedLoginPage()) {
        window.location.pathname = '/';
        return;
    }

    return (
        <ThemeProvider theme={theme}>
            <AppRouter />
        </ThemeProvider>
    );
}
