import * as React from "react";
import {
    Stack,
    TextField,
    Separator
} from "@fluentui/react";
import changeProjectNameMethod from "../../../../api/changeProjectNameMethod";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import changeProjectStatusMethod from "../../../../api/changeProjectStatusMethod";
import changeProjectMilestoneMethod from "../../../../api/changeProjectMilestoneMethod";
import Deadlines from "./Deadlines";
import changeProjectCodeMethod from "../../../../api/changeProjectCodeMethod";
import changeProjectServicesMethod from "../../../../api/changeProjectServicesMethod";
import Participants from "./Participants";

export default function General({currentUser, userType, project, setProject, setNotification, reloadData}) {
    const isChangeDisabled = userType === 'FREELANCER';

    const changeName = (value) => {
        if (isChangeDisabled) {
            return;
        }

        setProject({...project, data: {...project.data, name: value}});

        changeProjectNameMethod(project.data.id, value).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Название проекта изменено'});
        });
    };

    const changeCode = (value) => {
        if (isChangeDisabled) {
            return;
        }

        setProject({...project, data: {...project.data, code: value}});

        changeProjectCodeMethod(project.data.id, value).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Номер проекта изменён'});
        });
    };

    const changeMilestone = (value) => {
        if (isChangeDisabled) {
            return;
        }

        setProject({...project, data: {...project.data, milestone: value}});

        changeProjectMilestoneMethod(project.data.id, value).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Этап проекта изменён'});
        });
    };

    const changeStatus = (value) => {
        if (isChangeDisabled) {
            return;
        }

        setProject({...project, data: {...project.data, status: value}});

        changeProjectStatusMethod(project.data.id, value).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Статус проекта изменён'});
        });
    };

    const changeServices = (option) => {
        if (isChangeDisabled) {
            return;
        }

        const currentServicesArr = project.data.services.map((service) => service.name);
        const servicesArr = option.selected ? [...currentServicesArr, option.key] : currentServicesArr.filter(key => key !== option.key);
        const services = servicesArr.map((serviceName) => {return {name: serviceName};});
        setProject({...project, data: {...project.data, services: services}});

        changeProjectServicesMethod(project.data.id, services).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Услуги сохранены'});
        });
    };

    const statuses = [
        { key: 'IN_PROGRESS', text: 'В работе' },
        { key: 'HOLD', text: 'Холд' },
        { key: 'DONE', text: 'Закрыт' },
    ];

    const milestones = [
        { key: 'Входящие', text: 'Входящие' },
        { key: 'Приемка в работу', text: 'Приемка в работу' },
        { key: 'Дебрифинг', text: 'Дебрифинг' },
        { key: 'Стратегия', text: 'Стратегия' },
        { key: 'Название', text: 'Название' },
        { key: 'Дизайн', text: 'Дизайн' },
        { key: 'Вёрстка', text: 'Вёрстка' },
        // { key: 'Холд', text: 'Холд' },
        { key: 'Закрытие проекта', text: 'Закрытие проекта' },
        { key: 'Закрыт', text: 'Закрыт' },
    ];

    const services = [
        { key: 'Бренд-платформа', text: 'Бренд-платформа' },
        { key: 'Название', text: 'Название' },
        { key: 'Логотип и фирменный стиль', text: 'Логотип и фирменный стиль' },
        { key: 'Дизайн упаковки', text: 'Дизайн упаковки' },
        { key: 'Дизайн сайта', text: 'Дизайн сайта' },
        { key: 'Разработка сайта', text: 'Разработка сайта' },
        { key: 'Дизайн презентации', text: 'Дизайн презентации' },
        { key: 'Гайд по фирменному стилю', text: 'Гайд по фирменному стилю' },
        { key: 'Оформление фирменных носителей', text: 'Оформление фирменных носителей' },
        { key: 'PR', text: 'PR' },
    ];

    return  <Stack horizontal tokens={{ childrenGap: 30 }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
            <TextField label="Номер проекта" value={project.data.code} onChange={(e) => changeCode(e.target.value)} disabled={isChangeDisabled} style={{color: 'black'}} />
            <TextField label="Название проекта" value={project.data.name} onChange={(e) => changeName(e.target.value)} disabled={isChangeDisabled} style={{color: 'black'}} />
            <Dropdown
                placeholder="Выберите"
                label="Статус"
                options={statuses}
                selectedKey={project.data.status}
                onChange={(e, option) => changeStatus(option.key)}
                disabled={isChangeDisabled}
                styles={{ title: {color: 'black'}}}
            />
            <Dropdown
                placeholder="Выберите"
                label="Этап"
                options={milestones}
                selectedKey={project.data.milestone}
                onChange={(e, option) => changeMilestone(option.key)}
                disabled={isChangeDisabled}
                styles={{ title: {color: 'black'}}}
            />
            {isChangeDisabled
                ? <></>
                : <Dropdown
                    placeholder="Выберите"
                    label="Изменить услуги"
                    options={services}
                    multiSelect
                    selectedKeys={project.data.services.map((service) => service.name)}
                    onChange={(e, option) => changeServices(option)}
                />
            }
            {project.data.services.length > 0 ? <>
                <div style={{fontWeight: 600}}>Услуги</div>
                <ul>{project.data.services.map((service, i) => <li key={i}>{service.name}</li>)}</ul>
            </> : ''}
        </Stack>
        <Separator vertical />
        <div>
            {userType === 'FREELANCER'
                    ? <Participants currentUser={currentUser} canSeeFreelancers={false} canChangeManager={false} canChangeWorkers={false} canChangeArtDirector={false} project={project} reloadData={reloadData} setNotification={setNotification} />
                    : <Deadlines userType={userType} project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} />
            }
        </div>
    </Stack>;
}
