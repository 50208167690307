import * as React from "react";
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Stack, TextField,
} from "@fluentui/react";
import {FontIcon} from "@fluentui/react/lib/Icon";
import {useState} from "react";
import removeProjectLinkMethod from "../../../../api/removeProjectLinkMethod";
import addProjectLinkMethod from "../../../../api/addProjectLinkMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {FontSizes} from "@fluentui/theme";

export default function Links({userType, project, reloadData, setNotification}) {
    const [newLink, setNewLink] = useState({type: null, link: ''});

    const handleNewLinkAdded = async () => {
        if (newLink.link === '' || newLink.link === null) {
            setNotification({type: 'error', text: 'Не указана ссылка'});
            return;
        }

        if (newLink.type === null) {
            setNotification({type: 'error', text: 'Не выбран ресурс при добавлении ссылки'});
            return;
        }

        const response = await addProjectLinkMethod(project.data.id, newLink.type, newLink.link);

        if (response.status === 'ok') {
            setNewLink({type: null, link: ''});
            setNotification({type: 'success', text: 'Ссылка добавлена'});
            reloadData();
        }
    };

    let linksColumns = [];
    if (userType === 'FREELANCER') {
        linksColumns = [
            {
                key: 'type',
                name: 'Ресурс',
                fieldName: 'type',
                minWidth: 100,
                maxWidth: 160,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => {
                    if (item.type === 'LOOM') {
                        return <span>Презентация Loom</span>;
                    } else {
                        return <span>Дизайн в Figma</span>;
                    }
                },
            },
            {
                key: 'value',
                name: 'Ссылка',
                fieldName: 'value',
                minWidth: 300,
                maxWidth: 400,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(item.value)}>{item.value}</span>,
            },
        ];
    } else {
        linksColumns = [
            {
                key: 'type',
                name: 'Ресурс',
                fieldName: 'type',
                minWidth: 100,
                maxWidth: 160,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => {
                    if (item.type === 'LOOM') {
                        return <span>Презентация Loom</span>;
                    } else if (item.type === 'FIGMA') {
                        return <span>Дизайн в Figma</span>;
                    } else {
                        return <span>Google Drive</span>;
                    }
                },
            },
            {
                key: 'value',
                name: 'Ссылка',
                fieldName: 'value',
                minWidth: 300,
                maxWidth: 400,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(item.value)}>{item.value}</span>,
            },
            {
                key: 'status',
                name: 'Статус',
                fieldName: 'status',
                minWidth: 120,
                maxWidth: 160,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => <span>Видно клиенту в ЛК</span>,
            },
            {
                key: 'action',
                name: 'Действие',
                fieldName: 'name',
                minWidth: 80,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => {
                    return <FontIcon aria-label="Delete" iconName="Delete" style={{cursor: 'pointer'}} onClick={async () => {
                        await removeProjectLinkMethod(project.data.id, item.id);
                        reloadData();
                    }} />
                },
            },
        ];
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {project.data.links !== null && project.data.links.length !== 0
            ? <DetailsList
                items={project.data.links}
                compact={false}
                columns={linksColumns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
            />
            : <></>
        }
        <div>
            <div style={{ fontSize: FontSizes.size14, marginBottom: 20, marginTop: 20, fontWeight: 'bold' }}>Добавить ссылку</div>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 400 } }}>
                    <TextField placeholder="Ссылка" value={newLink.link} onChange={(e) => setNewLink({...newLink, link: e.target.value})} />
                </Stack>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 200 } }}>
                    <Dropdown
                        placeholder="Ресурс"
                        options={[
                            {key: 'LOOM', text: 'Презентация Loom'},
                            {key: 'FIGMA', text: 'Дизайн в Figma'},
                            {key: 'GOOGLE_DRIVE', text: 'Google Drive'},
                        ]}
                        style={{marginBottom: 10}}
                        selectedKey={newLink.type}
                        onChange={(e, option) => setNewLink({...newLink, type: option.key})}
                    />
                </Stack>
            </Stack>
            <DefaultButton style={{marginTop: 10}} text="Добавить ссылку" onClick={handleNewLinkAdded} />
        </div>
    </Stack>;
}
