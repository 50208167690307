import * as React from "react";
import {PrimaryButton, Stack} from "@fluentui/react";
import createCustomerCabinetMethod from "../../../../api/createCustomerCabinetMethod";
import attachToExistentCustomerCabinetMethod from "../../../../api/attachToExistentCustomerCabinetMethod";
import recreateCustomerCabinetMethod from "../../../../api/recreateCustomerCabinetMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";

export default function CustomerCabinet({project, setNotification, reloadData}) {
    const createCustomerCabinet = async () => {
        const response = await createCustomerCabinetMethod(project.data.id);

        if (response.error) {
            setNotification({type: 'error', text: response.error});
        }

        if (response.status === 'ok') {
            setNotification({type: 'success', text: 'Кабинет клиента создан. Ему на почту отправлен пароль.'});
            reloadData();
        }
    };

    const recreateCustomerCabinet = async () => {
        const response = await recreateCustomerCabinetMethod(project.data.id);

        if (response.error) {
            setNotification({type: 'error', text: response.error});
        }

        if (response.status === 'ok') {
            if (!project.data.hasCustomerCabinet && project.data.hasInCustomerCabinet) {
                setNotification({type: 'success', text: 'Проект удалён из старого кабинета. На новую почту создан новый кабинет и выслан пароль.'});
            } else {
                setNotification({type: 'success', text: 'Проект прикреплен к другому пользователю'});
            }

            reloadData();
        }
    };

    const attachToExistentCustomerCabinet = async () => {
        const response = await attachToExistentCustomerCabinetMethod(project.data.id);

        if (response.error) {
            setNotification({type: 'error', text: response.error});
        }

        if (response.status === 'ok') {
            setNotification({type: 'success', text: 'Проект прикреплён к существующему личному кабинету клиента'});
            reloadData();
        }
    };

    if (!project.data.hasCustomerCabinet && project.data.hasInCustomerCabinet) {
        return <Stack tokens={{ childrenGap: 15 }}>
            <div style={{fontWeight: 600}}>Проект привязан к кабинету клиента с другим Email ({project.data.customerCabinetEmail})</div>
            <div>
                Почему так могло произойти:<br /><br />
                <span style={{fontWeight: 600}}>1. Клиент поменял Email в своём аккаунте.</span><br />В этом случае вы можете поменять Email проекта, на тот, что выше и данное сообщение исчезнет.<br /><br />
                <span style={{fontWeight: 600}}>2. После создания кабинета клиента, вы изменили Email в этом проекте.</span><br />
                Вы можете отвязать проект от старого кабинета и создать новый кабинет клиента, на новый (текущий) Email проекта.<br />
                <DefaultButton style={{width: 400, marginTop: 5}} text="Отвязать от старого кабинета и создать новый" onClick={recreateCustomerCabinet} />
            </div>
        </Stack>;
    }

    if (project.data.hasCustomerCabinet && project.data.hasInCustomerCabinet && project.data.contact.email !== project.data.customerCabinetEmail) {
        return <Stack tokens={{ childrenGap: 15 }}>
            <div style={{fontWeight: 600}}>Проект привязан к кабинету клиента с другим Email ({project.data.customerCabinetEmail})</div>
            <div>
                Почему так могло произойти:<br /><br />
                <span style={{fontWeight: 600}}>1. Клиент поменял Email в своём аккаунте.</span><br />В этом случае вы можете поменять Email проекта, на тот, что выше и данное сообщение исчезнет.<br /><br />
                <span style={{fontWeight: 600}}>2. После создания кабинета клиента, вы изменили Email в этом проекте.</span><br />
                Теперь Email в проекте совпадает с Email другого пользователя.<br />
                Вы можете открепить проект от пользователя {project.data.customerCabinetEmail} и прикрепить к пользователю {project.data.contact.email}.<br />
                <DefaultButton style={{width: 400, marginTop: 5}} text="Прикрепить проект к другому пользователю" onClick={recreateCustomerCabinet} />
            </div>
        </Stack>;
    }

    return  <Stack tokens={{ childrenGap: 15 }}>
        {project.data.hasCustomerCabinet
            ? <>
                <div style={{fontWeight: 600}}>В системе есть кабинет клиента с Email {project.data.contact.email}</div>
                {project.data.hasInCustomerCabinet
                    ? <div style={{color: 'green'}}>Проект прикреплён к личному кабинету клиента</div>
                    : <>
                        <div>Проект не прикреплён к личному кабинету клиента</div>
                        <PrimaryButton style={{width: 300}} text="Прикрепить проект к существующему кабинету" onClick={attachToExistentCustomerCabinet} />
                    </>
                }
            </>
            : <>
                <div style={{fontWeight: 600}}>У клиента c Email {project.data.contact.email} нет личного кабинета</div>
                <div>Если клиент оплатит онлайн по ссылке, кабинет создастся автоматически, сразу после оплаты. Пароль придёт на почту.</div>
                <PrimaryButton style={{width: 300}} text="Создать кабинет клиента" onClick={createCustomerCabinet} />
            </>
        }
    </Stack>;
}
