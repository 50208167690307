import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode, Pivot, PivotItem,
    SelectionMode,
    Separator,
    Stack,
    TextField
} from "@fluentui/react";
import getProjectCollectionMethod from "../../api/getProjectCollectionMethod";
import {FontSizes} from "@fluentui/theme";
import Pagination from "../Theme/Pagination";
import {shortMonths} from "../data/shortMonths";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import exportFinancesToExcelMethod from "../../api/exportFinancesToExcelMethod";
import getUserCollectionMethod from "../../api/getUserCollectionMethod";
import getWaitingClassicPaymentProjectCollectionMethod from "../../api/getWaitingClassicPaymentProjectCollectionMethod";
import getProjectWaitingRealCostsCollectionMethod from "../../api/getProjectWaitingRealCostsCollectionMethod";
import DateRangePicker from "../Shared/DateRangePicker";
import getPaymentCollectionMethod from "../../api/getPaymentCollectionMethod";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import signProjectRealCostsMethod from "../../api/signProjectRealCostsMethod";
import Notification from "../notifications/Notification";

export default function FinanceCollection() {
    const navigate = useNavigate();
    const storedFilterSelection = localStorage.getItem('FINANCE_FILTER') ? JSON.parse(localStorage.getItem('FINANCE_FILTER')) : {manager: 'ALL', artdir: 'ALL', status: 'ALL', milestone: 'ALL', startedAtFrom: null, startedAtTo: null, startedAtFastButtonId: null, presentationDateFrom: null, presentationDateTo: null, presentationDateFastButtonId: null, dueDateFrom: null, dueDateTo: null, dueDateFastButtonId: null, sorting: 'START_DATE_NEW', text: ''};
    const storedOnlinePaymentFilerSelection = localStorage.getItem('ONLINE_PAYMENT_FILTER') ? JSON.parse(localStorage.getItem('ONLINE_PAYMENT_FILTER')) : {paidAtFrom: null, paidAtTo: null, paidAtFastButtonId: null};
    const [filterSelection, setFilterSelection] = useState(storedFilterSelection);
    const [onlinePaymentFilterSelection, setOnlinePaymentFilterSelection] = useState(storedOnlinePaymentFilerSelection);
    const [projectCollection, setProjectCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1, sum: 0, deb: 0, spendingPlan: 0}});
    const [waitingClassicPaymentCollection, setWaitingClassicPaymentCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [waitingRealCostsCollection, setWaitingRealCostsCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [page, setPage] = useState(1);
    const [waitingPaymentPage, setWaitingPaymentPage] = useState(1);
    const [realCostsPage, setRealCostsPage] = useState(1);
    const [managers, setManagers] = useState({loaded: false, data: []});
    const [activePivot, setActivePivot] = useState('paid');

    const [paymentCollection, setPaymentCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [onlinePaymentPage, setOnlinePaymentPage] = useState(1);
    const [notification, setNotification] = useState({type: 'hidden', text: ''});

    // useEffect(() => {
    //     if (!paymentCollection.loaded) {
    //         getPaymentCollectionMethod(page).then(response => {
    //             if (response.error) {
    //                 console.log(response.error.message);
    //                 return;
    //             }
    //
    //             setPaymentCollection({loaded: true, data: response});
    //         });
    //     }
    // });

    useEffect(() => {
        if (!projectCollection.loaded) {
            getProjectCollectionMethod(
                filterSelection.text,
                filterSelection.status,
                filterSelection.milestone,
                filterSelection.manager,
                filterSelection.artdir,
                filterSelection.startedAtFrom,
                filterSelection.startedAtTo,
                filterSelection.presentationDateFrom,
                filterSelection.presentationDateTo,
                filterSelection.dueDateFrom,
                filterSelection.dueDateTo,
                filterSelection.sorting,
                page,
                false,
                20
            ).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setProjectCollection({loaded: true, data: response});
            });
        }
        if (!waitingClassicPaymentCollection.loaded) {
            getWaitingClassicPaymentProjectCollectionMethod(waitingPaymentPage).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setWaitingClassicPaymentCollection({loaded: true, data: response});
            });
        }
        if (!waitingRealCostsCollection.loaded) {
            getProjectWaitingRealCostsCollectionMethod(realCostsPage).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setWaitingRealCostsCollection({loaded: true, data: response});
            });
        }
        if (!managers.loaded) {
            getUserCollectionMethod('MANAGER').then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setManagers({loaded: true, data: response});
            });
        }
        if (!paymentCollection.loaded) {
            getPaymentCollectionMethod(page, onlinePaymentFilterSelection.paidAtFrom, onlinePaymentFilterSelection.paidAtTo).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setPaymentCollection({loaded: true, data: response});
            });
        }
    });

    const reloadCollection = (freshFilterSelection, archived = false) => {
        getProjectCollectionMethod(
            freshFilterSelection.text,
            freshFilterSelection.status,
            freshFilterSelection.milestone,
            freshFilterSelection.manager,
            freshFilterSelection.artdir,
            freshFilterSelection.startedAtFrom,
            freshFilterSelection.startedAtTo,
            freshFilterSelection.presentationDateFrom,
            freshFilterSelection.presentationDateTo,
            freshFilterSelection.dueDateFrom,
            freshFilterSelection.dueDateTo,
            freshFilterSelection.sorting,
            page,
            false,
            20
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setProjectCollection({loaded: true, data: response});
        });
    };

    const renewPage = (page) => {
        setPage(page);
        getProjectCollectionMethod(
            filterSelection.text,
            filterSelection.status,
            filterSelection.milestone,
            filterSelection.manager,
            filterSelection.artdir,
            filterSelection.startedAtFrom,
            filterSelection.startedAtTo,
            filterSelection.presentationDateFrom,
            filterSelection.presentationDateTo,
            filterSelection.dueDateFrom,
            filterSelection.dueDateTo,
            filterSelection.sorting,
            page,
            false,
            20
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setProjectCollection({loaded: true, data: response});
        });
    };

    const renewWaitingPage = (page) => {
        setWaitingPaymentPage(page);
        getWaitingClassicPaymentProjectCollectionMethod(page).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setWaitingClassicPaymentCollection({loaded: true, data: response});
        });
    };

    const renewRealCostsPage = (page) => {
        setRealCostsPage(page);
        getProjectWaitingRealCostsCollectionMethod(page).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setWaitingRealCostsCollection({loaded: true, data: response});
        });
    };

    const renewOnlinePaymentPage = (page) => {
        setOnlinePaymentPage(page);
        getPaymentCollectionMethod(page, onlinePaymentFilterSelection.paidAtFrom, onlinePaymentFilterSelection.paidAtTo).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setPaymentCollection({loaded: true, data: response});
        });
    };

    const columns = [
        {
            key: 'code',
            name: 'Номер',
            fieldName: 'code',
            maxWidth: 80,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: 'startedAt',
            name: 'Дата старта',
            fieldName: 'startedAt',
            maxWidth: 20,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.startedAt === null) {
                    return <div>не указано</div>;
                }

                const date = new Date(item.startedAt);

                return <div>{date.getDate()} {shortMonths[date.getMonth()]} {date.getFullYear()}</div>;
            }
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            maxWidth: 350,
            minWidth: 200,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.name === null) {
                    return <div>не указано</div>;
                }

                return <div>{item.name}</div>;
            }
        },
        {
            key: 'customer',
            name: 'Заказчик',
            maxWidth: 350,
            minWidth: 200,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.company !== null && item.company.name !== null) {
                    return <div>{item.company.name}</div>;
                }

                if (item.contact.name !== null) {
                    return <div>{(item.contact.lastName ?? '') + ' ' + (item.contact.firstName ?? item.contact.name) + ' ' + (item.contact.middleName ?? '')}</div>;
                }

                return <div></div>;
            }
        },
        {
            key: 'status',
            name: 'Статус',
            fieldName: 'status',
            maxWidth: 90,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.status === 'IN_PROGRESS') {
                    return <div>В работе</div>;
                }

                if (item.status === 'HOLD') {
                    return <div>Холд</div>;
                }

                return <div>Закрыт</div>;
            }
        },
        {
            key: 'manager',
            name: 'Менеджер',
            fieldName: 'manager',
            isRowHeader: true,
            data: 'string',
            minWidth: 120,
            isPadded: true,
            onRender: (item) => {
                if (!item.manager) {
                    return <div></div>;
                }

                return <div>{item.manager}</div>;
            }
        },
        {
            key: 'summaryPrice',
            name: 'Доход, руб.',
            fieldName: 'summaryPrice',
            isRowHeader: true,
            data: 'string',
            minWidth: 100,
            isPadded: true,
            onRender: (item) => {
                if (!item.summaryPrice) {
                    return <div></div>;
                }

                return <div>{new Intl.NumberFormat().format(item.summaryPrice)} руб.</div>;
            }
        },
        {
            key: 'paid',
            name: 'Нам оплатили, руб.',
            fieldName: 'paid',
            isRowHeader: true,
            data: 'string',
            minWidth: 120,
            isPadded: true,
            onRender: (item) => {
                return <div>{new Intl.NumberFormat().format(item.paid)} руб.</div>;
            }
        },
        {
            key: 'left',
            name: 'Нам должны, руб.',
            isRowHeader: true,
            data: 'string',
            minWidth: 120,
            isPadded: true,
            onRender: (item) => {
                return <div>{new Intl.NumberFormat().format(item.summaryPrice - item.paid)} руб.</div>;
            }
        },
        {
            key: 'estimatedSummarySpending',
            name: 'Расход план, руб.',
            fieldName: 'estimatedSummarySpending',
            isRowHeader: true,
            data: 'string',
            minWidth: 100,
            isPadded: true,
            onRender: (item) => {
                if (!item.estimatedSummarySpending) {
                    return <div></div>;
                }

                return <div>{new Intl.NumberFormat().format(item.estimatedSummarySpending)} руб.</div>;
            }
        },
        {
            key: 'summarySpending',
            name: 'Расход факт, руб.',
            fieldName: 'summarySpending',
            isRowHeader: true,
            data: 'string',
            minWidth: 100,
            isPadded: true,
            onRender: (item) => {
                if (!item.summarySpending) {
                    return <div></div>;
                }

                return <div>{new Intl.NumberFormat().format(item.summarySpending)} руб.</div>;
            }
        },
        {
            key: 'estimatedSummaryMargin',
            name: 'Маржа план, руб.',
            fieldName: 'estimatedSummaryMargin',
            isRowHeader: true,
            data: 'string',
            minWidth: 100,
            isPadded: true,
            onRender: (item) => {
                if (!item.estimatedSummaryMargin) {
                    return <div></div>;
                }

                return <div>{new Intl.NumberFormat().format(item.estimatedSummaryMargin)} руб.</div>;
            }
        },
        {
            key: 'estimatedMarginPercent',
            name: 'Маржа план, %',
            fieldName: 'estimatedMarginPercent',
            isRowHeader: true,
            data: 'string',
            minWidth: 80,
            isPadded: true,
            onRender: (item) => {
                if (!item.estimatedMarginPercent) {
                    return <div></div>;
                }

                return <div>{item.estimatedMarginPercent+'%'}</div>;
            }
        },
    ];

    const waitingPaymentColumns = [
        {
            key: 'code',
            name: 'Номер проекта',
            fieldName: 'code',
            maxWidth: 150,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: 'paymentStepTitle',
            name: 'Тип платежа',
            isRowHeader: true,
            data: 'string',
            maxWidth: 160,
            minWidth: 80,
            isPadded: true,
            onRender: (item) => {
                if (item.prepayment.type === 'CLASSIC' && item.prepayment.status === 'WAITING_PAYMENT') {
                    return <div>{item.prepayment.title}</div>;
                }

                if (item.postPayment.type === 'CLASSIC' && item.postPayment.status === 'WAITING_PAYMENT') {
                    return <div>{item.postPayment.title}</div>;
                }

                let paymentStepTitle = null;
                item.paymentSteps.forEach((paymentStep) => {
                    if (paymentStep.type === 'CLASSIC' && paymentStep.status === 'WAITING_PAYMENT') {
                        paymentStepTitle = paymentStep.title;
                    }
                });

                return <div>{paymentStepTitle}</div>;
            }
        },
        {
            key: 'paymentSum',
            name: 'Сумма платежа, руб.',
            fieldName: 'paymentSum',
            isRowHeader: true,
            data: 'string',
            maxWidth: 180,
            minWidth: 80,
            isPadded: true,
            onRender: (item) => {
                if (item.prepayment.type === 'CLASSIC' && item.prepayment.status === 'WAITING_PAYMENT') {
                    return <div>{new Intl.NumberFormat().format(item.prepayment.sum)} руб.</div>;
                }

                if (item.postPayment.type === 'CLASSIC' && item.postPayment.status === 'WAITING_PAYMENT') {
                    return <div>{new Intl.NumberFormat().format(item.postPayment.sum)} руб.</div>;
                }

                let paymentStepSum = null;
                item.paymentSteps.forEach((paymentStep) => {
                    if (paymentStep.type === 'CLASSIC' && paymentStep.status === 'WAITING_PAYMENT') {
                        paymentStepSum = new Intl.NumberFormat().format(paymentStep.sum) + ' руб.';
                    }
                });

                return <div>{paymentStepSum}</div>;
            }
        },
        {
            key: 'customer',
            name: 'Заказчик',
            maxWidth: 200,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.company !== null && item.company.name !== null) {
                    return <div>{item.company.name}</div>;
                }

                if (item.contact.name !== null) {
                    return <div>{(item.contact.lastName ?? '') + ' ' + (item.contact.firstName ?? item.contact.name) + ' ' + (item.contact.middleName ?? '')}</div>;
                }

                return <div></div>;
            }
        },
        {
            key: 'companyInn',
            name: 'ИНН Заказчика',
            maxWidth: 180,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.company !== null && item.company.taxIdentificationNumber !== null) {
                    return <div>{item.company.taxIdentificationNumber}</div>;
                }

                return <div>Не указан</div>;
            }
        },
        {
            key: 'name',
            name: 'Название проекта',
            fieldName: 'name',
            maxWidth: 350,
            minWidth: 120,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.name === null) {
                    return <div>не указано</div>;
                }

                return <div>{item.name}</div>;
            }
        },
        {
            key: 'manager',
            name: 'Менеджер',
            fieldName: 'manager',
            isRowHeader: true,
            data: 'string',
            minWidth: 120,
            isPadded: true,
            onRender: (item) => {
                if (!item.manager) {
                    return <div></div>;
                }

                return <div>{item.manager}</div>;
            }
        },
        {
            key: 'status',
            name: 'Статус',
            fieldName: 'status',
            maxWidth: 150,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.status === 'IN_PROGRESS') {
                    return <div>В работе</div>;
                }

                if (item.status === 'HOLD') {
                    return <div>Холд</div>;
                }

                return <div>Закрыт</div>;
            }
        },
    ];

    const onlinePaymentColumns = [
        {
            key: 'date',
            name: 'Дата оплаты',
            fieldName: 'paidAt',
            minWidth: 100,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
        },
        {
            key: 'projectCode',
            name: 'Проект',
            fieldName: 'projectCode',
            minWidth: 80,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return item.projectCode === null ? '' : item.projectCode;
            }
        },
        {
            key: 'title',
            name: 'Назначение платежа',
            fieldName: 'title',
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'customerFullName',
            name: 'ФИО клиента',
            fieldName: 'customerFullName',
            minWidth: 200,
            maxWidth: 360,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'status',
            name: 'Статус',
            fieldName: 'status',
            minWidth: 50,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item.status === 'PAID') {
                    return <div style={{color: 'green'}}>Оплачен</div>
                } else if (item.status === 'CANCELED') {
                    return <div style={{color: 'red'}}>Отменён</div>
                } else if (item.status === 'PENDING') {
                    return <div style={{color: 'blue'}}>В обработке</div>
                }

                return <div>Не оплачен</div>
            }
        },
        {
            key: 'amount',
            name: 'Сумма',
            fieldName: 'amount',
            minWidth: 90,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    ];

    const realCostsColumns = [
        {
            key: 'code',
            name: '№ проекта',
            fieldName: 'code',
            maxWidth: 80,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: 'contact',
            name: 'Заказчик',
            fieldName: 'contact',
            minWidth: 120,
            maxWidth: 200,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item.company !== null && item.company.name !== null) {
                    return <div>{item.company.name}</div>;
                }

                if (item.contact.name !== null) {
                    return <div>{(item.contact.lastName ?? '') + ' ' + (item.contact.firstName ?? item.contact.name) + ' ' + (item.contact.middleName ?? '')}</div>;
                }

                return <div></div>;
            }
        },
        {
            key: 'manager',
            name: 'Менеджер',
            fieldName: 'manager',
            isRowHeader: true,
            data: 'string',
            minWidth: 120,
            isPadded: true,
            onRender: (item) => {
                if (item.manager === null) {
                    return <div>нет</div>;
                }

                return <div>{item.manager}</div>;
            }
        },
        {
            key: 'summaryPrice',
            name: 'Доход план',
            fieldName: 'summaryPrice',
            isRowHeader: true,
            data: 'string',
            minWidth: 80,
            isPadded: true,
            onRender: (item) => {
                if (!item.summaryPrice) {
                    return <div>не указан</div>;
                }

                return <div>{new Intl.NumberFormat().format(item.summaryPrice)} руб.</div>;
            }
        },
        {
            key: 'paid',
            name: 'Доход факт',
            fieldName: 'paid',
            isRowHeader: true,
            data: 'string',
            minWidth: 80,
            isPadded: true,
            onRender: (item) => {
                if (!item.paid) {
                    return <div>не указан</div>;
                }

                return <div>{new Intl.NumberFormat().format(item.paid)} руб.</div>;
            }
        },
        {
            key: 'summarySpending',
            name: 'Расход факт',
            fieldName: 'summarySpending',
            isRowHeader: true,
            data: 'string',
            minWidth: 80,
            isPadded: true,
            onRender: (item) => {
                if (!item.summarySpending) {
                    return <div>не указан</div>;
                }

                return <div>{new Intl.NumberFormat().format(item.summarySpending)} руб.</div>;
            }
        },
        {
            key: 'marginPercent',
            name: 'Маржа',
            fieldName: 'marginPercent',
            isRowHeader: true,
            data: 'string',
            minWidth: 80,
            isPadded: true,
            onRender: (item) => {
                if (!item.marginPercent) {
                    return <div>не указана</div>;
                }

                return <div>{parseInt(item.marginPercent)}%</div>;
            }
        },
        {
            key: 'milestone',
            name: 'Статус',
            fieldName: 'milestone',
            isRowHeader: true,
            minWidth: 80,
            isPadded: true,
        },
        {
            key: 'acceptClosing',
            name: 'Подтвердить закрытие',
            isRowHeader: true,
            data: 'string',
            minWidth: 200,
            isPadded: true,
            onRender: (item) => {
                return <PrimaryButton className="accept-closing" text="Подтвердить" onClick={async () => {
                    const response = await signProjectRealCostsMethod(item.id);
                    if (response.error) {
                        setNotification({type: 'error', text: response.error});
                        return;
                    }

                    if (response.status === 'ok') {
                        setNotification({type: 'success', text: `Закрытие проекта ${item.code} подтверждено`});
                        getProjectWaitingRealCostsCollectionMethod(realCostsPage).then(response => {
                            if (response.error) {
                                console.log(response.error.message);
                                return;
                            }

                            setWaitingRealCostsCollection({loaded: true, data: response});
                        });
                    }
                }}/>;
            }
        },
    ];

    const dropdownStyles = {
        dropdown: { width: 185 },
    };

    const changeFilter = (newFilterSelection) => {
        localStorage.setItem('FINANCE_FILTER', JSON.stringify(newFilterSelection));
        setFilterSelection(newFilterSelection);
        reloadCollection(newFilterSelection);
    };

    const changeOnlinePaymentFilter = (newFilterSelection) => {
        localStorage.setItem('ONLINE_PAYMENT_FILTER', JSON.stringify(newFilterSelection));
        setOnlinePaymentFilterSelection(newFilterSelection);

        getPaymentCollectionMethod(page, newFilterSelection.paidAtFrom, newFilterSelection.paidAtTo).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setPaymentCollection({loaded: true, data: response});
        });
    };

    const managersList = managers.data.map((item) => {
        return {key: item.id, text: item.firstName + ' ' + (item.lastName ?? '')}
    });
    managersList.unshift({key: 'ALL', text: 'Все'});

    const filters = {text: '', managers: managersList, artdirs: [], statuses: [
            { key: 'ALL', text: 'Все статусы' },
            { key: 'IN_PROGRESS', text: 'В работе' },
            { key: 'HOLD', text: 'Холд' },
            { key: 'DONE', text: 'Закрыт' },
        ], milestones: [], sorting: [
            { key: 'START_DATE_NEW', text: 'Сначала новые' },
            { key: 'START_DATE_OLD', text: 'Сначала старые' },
        ]};

    const hash = window.location.hash && window.location.hash.substring(1);
    if (activePivot !== 'waiting' && hash === 'waiting') {
        setActivePivot('waiting');
    } else if (activePivot !== 'closing' && hash === 'closing') {
        setActivePivot('closing');
    } else if (activePivot !== 'online' && hash === 'online') {
        setActivePivot('online');
    }

    const projectsCountLastDigit = parseInt(projectCollection.data.count.toString().slice(-1));
    let projectsCountWord = '';
    if (projectsCountLastDigit === 1) {
        projectsCountWord = 'проект';
    } else if (projectsCountLastDigit > 1 && projectsCountLastDigit < 5) {
        projectsCountWord = 'проекта';
    } else {
        projectsCountWord = 'проектов';
    }

    const waitingClassicPaymentCountLastDigit = parseInt(waitingClassicPaymentCollection.data.count.toString().slice(-1));
    let waitingClassicPaymentCountWord = '';
    if (waitingClassicPaymentCountLastDigit === 1) {
        waitingClassicPaymentCountWord = 'проект';
    } else if (waitingClassicPaymentCountLastDigit > 1 && waitingClassicPaymentCountLastDigit < 5) {
        waitingClassicPaymentCountWord = 'проекта';
    } else {
        waitingClassicPaymentCountWord = 'проектов';
    }

    const waitingRealCostsCountLastDigit = parseInt(waitingRealCostsCollection.data.count.toString().slice(-1));
    let waitingRealCostsCountWord = '';
    if (projectsCountLastDigit === 1) {
        waitingRealCostsCountWord = 'проект';
    } else if (waitingRealCostsCountLastDigit > 1 && waitingRealCostsCountLastDigit < 5) {
        waitingRealCostsCountWord = 'проекта';
    } else {
        waitingRealCostsCountWord = 'проектов';
    }

    return (<>
        <div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Финансы</div>
        <Notification type={notification.type} text={notification.text} />
        <Pivot aria-label="Меню" selectedKey={activePivot} onLinkClick={(item) => {
            window.history.pushState('', '/', window.location.pathname);
            setActivePivot(item.props.itemKey);
        }}>
            <PivotItem headerText="Предоплаченные проекты" itemKey="paid">
                <Stack style={{marginBottom: 10, marginTop: 20}} tokens={{ childrenGap: 5 }}>
                    {/*<div style={{fontWeight: 600}}>Даты начала проекта</div>*/}
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 185 } }}>
                            <TextField label="Номер проекта" value={filterSelection.text ?? null} onChange={(e) => changeFilter({...filterSelection, text: e.target.value})} />
                        </Stack>
                        <Dropdown
                            placeholder="Все статусы"
                            label="Статус"
                            options={filters.statuses}
                            styles={dropdownStyles}
                            selectedKey={filterSelection.status}
                            onChange={(e, option) => changeFilter({...filterSelection, status: option.key})}
                        />
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <DateRangePicker
                            label="Дата старта"
                            onChange={(dateFrom, dateTo, fastSelectButtonId) => changeFilter({...filterSelection, startedAtFrom: dateFrom, startedAtTo: dateTo, startedAtFastButtonId: fastSelectButtonId})}
                            defaultDateFrom={filterSelection.startedAtFrom}
                            defaultDateTo={filterSelection.startedAtTo}
                            defaultSelectedButtonId={filterSelection.startedAtFastButtonId}
                        />
                        <Dropdown
                            placeholder="Все"
                            label="Менеджер"
                            options={filters.managers}
                            styles={dropdownStyles}
                            selectedKey={filterSelection.manager}
                            onChange={(e, option) => changeFilter({...filterSelection, manager: option.key})}
                        />
                        <Dropdown
                            placeholder="Сначала новые"
                            label="Сортировка"
                            options={filters.sorting}
                            styles={dropdownStyles}
                            selectedKey={filterSelection.sorting}
                            onChange={(e, option) => changeFilter({...filterSelection, sorting: option.key})}
                        />
                        <div style={{display: 'flex', alignItems: 'end'}}>
                            <DefaultButton text="Экспорт Excel" iconProps={{ iconName: 'ExcelDocument' }} onClick={() => exportFinancesToExcelMethod(filterSelection.startedAtFrom, filterSelection.startedAtTo, filterSelection.status, filterSelection.manager)} />
                        </div>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }} style={{marginTop: 20}}>
                    <div>Найдено: <span style={{fontWeight: 600}}>{projectCollection.data.count}</span> {projectsCountWord} на сумму <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(projectCollection.data.sum)} руб.</span></div>
                    {projectCollection.data.spendingPlan !== 0
                        ? <>
                            <Separator vertical />
                            <div>План расходов: <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(projectCollection.data.spendingPlan)} руб.</span></div>
                        </>
                        : <></>
                    }
                    <Separator vertical />
                    <div>Дебиторка: <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(projectCollection.data.deb)} руб.</span></div>
                </Stack>
                <DetailsList
                    items={projectCollection.data.collection}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    getKey={(item, index) => item.key}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onRenderRow={(props, Row) => {
                        return <div style={{cursor: 'pointer'}} onClick={(e) => {
                            if (e.target.classList.contains('archive')) {
                                return;
                            }

                            navigate('/finance/' + props.item.id);
                        }}>
                            <Row {...props} />
                        </div>;
                    }}
                />
                <div style={{marginTop: 20}}>
                    <Pagination page={page} lastPage={projectCollection.data.lastPage} renewPage={renewPage} />
                </div>
            </PivotItem>
            <PivotItem headerText="Ожидают оплату" itemKey="waiting">
                <Stack horizontal tokens={{ childrenGap: 10 }} style={{marginTop: 20}}>
                    <div>Найдено: <span style={{fontWeight: 600}}>{waitingClassicPaymentCollection.data.count}</span> {waitingClassicPaymentCountWord}</div>
                </Stack>
                <DetailsList
                    items={waitingClassicPaymentCollection.data.collection}
                    compact={false}
                    columns={waitingPaymentColumns}
                    selectionMode={SelectionMode.none}
                    getKey={(item, index) => item.key}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onRenderRow={(props, Row) => {
                        return <div style={{cursor: 'pointer'}} onClick={(e) => {
                            if (e.target.classList.contains('archive')) {
                                return;
                            }

                            navigate('/finance/' + props.item.id + '/only-payment-steps');
                        }}>
                            <Row {...props} />
                        </div>;
                    }}
                />
                <div style={{marginTop: 20}}>
                    <Pagination page={waitingPaymentPage} lastPage={waitingClassicPaymentCollection.data.lastPage} renewPage={renewWaitingPage} />
                </div>
            </PivotItem>
            <PivotItem headerText="Закрытие проекта" itemKey="closing">
                <Stack horizontal tokens={{childrenGap: 10}} style={{marginTop: 20}}>
                    <div>Найдено: <span
                        style={{fontWeight: 600}}>{waitingRealCostsCollection.data.count}</span> {waitingRealCostsCountWord}
                    </div>
                    <Separator vertical/>
                    <div>Сумма поступлений: <span
                        style={{fontWeight: 600}}>{new Intl.NumberFormat().format(waitingRealCostsCollection.data.summaryIncome)}</span> руб.
                    </div>
                    <Separator vertical/>
                    <div>Сумма списаний: <span
                        style={{fontWeight: 600}}>{new Intl.NumberFormat().format(waitingRealCostsCollection.data.summarySpending)}</span> руб.
                    </div>
                </Stack>
                <DetailsList
                    items={waitingRealCostsCollection.data.collection}
                    compact={false}
                    columns={realCostsColumns}
                    selectionMode={SelectionMode.none}
                    getKey={(item, index) => item.key}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onRenderRow={(props, Row) => {
                        return <div style={{cursor: 'pointer'}} onClick={(e) => {
                            if (e.target.classList.contains('archive') || e.target.classList.contains('ms-Button-label')) {
                                return;
                            }

                            navigate('/finance/' + props.item.id + '/closing');
                        }}>
                            <Row {...props} />
                        </div>;
                    }}
                />
                <div style={{marginTop: 20}}>
                    <Pagination page={realCostsPage} lastPage={waitingRealCostsCollection.data.lastPage} renewPage={renewRealCostsPage} />
                </div>
            </PivotItem>
            <PivotItem headerText="Онлайн-платежи" itemKey="online">
                {/*<div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Онлайн-платежи</div>*/}
                <Stack style={{marginBottom: 10, marginTop: 20}} tokens={{ childrenGap: 5 }}>
                    <DateRangePicker
                        label="Дата оплаты"
                        onChange={(dateFrom, dateTo, fastSelectButtonId) => changeOnlinePaymentFilter({...onlinePaymentFilterSelection, paidAtFrom: dateFrom, paidAtTo: dateTo, startedAtFastButtonId: fastSelectButtonId})}
                        defaultDateFrom={onlinePaymentFilterSelection.paidAtFrom}
                        defaultDateTo={onlinePaymentFilterSelection.paidAtTo}
                        defaultSelectedButtonId={onlinePaymentFilterSelection.paidAtFastButtonId}
                    />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }} style={{marginTop: 20}}>
                    <div>Найдено: <span style={{fontWeight: 600}}>{paymentCollection.data.count}</span> записей</div>
                </Stack>
                <DetailsList
                    items={paymentCollection.data.collection}
                    compact={false}
                    columns={onlinePaymentColumns}
                    selectionMode={SelectionMode.none}
                    getKey={(item, index) => item.key}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onRenderRow={(props, Row) => {
                        return <div style={{cursor: 'pointer'}} onClick={(e) => {
                            if (e.target.classList.contains('archive')) {
                                return;
                            }

                            navigate('/finance/payments/' + props.item.projectId + '/' + props.item.onlinePaymentId);
                        }}>
                            <Row {...props} />
                        </div>;
                    }}
                />
                <div style={{marginTop: 20}}>
                    <Pagination page={onlinePaymentPage} lastPage={paymentCollection.data.lastPage} renewPage={renewOnlinePaymentPage} />
                </div>
            </PivotItem>
        </Pivot>
    </>);
};
