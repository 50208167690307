import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    Breadcrumb, DetailsList, DetailsListLayoutMode, mergeStyleSets, Pivot, PivotItem, SelectionMode,
    Separator,
    Stack,
    TextField, TooltipHost
} from "@fluentui/react";
import {InitialProjectData} from "../Project/ViewProject/Data/InitialProjectData";
import getSingleProjectMethod from "../../api/getSingleProjectMethod";
import Notification from "../notifications/Notification";
import Finance from "../Project/ViewProject/Tabs/Finance/Finance";
import {Dropdown} from "@fluentui/react/lib/Dropdown";

export default function ViewPaymentSteps() {
    const params = useParams();
    const [project, setProject] = useState({loaded: false, data: InitialProjectData});
    const [notification, setNotification] = useState({type: 'hidden', text: ''});

    const reloadData = () => setProject({loaded: false, data: {...project.data}});

    useEffect(() => {
        if (!project.loaded) {
            getSingleProjectMethod(params.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setProject({loaded: true, data: response});
            });
        }
    });

    const navigate = useNavigate();

    const breadcrumbs = [
        { text: 'Финансы', key: 'financeCollection', href: '/finance#waiting', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: project.data.code ?? project.data.id, key: 'viewFinance', href: '/finance/' + project.data.id + '/only-payment-steps', isCurrentItem: true },
    ];

    const statuses = [
        { key: 'IN_PROGRESS', text: 'В работе' },
        { key: 'HOLD', text: 'Холд' },
        { key: 'DONE', text: 'Закрыт' },
    ];

    let customer = '';

    if (project.data.company !== null && project.data.company.name !== null) {
        customer = project.data.company.name;
    }

    if (project.data.contact.name !== null) {
        customer = (project.data.contact.lastName ?? '') + ' ' + (project.data.contact.firstName ?? project.data.contact.name) + ' ' + (project.data.contact.middleName ?? '');
    }

    return (
        <div>
            <Breadcrumb
                items={breadcrumbs}
                maxDisplayedItems={10}
                ariaLabel="Навигация"
                overflowAriaLabel="Показать больше"
                style={{marginBottom: 20}}
            />
            <Notification type={notification.type} text={notification.text} />
            <div style={{marginTop: 20}}>
                <Stack horizontal tokens={{ childrenGap: 30 }} style={{marginBottom: 15}}>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Название проекта" value={project.data.name} disabled={true} style={{color: 'black'}} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Номер проекта" value={project.data.code} disabled={true} style={{color: 'black'}} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <Dropdown
                            placeholder="Выберите"
                            label="Статус"
                            options={statuses}
                            selectedKey={project.data.status}
                            disabled={true}
                            styles={{ title: {color: 'black'}}}
                        />
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 30 }}>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Заказчик" value={customer} disabled={true} style={{color: 'black'}} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="ИНН заказчика" value={project.data.company !== null ? project.data.company.taxIdentificationNumber : ''} disabled={true} style={{color: 'black'}} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Менеджер" value={project.data.manager ? project.data.manager.name : 'Не указан'} disabled={true} style={{color: 'black'}} />
                    </Stack>
                </Stack>
                <Separator />
                <div style={{marginTop: 20}}>
                    <Finance project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} canChangeFinanceFact={true} isShorty={true} />
                </div>
            </div>
        </div>
    );
}
