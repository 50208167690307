import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function archiveProjectMethod(id) {
    const url = new URL(serverUrl + '/api/v2/pc/archive-project');

    return fetch(url, {
        method: 'PATCH',
        body: JSON.stringify({id: id}),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
