import {getAuthToken} from "../../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function getChatCollectionMethod(page) {
    const searchParams = new URLSearchParams({page});
    const url = new URL(serverUrl + '/api/v2/pc/chats?' + searchParams);

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
