import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function addTeamMethod(name, about, members, services) {
    const url = new URL(serverUrl + '/api/v2/pc/add-team');

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({name: name, about: about, members: members, services: services}),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
