import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import getDocmasterTypesMethod from "../../api/getDocmasterTypesMethod";
import {DetailsList, DetailsListLayoutMode, SelectionMode} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";

export default function TypeCollection() {
    const navigate = useNavigate();
    const [types, setTypes] = useState({loaded: false, data: []});

    useEffect(() => {
        if (!types.loaded) {
            getDocmasterTypesMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setTypes({loaded: true, data: response});
            });
        }
    });

    const columns = [
        {
            key: 'value',
            name: 'Тип документа',
            fieldName: 'value',
            maxWidth: 80,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
        },
    ];

    return <>
        <div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>DOCMASTER</div>
        <div style={{ fontSize: FontSizes.size18, marginBottom: 20,fontWeight: 600 }}>Выберите шаблон документа</div>
        <DetailsList
            items={types.data}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => item.key}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={false}
            onRenderRow={(props, Row) => {
                return <div style={{cursor: 'pointer'}} onClick={(e) => {
                    if (e.target.classList.contains('archive')) {
                        return;
                    }

                    navigate('/docmaster/' + props.item.name);
                }}>
                    <Row {...props} />
                </div>;
            }}
        />
    </>
};
