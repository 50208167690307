import * as React from "react";
import {FontSizes} from "@fluentui/theme";
import {
    DefaultFontStyles, DetailsList, DetailsListLayoutMode,
    DocumentCard, DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    getTheme, SelectionMode, Separator, Spinner, SpinnerSize, TooltipHost
} from "@fluentui/react";
import {useEffect, useState} from "react";
import getAnalyticMethod from "../api/getAnalyticMethod";
import {VerticalBarChart, DonutChart} from "@fluentui/react-charting";
import getCurrentUserMethod from "../api/getCurrentUserMethod";
import {useNavigate} from "react-router-dom";
import DateRangePicker from "./Shared/DateRangePicker";

const initialAnalytic = {
    // visitsToday: 0,
    // leadsToday: 0,
    // leadsIn30Days: 0,
    leadsCountPerEveryMonth: [],
    leadsBySources: [],
    currentYearProjectSum: 0,
    currentYearProjectCount: 0,
    yearlyPlan: 0,
    yearlyFact: 0,
    projectsInProgress: 0,
    projectsDone: 0,
    projectsCountByTypes: {},
    projectsOnSigning: 0,
    leadsMonthlyCountByStatuses: {},
    projectsCosts: 0,
};

const theme = getTheme();

export default function MainPage() {
    const navigate = useNavigate();
    const [analytic, setAnalytic] = useState({loaded: false, data: initialAnalytic});
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[],status:''},type:''});
    const [dates, setDates] = useState([null, null, 'CURRENT_YEAR']);

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    useEffect(() => {
        if (!analytic.loaded) {
            getAnalyticMethod(dates[0], dates[1]).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setDates([response.fromDate, response.toDate, 'CURRENT_YEAR']);
                setAnalytic({loaded: true, data: response});
            });
        }
    });

    const changeDates = (fromDate, toDate, fastSelectButtonId) => {
        setDates([fromDate, toDate, fastSelectButtonId]);
        getAnalyticMethod(fromDate, toDate).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setAnalytic({loaded: true, data: response});
        });
    };

    const axisStyle = {
        stroke: '#ef5021',
    };
    const textStyle = {
        fill: '#ef5021',
        fontSize: '10px',
    };

    const customStyles = () => {
        return {
            chart: {
                paddingBottom: '25px',
            },
            chartLabel: {
                color: '#ef5021',
                ...DefaultFontStyles.large,
            },
            xAxisDomain: axisStyle,
            xAxisTicks: axisStyle,
            yAxisDomain: axisStyle,
            yAxisTicks: axisStyle,
            yAxisText: textStyle,
        };
    };

    const leadsBySourcesColumns = [
        {
            key: 'pageName',
            name: 'Страница',
            fieldName: 'pageName',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'count',
            name: 'Кол-во лидов',
            fieldName: 'count',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    ];

    const { palette, fonts } = theme;

    let hasAccess = null;
    let canSeeVisitStatistics = false;
    let canSeePageRating = false;
    let canSeeLeadStatistics = false;
    let canSeeContractStatistics = false;
    let canSeeContractStatisticsTeamId = null;
    user.security.permissions.forEach((permission) => {
        if (permission.key === 'see_visit_statistics' && permission.type === 'ALL') {
            canSeeVisitStatistics = true;
        }

        if (permission.key === 'see_page_rating' && permission.type === 'ALL') {
            canSeePageRating = true;
        }

        if (permission.key === 'see_leads_statistics') {
            canSeeLeadStatistics = true;
        }

        if (permission.key === 'see_contracts_statistics') {
            canSeeContractStatistics = true;
            if (permission.teamId !== 'null' && permission.teamId !== null) {
                canSeeContractStatisticsTeamId = permission.teamId;
            }
        }

        if (permission.key === 'analytic_module') {
            hasAccess = true;
        }
    });

    if (user.contact.email.length === 0 || !analytic.loaded) {
        return <Spinner size={SpinnerSize.large} />;
    }

    if (!hasAccess && user.type !== 'FREELANCER') {
        navigate('/projects');
    }

    const colors = ['#EF5021', '#F09A22', '#5F8082', '#9ED3D7', '#D7BF9E', '#1D1D1D'];
    //const colors = ['#EF5021', '#F02274', '#F07622', '#F02E22', '#F09A22', '#F08F71'];
    //const colors = ['#EF5021', '#3051DB', '#84DB30', '#719C49', '#86584A', '#4A5686'];
    //const colors = ['#EF5021', '#825F6D', '#5F826D', '#9ED7B5', '#D7AC9E', '#67FFA3'];
    //const colors = ['#EF5021', '#5F6682', '#82715F', '#5F7882', '#D7BC9E', '#91A7FF'];

    const hours = new Date().getHours();
    let greeting = 'Доброй ночи';
    let emoji = '🌙';

    if (hours >= 23) {
        greeting = 'Доброй ночи';
        emoji = '🌙';
    } else if (hours >= 17) {
        greeting = 'Добрый вечер';
        emoji = '🌙';
    } else if (hours >= 12) {
        greeting = 'Добрый день';
        emoji = '☀️';
    } else if (hours >= 5) {
        greeting = 'Доброе утро';
        emoji = '☀️';
    }

    if (user.type === 'FREELANCER') {
        return (<div>
            {user.contact.firstName && user.contact.firstName.length > 0
                ? <div style={{ fontSize: FontSizes.size20, marginBottom: 30, fontWeight: 600 }}>{greeting}, {user.contact.firstName} {emoji}</div>
                : <div style={{ fontSize: FontSizes.size20, marginBottom: 30, fontWeight: 600 }}>{greeting} {emoji}</div>
            }
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4" style={{paddingLeft: 0, paddingRight: 16}}>
                        <div style={{ fontSize: FontSizes.size18, marginBottom: 10, fontWeight: 600 }}>Проектная статистика</div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 20}}>
                            <div>
                                <DocumentCard type={DocumentCardType.compact} style={{padding: 10, display: 'block', height: 'unset'}}>
                                    <div style={{marginBottom: 5}}>Всего проектов: <span style={{color: '#EF5121'}}>{analytic.data.currentYearProjectCount} шт.</span></div>
                                    <div style={{marginBottom: 5}}>Текущие: <span style={{color: '#EF5121'}}>{analytic.data.projectsInProgress} шт.</span></div>
                                    <div style={{marginBottom: 2}}>Завершенные: <span style={{color: '#EF5121'}}>{analytic.data.projectsDone} шт.</span></div>
                                </DocumentCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    return (<div>
        {user.contact.firstName && user.contact.firstName.length > 0
            ? <div style={{ fontSize: FontSizes.size20, marginBottom: 30, fontWeight: 600 }}>{greeting}, {user.contact.firstName} {emoji}</div>
            : <div style={{ fontSize: FontSizes.size20, marginBottom: 30, fontWeight: 600 }}>{greeting} {emoji}</div>
        }
        <div className="ms-Grid" dir="ltr">
            <div style={{marginLeft: -8, paddingRight: 16, marginBottom: 20, marginTop: -10}}>
                <DateRangePicker
                    onChange={(dateFrom, dateTo, fastSelectButtonId) => changeDates(dateFrom, dateTo, fastSelectButtonId)}
                    defaultSelectedButtonId={dates[2]}
                    excludeButtons={['ALL']}
                />
            </div>
            {canSeeContractStatistics
                ? <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4" style={{paddingLeft: 0, paddingRight: 16}}>
                        <div style={{ fontSize: FontSizes.size18, marginBottom: 10, fontWeight: 600 }}>Финансовая статистика</div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 20}}>
                            <div>
                                {canSeeContractStatisticsTeamId === null
                                    ? <DocumentCard type={DocumentCardType.compact} style={{padding: 10, display: 'block', height: 'unset'}}>
                                        <div style={{fontWeight: 600, marginBottom: 5}}>Сумма контрактов план: {new Intl.NumberFormat().format(analytic.data.yearlyPlan)} руб. за год</div>
                                        <div style={{marginBottom: 2}}>Сумма контрактов факт: <span onClick={() => navigate(`/projects?startedAtFrom=${dates[0]}&startedAtTo=${dates[1]}`)} style={{color: '#EF5121', cursor: 'pointer'}}>{new Intl.NumberFormat().format(analytic.data.currentYearProjectSum)} руб.</span></div>
                                    </DocumentCard>
                                    : <></>
                                }
                            </div>
                            <div>
                                {canSeeContractStatisticsTeamId === null
                                    ? <DocumentCard type={DocumentCardType.compact} style={{padding: 10, display: 'block', height: 'unset'}}>
                                        <div style={{fontWeight: 600, marginBottom: 5}}>Сумма поступлений: {new Intl.NumberFormat().format(analytic.data.yearlyFact)} руб.</div>
                                        <div style={{marginBottom: 2}}>Сумма списаний: <span style={{color: '#EF5121'}}>{new Intl.NumberFormat().format(analytic.data.projectsCosts)} руб.</span></div>
                                    </DocumentCard>
                                    : <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4" style={{paddingLeft: 0, paddingRight: 16}}>
                        <div style={{ fontSize: FontSizes.size18, marginBottom: 10, fontWeight: 600 }}>Проектная статистика</div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 20}}>
                            <div>
                                <DocumentCard type={DocumentCardType.compact} style={{padding: 10, display: 'block', height: 'unset'}}>
                                    {canSeeContractStatisticsTeamId === null ? <div style={{fontWeight: 600, marginBottom: 5}}>Кол-во контрактов план: 210 шт. за год</div> : <></>}
                                    <div style={{marginBottom: 10}}>Кол-во контрактов факт: <span onClick={() => navigate(`/projects?startedAtFrom=${dates[0]}&startedAtTo=${dates[1]}`)} style={{color: '#EF5121', cursor: 'pointer'}}>{analytic.data.currentYearProjectCount} шт.</span></div>
                                    <div style={{marginBottom: 5}}>Текущие: <span onClick={() => navigate(`/projects?startedAtFrom=${dates[0]}&startedAtTo=${dates[1]}&status=IN_PROGRESS`)} style={{color: '#EF5121', cursor: 'pointer'}}>{analytic.data.projectsInProgress} шт.</span></div>
                                    {canSeeContractStatisticsTeamId === null
                                        ? <div style={{marginBottom: 5}}>На подписании: <span onClick={() => navigate(`/leads?status=WIN&prepaymentStatus=WAITING_PAYMENT`)} style={{color: '#EF5121', cursor: 'pointer'}}>{analytic.data.projectsOnSigning} шт.</span></div>
                                        : <></>
                                    }
                                    <div style={{marginBottom: 2}}>Завершенные: <span onClick={() => navigate(`/projects?lastPaidAtFrom=${dates[0]}&lastPaidAtTo=${dates[1]}`)} style={{color: '#EF5121', cursor: 'pointer'}}>{analytic.data.projectsDone} шт.</span></div>
                                </DocumentCard>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4" style={{paddingLeft: 0, paddingRight: 16}}>
                        {/*<DonutChart*/}
                        {/*    height={180}*/}
                        {/*    data={{chartData: Object.entries(analytic.data.projectsCountByTypes).map((item) => {*/}
                        {/*        return { legend: item[0], data: item[1]};*/}
                        {/*    })}}*/}
                        {/*    innerRadius={25}*/}
                        {/*    legendProps={{allowFocusOnLegends: true}}*/}
                        {/*    hideLegend={false}*/}
                        {/*    hideLabels={false}*/}
                        {/*    showLabelsInPercent={false}*/}
                        {/*/>*/}
                        {canSeePageRating
                            ? <>
                                <div style={{ fontSize: FontSizes.size18, marginBottom: 10, fontWeight: 600 }}>Заявки по источникам</div>
                                {/*<div style={{ fontSize: FontSizes.size14, fontWeight: 600, marginBottom: 10 }}>Кол-во лидов по источникам</div>*/}
                                <DocumentCard type={DocumentCardType.normal} style={{height: 140, overflowY: 'auto', maxWidth: 'unset'}}>
                                    <DetailsList
                                        styles={{root: {maxWidth: 'unset'}}}
                                        items={analytic.data.leadsBySources}
                                        compact={true}
                                        columns={leadsBySourcesColumns}
                                        selectionMode={SelectionMode.none}
                                        getKey={(item, index) => item.key}
                                        setKey="none"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={false}
                                    />
                                </DocumentCard>
                            </>
                            : <></>
                        }
                    </div>
                </div>
                : <></>
            }
            <div className="ms-Grid-row" style={{marginTop: 20}}>
                {canSeeLeadStatistics
                    ? <div className="ms-Grid-col ms-sm12 ms-md7 ms-lg7" style={{paddingLeft: 0, paddingRight: 16}}>
                        <div style={{ fontSize: FontSizes.size18, fontWeight: 600, marginBottom: 10 }}>Статистика заявок</div>
                        {/*<div style={{ fontSize: FontSizes.size16, fontWeight: 600, marginBottom: 20 }}>Лиды</div>*/}
                        {/*<AreaChart*/}
                        {/*    width={700}*/}
                        {/*    height={300}*/}
                        {/*    // chartTitle="Посещения каждый день"*/}
                        {/*    data={{*/}
                        {/*        chartTitle: 'Уникальные посетители',*/}
                        {/*        lineChartData: [*/}
                        {/*            {*/}
                        {/*                legend: 'Посещения',*/}
                        {/*                data: Object.values(analytic.data.leadsCountPerEveryMonth).map((item) => { return {x: item.date, y: item.count} }),*/}
                        {/*            }*/}
                        {/*        ],*/}
                        {/*    }}*/}
                        {/*    hideLegend={true}*/}
                        {/*    // hideTooltip={true}*/}
                        {/*    // styles={customStyles}*/}
                        {/*    // barWidth={12}*/}
                        {/*    enablePerfOptimization={true}*/}
                        {/*    enableReflow={true}*/}
                        {/*    hideLabels={true}*/}
                        {/*    // height={800}*/}
                        {/*    // width={400}*/}
                        {/*    // height={350}*/}
                        {/*    // width={650}*/}
                        {/*    rotateXAxisLables={true}*/}
                        {/*    // enableReflow={true}*/}
                        {/*/>*/}
                        <VerticalBarChart
                            chartTitle="Лиды"
                            data={Object.values(analytic.data.leadsCountPerEveryMonth).map((item) => { return {x: item.date, y: item.count, color: '#ef5021'} })}
                            hideLegend={true}
                            styles={customStyles}
                            barWidth={28}
                            maxBarWidth={40}
                            hideLabels={false}
                            rotateXAxisLables={false}
                            enableReflow={true}
                        />
                    </div>
                    : <></>
                }
                <div className="ms-Grid-col ms-sm12 ms-md5 ms-lg5">
                    <div style={{ fontSize: FontSizes.size18, fontWeight: 600, marginBottom: 20 }}>Типы проектов</div>
                    <DonutChart
                        height={340}
                        data={{chartData: Object.entries(analytic.data.projectsCountByTypes).map((item, number) => {
                                return { legend: item[0], data: item[1], color: colors[number]};
                            })}}
                        innerRadius={100}
                        legendProps={{allowFocusOnLegends: true}}
                        hideLegend={false}
                        hideLabels={false}
                        showLabelsInPercent={true}
                    />
                    {/*<div style={{marginBottom: 20, border: '1px solid rgb(237, 235, 233)'}}>*/}
                    {/*    <div style={{padding: 10, display: 'block', height: 'unset'}}>*/}
                    {/*        {canSeeVisitStatistics*/}
                    {/*            ? <div style={{marginBottom: 2}}>Посетители сегодня: <span style={{color: '#EF5121'}}>{analytic.data.visitsToday}</span></div>*/}
                    {/*            : <></>*/}
                    {/*        }*/}
                    {/*        {canSeeLeadStatistics*/}
                    {/*            ? <div style={{marginBottom: 2}}>Лиды сегодня: <span style={{color: '#EF5121'}}>{analytic.data.leadsToday}</span></div>*/}
                    {/*            : <></>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*{canSeePageRating*/}
                    {/*    ? <>*/}
                    {/*        <div style={{ fontSize: FontSizes.size14, fontWeight: 600, marginBottom: 10 }}>Кол-во лидов по источникам</div>*/}
                    {/*        <DocumentCard type={DocumentCardType.normal} style={{height: 300, overflowY: 'auto', maxWidth: 'unset'}}>*/}
                    {/*            <DetailsList*/}
                    {/*                styles={{root: {maxWidth: 'unset'}}}*/}
                    {/*                items={analytic.data.leadsBySources}*/}
                    {/*                compact={true}*/}
                    {/*                columns={leadsBySourcesColumns}*/}
                    {/*                selectionMode={SelectionMode.none}*/}
                    {/*                getKey={(item, index) => item.key}*/}
                    {/*                setKey="none"*/}
                    {/*                layoutMode={DetailsListLayoutMode.justified}*/}
                    {/*                isHeaderVisible={false}*/}
                    {/*            />*/}
                    {/*        </DocumentCard>*/}
                    {/*    </>*/}
                    {/*    : <></>*/}
                    {/*}*/}
                </div>
            </div>
        </div>
        <div className="ms-Grid" dir="ltr">
            {/*<div className="ms-Grid-row">*/}
            {/*    {canSeeVisitStatistics*/}
            {/*        ? <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">*/}
            {/*            <DocumentCard type={DocumentCardType.compact}>*/}
            {/*                <div style={{width: 104, backgroundColor: 'rgb(250, 249, 248)', fontSize: fonts.superLarge.fontSize, paddingLeft: 16}}>*/}
            {/*                    {analytic.data.visitsToday}*/}
            {/*                </div>*/}
            {/*                <DocumentCardDetails>*/}
            {/*                    <DocumentCardTitle title="Посетителей сегодня" shouldTruncate />*/}
            {/*                </DocumentCardDetails>*/}
            {/*            </DocumentCard>*/}
            {/*        </div>*/}
            {/*        : <></>*/}
            {/*    }*/}
            {/*    {canSeeLeadStatistics*/}
            {/*        ? <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">*/}
            {/*            <DocumentCard type={DocumentCardType.compact}>*/}
            {/*                <div style={{width: 104, backgroundColor: 'rgb(250, 249, 248)', fontSize: fonts.superLarge.fontSize, paddingLeft: 16}}>*/}
            {/*                    {analytic.data.leadsToday}*/}
            {/*                </div>*/}
            {/*                <DocumentCardDetails>*/}
            {/*                    <DocumentCardTitle title="Лиды сегодня" shouldTruncate />*/}
            {/*                </DocumentCardDetails>*/}
            {/*            </DocumentCard>*/}
            {/*        </div>*/}
            {/*        : <></>*/}
            {/*    }*/}
            {/*    {canSeeLeadStatistics*/}
            {/*        ? <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">*/}
            {/*            <DocumentCard type={DocumentCardType.compact}>*/}
            {/*                <div style={{width: 104, backgroundColor: 'rgb(250, 249, 248)', fontSize: fonts.superLarge.fontSize, paddingLeft: 16}}>*/}
            {/*                    {analytic.data.leadsIn30Days}*/}
            {/*                </div>*/}
            {/*                <DocumentCardDetails>*/}
            {/*                    <DocumentCardTitle title="Лиды за последние 30 дней" shouldTruncate />*/}
            {/*                </DocumentCardDetails>*/}
            {/*            </DocumentCard>*/}
            {/*        </div>*/}
            {/*        : <></>*/}
            {/*    }*/}
            {/*</div>*/}
            {/*<div className="ms-Grid-row" style={{marginTop: 20}}>*/}
            {/*    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">*/}
            {/*        <DocumentCard type={DocumentCardType.compact}>*/}
            {/*            <div style={{width: 104, backgroundColor: 'rgb(250, 249, 248)', fontSize: fonts.medium.fontSize, paddingLeft: 5, paddingTop: 16}}>*/}
            {/*                {new Intl.NumberFormat().format(analytic.data.currentYearProjectSum)} руб.*/}
            {/*            </div>*/}
            {/*            <DocumentCardDetails>*/}
            {/*                <DocumentCardTitle title="Сумма контрактов" shouldTruncate />*/}
            {/*            </DocumentCardDetails>*/}
            {/*        </DocumentCard>*/}
            {/*    </div>*/}
            {/*    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">*/}
            {/*        <DocumentCard type={DocumentCardType.compact}>*/}
            {/*            <div style={{width: 104, backgroundColor: 'rgb(250, 249, 248)', fontSize: fonts.superLarge.fontSize, paddingLeft: 16}}>*/}
            {/*                {analytic.data.currentYearProjectCount}*/}
            {/*            </div>*/}
            {/*            <DocumentCardDetails>*/}
            {/*                <DocumentCardTitle title="Кол-во контрактов за год" shouldTruncate />*/}
            {/*            </DocumentCardDetails>*/}
            {/*        </DocumentCard>*/}
            {/*    </div>*/}
            {/*    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*{canSeeLeadStatistics || canSeePageRating*/}
            {/*    ? <div style={{ fontSize: FontSizes.size20, marginTop: 40, marginBottom: 20 }}>Статистика за 30 дней</div>*/}
            {/*    : <></>*/}
            {/*}*/}
            <div className="ms-Grid-row">
                {/*{canSeeLeadStatistics*/}
                {/*    ? <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">*/}
                {/*        <div style={{ fontSize: FontSizes.size16, fontWeight: 600, marginBottom: 20 }}>Лиды</div>*/}
                {/*        <VerticalBarChart*/}
                {/*            chartTitle="Посещения каждый день"*/}
                {/*            data={Object.values(analytic.data.leadsCountPerEveryDay).map((item) => { return {x: item.date, y: item.count, color: '#ef5021'} })}*/}
                {/*            hideLegend={true}*/}
                {/*            // hideTooltip={true}*/}
                {/*            styles={customStyles}*/}
                {/*            barWidth={12}*/}
                {/*            hideLabels={true}*/}
                {/*            // height={800}*/}
                {/*            // width={400}*/}
                {/*            // height={350}*/}
                {/*            // width={650}*/}
                {/*            rotateXAxisLables={true}*/}
                {/*            // enableReflow={true}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    : <></>*/}
                {/*}*/}
                {/*{canSeePageRating*/}
                {/*    ? <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6" style={canSeeLeadStatistics ? {paddingLeft: 40} : {}}>*/}
                {/*        <div style={{ fontSize: FontSizes.size16, fontWeight: 600, marginBottom: 20 }}>Рейтинг страниц по кол-ву лидов</div>*/}
                {/*        <DocumentCard type={DocumentCardType.normal} style={{height: 300, overflowY: 'auto'}}>*/}
                {/*            <DetailsList*/}
                {/*                items={analytic.data.leadsBySources}*/}
                {/*                compact={true}*/}
                {/*                columns={leadsBySourcesColumns}*/}
                {/*                selectionMode={SelectionMode.none}*/}
                {/*                getKey={(item, index) => item.key}*/}
                {/*                setKey="none"*/}
                {/*                layoutMode={DetailsListLayoutMode.justified}*/}
                {/*                isHeaderVisible={false}*/}
                {/*            />*/}
                {/*        </DocumentCard>*/}
                {/*    </div>*/}
                {/*    : <></>*/}
                {/*}*/}
            </div>
        </div>
        {/*<div style={{display: 'flex', justifyContent: 'space-between'}}>*/}
        {/*    <div style={{boxShadow: DefaultEffects.elevation8, padding: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20}}>*/}
        {/*        <div style={{ fontSize: FontSizes.size14 }}>Посетителей сегодня</div>*/}
        {/*        <div style={{ fontSize: FontSizes.size14, fontWeight: 'bold' }}>{analytic.data.visitsToday}</div>*/}
        {/*    </div>*/}
        {/*    <div style={{boxShadow: DefaultEffects.elevation8, padding: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20}}>*/}
        {/*        <div style={{ fontSize: FontSizes.size14 }}>Лиды сегодня</div>*/}
        {/*        <div style={{ fontSize: FontSizes.size14, fontWeight: 'bold' }}>{analytic.data.leadsToday}</div>*/}
        {/*    </div>*/}
        {/*    <div style={{boxShadow: DefaultEffects.elevation8, padding: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20}}>*/}
        {/*        <div style={{ fontSize: FontSizes.size14 }}>Лиды за последние 30 дней</div>*/}
        {/*        <div style={{ fontSize: FontSizes.size14, fontWeight: 'bold' }}>{analytic.data.leadsIn30Days}</div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>)
};
