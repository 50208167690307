import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function getAnalyticMethod(fromDate, toDate) {
    const searchParams = new URLSearchParams({fromDate: fromDate ?? '', toDate: toDate ?? ''});
    const url = new URL(serverUrl + '/api/v2/pc/analytic?' + searchParams);

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
