import * as React from "react";
import {Nav} from "@fluentui/react/lib/Nav";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import getMenuMethod from "../../api/getMenuMethod";

export default function Menu() {
    const navigate = useNavigate();
    const [menuLinks, setMenuLinks] = useState([]);

    useEffect(() => {
        if (menuLinks.length === 0) {
            getMenuMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setMenuLinks(response);
            });
        }
    });

    const styles = {
        root: {
            width: 208,
            boxSizing: 'border-box',
            // border: '1px solid #eee',
            overflowY: 'auto',
        },
    };

    const links = [
        {
            links: menuLinks, /*[
                {
                    name: 'Главная',
                    // url: '/',
                    icon: 'AnalyticsLogo', //'HomeSolid',
                    key: 'main',
                },
                {
                    name: 'Лиды',
                    // url: '/leads',
                    icon: 'ContactCard',
                    key: 'leads',
                },
                {
                    name: 'Проекты',
                    // url: '/projects',
                    icon: 'FabricOpenFolderHorizontal',
                    key: 'projects',
                },
                {
                    name: 'Платежи',
                    // url: '/projects',
                    icon: 'PaymentCard',
                    key: 'payments',
                },
                {
                    name: 'Команды',
                    // url: '/teams',
                    icon: 'TeamFavorite',
                    key: 'teams',
                },
                {
                    name: 'Фрилансеры',
                    // url: '/freelancers',
                    icon: 'PeopleRepeat',
                    key: 'freelancers',
                },
                {
                    name: 'Сотрудники',
                    // url: '/employees',
                    icon: 'People',
                    key: 'employees',
                },
                {
                    name: 'DOCMASTER',
                    // url: '/docmaster',
                    icon: 'WordDocument',
                    key: 'docmaster',
                },
                // {
                //     name: 'Главная',
                //     url: '/',
                //     // icon: 'HomeSolid',
                //     key: 'main',
                // },
                // {
                //     name: 'Лиды',
                //     url: '/leads',
                //     // icon: 'ContactCard',
                //     key: 'leads',
                // },
                // {
                //     name: 'Проекты',
                //     url: '/',
                //     icon: 'FabricOpenFolderHorizontal',
                //     key: 'projects',
                // },
                // {
                //     name: 'Команды',
                //     url: '/',
                //     icon: 'TeamsLogo',
                //     key: 'teams',
                // },
                // {
                //     name: 'Пользователи',
                //     url: '/',
                //     icon: 'People',
                //     key: 'users',
                // },
            ],*/
        },
    ];

    const onMenuClick = (e, item) => {
        if (!item) {
            return;
        }

        switch (item.key) {
            case 'main':
                navigate('/');
                break;
            case 'leads':
                navigate('/leads');
                break;
            case 'projects':
                navigate('/projects');
                break;
            case 'finance':
                navigate('/finance');
                break;
            case 'makeDocument':
                navigate('/waiting-docs-projects');
                break;
            // case 'payments':
            //     navigate('/payments');
            //     break;
            case 'teams':
                navigate('/teams');
                break;
            case 'pcUsers':
                navigate('/pc-users');
                break;
            case 'chats':
                navigate('/chats');
                break;
            case 'employees':
                navigate('/employees');
                break;
            case 'freelancers':
                navigate('/freelancers');
                break;
            case 'docmaster':
                navigate('/docmaster');
                break;
            default:
                return;
        }
    };

    let selectedKey = null;

    if (window.location.pathname === '/') {
        selectedKey = 'main';
    }

    if (window.location.pathname.includes('/leads')) {
        selectedKey = 'leads';
    }

    if (window.location.pathname.includes('/projects')) {
        selectedKey = 'projects';
    }

    if (window.location.pathname.includes('/finance')) {
        selectedKey = 'finance';
    }

    if (window.location.pathname.includes('/waiting-docs-projects')) {
        selectedKey = 'makeDocument';
    }

    if (window.location.pathname.includes('/pc-users')) {
        selectedKey = 'pcUsers';
    }

    if (window.location.pathname.includes('/payments')) {
        selectedKey = 'payments';
    }

    // if (window.location.pathname.includes('/employees')) {
    //     selectedKey = 'employees';
    // }

    if (window.location.pathname.includes('/chats')) {
        selectedKey = 'chats';
    }

    if (window.location.pathname.includes('/freelancers')) {
        selectedKey = 'freelancers';
    }

    if (window.location.pathname.includes('/teams')) {
        selectedKey = 'teams';
    }

    if (window.location.pathname.includes('/docmaster')) {
        selectedKey = 'docmaster';
    }

    return (
        <div>
            <Nav
                onLinkClick={onMenuClick}
                selectedKey={selectedKey}
                ariaLabel="Меню"
                styles={styles}
                groups={links}
            />
        </div>
    );
};
