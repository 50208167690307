import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import getDocmasterTypesMethod from "../../api/getDocmasterTypesMethod";
import {
    Breadcrumb, DatePicker,
    DayOfWeek, DefaultButton,
    DetailsList,
    DetailsListLayoutMode, PrimaryButton,
    SelectionMode,
    Stack,
    TextField
} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import getDocmasterDocumentMethod from "../../api/getDocmasterDocumentMethod";
import Notification from "../notifications/Notification";
import addProjectCommentMethod from "../../api/addProjectCommentMethod";
import fillDocmasterDocument from "../../api/FillDocmasterDocument";
import {datePickerStringsRu} from "../data/datePickerStringsRu";
import addDocmasterDocument from "../../api/addDocmasterDocument";
import generateDocmasterDocumentMethod from "../../api/generateDocmasterDocumentMethod";
import removeDocmasterDocumentMethod from "../../api/removeDocmasterDocumentMethod";

export default function Document() {
    const navigate = useNavigate();
    const params = useParams();
    const [notification, setNotification] = useState({type: 'hidden', text: ''});
    const [document, setDocument] = useState({loaded: false, data: {id: '', data: {}, typeName: '', typeValue: '', fileId: null, createdAt: ''}});

    useEffect(() => {
        if (!document.loaded) {
            getDocmasterDocumentMethod(params.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setDocument({loaded: true, data: response});
            });
        }
    });

    const changeDocumentData = (key, value) => {
        const data = {...document.data.data, [key]: {...document.data.data[key], value: value}};
        setDocument({...document, data: {...document.data, data: data}});

        fillDocmasterDocument(params.id, data).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные сохранены'});
        });
    };

    const breadcrumbs = [
        { text: 'DOCMASTER', key: 'docmaster', href: '/docmaster' },
        { text: document.data.typeValue, key: 'documents', href: '/docmaster/' + document.data.typeName },
        { text: 'Документ от ' + document.data.createdAt, key: 'document', href: '/docmaster/documents/' + params.id, isCurrentItem: true },
    ];

    const groups = {};

    Object.values(document.data.data).forEach((item) => {
        if (groups[item.group]) {
            groups[item.group].push(item);
            return;
        }

        groups[item.group] = [];
        groups[item.group].push(item);
    });

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    }

    const generateWord = async () => {
        generateDocmasterDocumentMethod(params.id).then((response) => {
            setDocument({...document, data: {...document.data, fileId: response.fileId}});
        });
    };

    const removeDocument = async () => {
        removeDocmasterDocumentMethod(params.id).then((response) => {
            navigate('/docmaster/' + document.data.typeName);
        });
    };

    return <>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 20}}
        />
        <Notification type={notification.type} text={notification.text} />
        {document.data.fileId === null
            ? <div>
                <PrimaryButton style={{marginTop: 10, marginBottom: 10}} text="Сгенерировать WORD" onClick={generateWord} />
                <div>После генерации файл нельзя будет изменить, только удалить и создать новый</div>
            </div>
            : <div style={{marginTop: 10}}>
                <PrimaryButton text="Открыть файл" onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + document.data.fileId)} />
                <DefaultButton style={{marginLeft: 10}} text="Удалить" onClick={removeDocument} />
            </div>
        }
        <Stack style={{marginTop: 20}} tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
        {Object.values(groups).map((group) => {
            return <div>
                <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 10, fontWeight: 'bold' }}>{group.at(0).group}</div>
                {group.map((item) => {
                    if (item.type === 'text') {
                        if (item.prefix) {
                            return <div style={{marginBottom: 5}}>
                                <TextField disabled={document.data.fileId !== null} prefix={item.prefix} label={item.name} value={document.data.data[item.key].value} onChange={(e) => changeDocumentData(item.key, e.target.value)} />
                            </div>;
                        }

                        if (item.postfix) {
                            return <div style={{marginBottom: 5}}>
                                <TextField disabled={document.data.fileId !== null} suffix={item.postfix} label={item.name} value={document.data.data[item.key].value} onChange={(e) => changeDocumentData(item.key, e.target.value)} />
                            </div>;
                        }

                        return <div style={{marginBottom: 5}}>
                            <TextField disabled={document.data.fileId !== null} label={item.name} value={document.data.data[item.key].value} onChange={(e) => changeDocumentData(item.key, e.target.value)} />
                        </div>;
                    }

                    if (item.type === 'textarea') {
                        return <div style={{marginBottom: 5}}>
                            <TextField disabled={document.data.fileId !== null} multiline={true} rows={5} label={item.name} value={document.data.data[item.key].value} onChange={(e) => changeDocumentData(item.key, e.target.value)} />
                        </div>;
                    }

                    if (item.type === 'date') {
                        return <DatePicker
                            disabled={document.data.fileId !== null}
                            firstDayOfWeek={DayOfWeek.Monday}
                            showWeekNumbers={false}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Выберите дату"
                            ariaLabel="Выберите дату"
                            label={item.name}
                            strings={datePickerStringsRu}
                            formatDate={formatDate}
                            value={document.data.data[item.key].value ? new Date(document.data.data[item.key].value) : null}
                            onSelectDate={(date) => changeDocumentData(item.key, date.toDateString())}
                        />;
                    }

                    if (item.type === 'number') {
                        if (item.prefix) {
                            return <div style={{marginBottom: 5}}>
                                <TextField disabled={document.data.fileId !== null} type="number" prefix={item.prefix} label={item.name} value={document.data.data[item.key].value} onChange={(e) => changeDocumentData(item.key, e.target.value)} />
                            </div>;
                        }

                        if (item.postfix) {
                            return <div style={{marginBottom: 5}}>
                                <TextField disabled={document.data.fileId !== null} type="number" suffix={item.postfix} label={item.name} value={document.data.data[item.key].value} onChange={(e) => changeDocumentData(item.key, e.target.value)} />
                            </div>;
                        }

                        return <div style={{marginBottom: 5}}>
                            <TextField disabled={document.data.fileId !== null} type="number" label={item.name} value={document.data.data[item.key].value} onChange={(e) => changeDocumentData(item.key, e.target.value)} />
                        </div>;
                    }

                    return <></>;
                })}
            </div>;
        })}
        </Stack>
    </>
};
