import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    ActionButton,
    Breadcrumb, Callout, DetailsHeader,
    DetailsList,
    DetailsListLayoutMode, Pivot, PivotItem, Popup, PrimaryButton, Separator, Spinner, SpinnerSize,
    Stack, TextField, Toggle,
    TooltipHost
} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import getSingleUserMethod from "../../../api/getSingleUserMethod";
import changeUserContactMethod from "../../../api/changeUserContactMethod";
import changeUserProfileMethod from "../../../api/changeUserProfileMethod";
import { SelectionMode } from '@fluentui/react/lib/Selection';
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import Notification from "../../notifications/Notification";
import getTeamCollectionMethod from "../../../api/getTeamCollectionMethod";
import { DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import changeUserPermissionsMethod from "../../../api/changeUserPermissionsMethod";
import {Persona, PersonaSize} from "@fluentui/react/lib/Persona";
import regenerateAndSendPasswordMethod from "../../../api/regenerateAndSendPasswordMethod";
import inviteUserMethod from "../../../api/inviteUserMethod";
import getSpecializationsMethod from "../../../api/getSpecializationsMethod";
import getSingleTeamMethod from "../../../api/getSingleTeamMethod";
import ChangePermissions from "../../User/Shared/ChangePermissions";
import getCurrentUserMethod from "../../../api/getCurrentUserMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";
import deactivateEmployeeMethod from "../../../api/deactivateEmployeeMethod";
import {datePickerStringsRu} from "../../data/datePickerStringsRu";

const initialUser = {
    contact: {
        firstName: '',
        lastName: '',
        email: null,
        phoneNumber: null,
        telegramUsername: null
    },
    profile: {
        avatarId: null,
        specialization: null,
        city: null,
        website: null,
        about: null
    },
    status: null,
    permissions: [],
    hasPassword: false,
    passwordResetAt: null,
    isEmailVerified: false,
    createdAt: null,
};

const initialTeam = {
    name: null,
    about: null,
    members: [
        {
            userId: null,
            type: null,
            contact: {
                firstName: null,
                lastName: null,
                email: null,
                phoneNumber: null,
                telegramUsername: null
            },
            profile: {
                specialization: null,
                city: null,
                about: null
            }
        }
    ],
    services: [],
};

export default function ViewTeamMember() {
    const params = useParams();
    const [team, setTeam] = useState({loaded: false, data: initialTeam});
    const [user, setUser] = useState({loaded: false, data: initialUser});
    const [notification, setNotification] = useState({type: 'hidden', text: ''});
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const [me, setMe] = useState({id: null, contact:{firstName:'',lastName:'',email:''},security:{permissions:[]}});
    const navigate = useNavigate();

    useEffect(() => {
        if (me.contact.email.length === 0 && me.contact.firstName.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setMe(response);
            });
        }
    });

    let canChangeTeamMember = false;
    me.security.permissions.forEach((permission) => {
        if (permission.key === 'change_team' && (permission.type === 'ALL' || permission.type === 'TEAM')) {
            canChangeTeamMember = true;
        }
    });

    useEffect(() => {
        if (!user.loaded) {
            getSingleUserMethod(params.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    return;
                }

                setUser({loaded: true, data: response});
            });

            getSingleTeamMethod(params.teamId).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setTeam({loaded: true, data: response});
            });

            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });
        }
    });

    let teamId = null;
    me.security.permissions.forEach((permission) => {
        if (teamId === null && permission.key === 'see_teams' && permission.type === 'TEAM') {
            teamId = permission.teamId;
        }
    });

    if (me.contact.email.length === 0 && me.contact.firstName.length === 0) {
        return <Spinner size={SpinnerSize.large} />;
    }

    const breadcrumbs = teamId === null
        ? [
            { text: 'Команды', key: 'teams', onClick: () => navigate('/teams') },
            { text: team.data.name, key: 'team', onClick: () => navigate('/teams/' + params.teamId) },
            { text: user.data.contact.firstName + ' ' + (user.data.contact.lastName ?? ''), key: 'teamMember', href: '/teams/' + params.teamId + '/' + params.id, isCurrentItem: true },
        ]
        : [
            { text: 'Моя команда', key: 'team', onClick: () => navigate('/teams/' + params.teamId) },
            { text: user.data.contact.firstName + ' ' + (user.data.contact.lastName ?? ''), key: 'teamMember', href: '/teams/' + params.teamId + '/' + params.id, isCurrentItem: true },
        ]
    ;

    const reloadData = () => setUser({loaded: false, data: {...user.data}});

    const changeContact = (key, value) => {
        const contact = {...user.data.contact, [key]: value};
        setUser({...user, data: {...user.data, contact: contact}});

        changeUserContactMethod(params.id, contact).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные контакта сохранены'});
        });
    };

    const changeProfile = (key, value) => {
        const profile = {...user.data.profile, [key]: value};
        setUser({...user, data: {...user.data, profile: profile}});

        changeUserProfileMethod(params.id, profile).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные профиля сохранены'});
        });
    };

    const selectedSpecializations = specializations.data.filter((item) => item.key === user.data.profile.specialization);

    const regenerateAndSendPassword = () => {
        regenerateAndSendPasswordMethod(params.id).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            reloadData();
            setNotification({type: 'success', text: 'Пароль сгенерирован и выслан'});
        });
    };

    const inviteUser = () => {
        inviteUserMethod(params.id).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            reloadData();
            setNotification({type: 'success', text: 'Пользователь приглашен в систему'});
        });
    };

    const username = user.data.contact.firstName + ' ' + (user.data.contact.lastName ?? '');
    const userspecialization = selectedSpecializations.length > 0 ? selectedSpecializations[0].text : null;

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year} года`;
    }

    return (
        <div>
            <Breadcrumb
                items={breadcrumbs}
                maxDisplayedItems={10}
                ariaLabel="Навигация"
                overflowAriaLabel="Показать больше"
                style={{marginBottom: 20}}
            />
            <Notification type={notification.type} text={notification.text} />
            <div style={{marginBottom: 15, marginTop: 30}}>
                <div style={{ marginBottom: 15, marginTop: 30, fontSize: FontSizes.size24 }}>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        {user.data.profile.avatarId !== null
                            ? <Persona imageUrl={process.env.REACT_APP_API_URL + '/files/' + user.data.profile.avatarId} text={username} secondaryText={userspecialization} size={PersonaSize.size56} />
                            : <Persona text={username} secondaryText={userspecialization} size={PersonaSize.size56} />
                        }
                    </Stack>
                </div>
            </div>
            {teamId !== null
                ? <Pivot aria-label="Меню пользователя">
                    <PivotItem headerText="Профиль">
                        <div style={{marginTop: 20}}>
                            <Stack horizontal tokens={{ childrenGap: 50 }}>
                                <div>
                                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                        <TextField label="Имя" value={user.data.contact.firstName} onChange={(e) => changeContact('firstName', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Фамилия" value={user.data.contact.lastName} onChange={(e) => changeContact('lastName', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Телефон" value={user.data.contact.phoneNumber} onChange={(e) => changeContact('phoneNumber', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Email" value={user.data.contact.email} onChange={(e) => changeContact('email', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Telegram username (без @)" value={user.data.contact.telegramUsername} onChange={(e) => changeContact('telegramUsername', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                    </Stack>
                                </div>
                                <div>
                                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                        <Dropdown
                                            placeholder="Выберите"
                                            label="Специализация"
                                            options={specializations.data}
                                            style={{marginBottom: 10}}
                                            selectedKey={user.data.profile.specialization}
                                            onChange={(e, option) => changeProfile('specialization', option.key)}
                                            disabled={!canChangeTeamMember}
                                            styles={{ title: {color: 'black'}}}
                                        />
                                        <TextField label="Город" value={user.data.profile.city} onChange={(e) => changeProfile('city', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Сайт" value={user.data.profile.website} onChange={(e) => changeProfile('website', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="О себе" multiline rows={5} value={user.data.profile.about} onChange={(e) => changeProfile('about', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                    </Stack>
                                </div>
                            </Stack>
                        </div>
                    </PivotItem>
                    {(me.id !== null && user.data.id !== null) && (me.id !== user.data.id) && canChangeTeamMember
                        ? <PivotItem headerText="Доступы">
                            <div style={{marginTop: 20}}>
                                <ChangePermissions user={user} setUser={setUser} setNotification={setNotification} />
                            </div>
                        </PivotItem>
                        : <></>
                    }
                    {!canChangeTeamMember
                        ? <></>
                        : <PivotItem headerText="Вход в систему">
                            <div style={{marginTop: 20}}>
                                {!user.data.hasPassword
                                    ? <div style={{marginTop: 20}}>
                                        <div style={{ marginTop: 15 }}>
                                            <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> новый пользователь без доступа к системе</div>
                                        </div>
                                        <Separator />
                                        <div>
                                            <div style={{ fontSize: FontSizes.size14, marginBottom: 15, fontWeight: 600 }}>Выслать приглашение на email с паролем?</div>
                                            <PrimaryButton text="Выслать" iconProps={{ iconName: 'Mail' }} onClick={inviteUser} />
                                        </div>
                                    </div>
                                    : <div style={{marginTop: 20}}>
                                        <div style={{ marginTop: 15 }}>
                                            <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> пароль обновлён <span style={{ fontWeight: 600 }}>{formatDate(new Date(user.data.passwordResetAt ? user.data.passwordResetAt : user.data.createdAt))}</span></div>
                                        </div>
                                        <Separator />
                                        <div style={{marginBottom: 90}}>
                                            <div style={{ fontSize: FontSizes.size14, marginBottom: 15, fontWeight: 600 }}>Сгенерировать новый пароль и отправить на email?</div>
                                            <DefaultButton text="Сгенерировать и отправить" iconProps={{ iconName: 'PasswordField' }} onClick={regenerateAndSendPassword} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </PivotItem>
                    }
                </Pivot>
                : <Pivot aria-label="Меню пользователя">
                    <PivotItem headerText="Данные контакта">
                        <div style={{marginTop: 20}}>
                            <Stack horizontal tokens={{ childrenGap: 50 }}>
                                <div>
                                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                        <TextField label="Имя" value={user.data.contact.firstName} onChange={(e) => changeContact('firstName', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Фамилия" value={user.data.contact.lastName} onChange={(e) => changeContact('lastName', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Телефон" value={user.data.contact.phoneNumber} onChange={(e) => changeContact('phoneNumber', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Email" value={user.data.contact.email} onChange={(e) => changeContact('email', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Telegram username (без @)" value={user.data.contact.telegramUsername} onChange={(e) => changeContact('telegramUsername', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                    </Stack>
                                </div>
                            </Stack>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Профиль">
                        <div style={{marginTop: 20}}>
                            <Stack horizontal tokens={{ childrenGap: 50 }}>
                                <div>
                                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                        <Dropdown
                                            placeholder="Выберите"
                                            label="Специализация"
                                            options={specializations.data}
                                            style={{marginBottom: 10}}
                                            selectedKey={user.data.profile.specialization}
                                            onChange={(e, option) => changeProfile('specialization', option.key)}
                                            disabled={!canChangeTeamMember}
                                            styles={{ title: {color: 'black'}}}
                                        />
                                        <TextField label="Город" value={user.data.profile.city} onChange={(e) => changeProfile('city', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="Сайт" value={user.data.profile.website} onChange={(e) => changeProfile('website', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                        <TextField label="О себе" multiline rows={5} value={user.data.profile.about} onChange={(e) => changeProfile('about', e.target.value)} style={{color: 'black'}} disabled={!canChangeTeamMember} />
                                    </Stack>
                                </div>
                            </Stack>
                        </div>
                    </PivotItem>
                    {!canChangeTeamMember
                        ? <></>
                        : <PivotItem headerText="Доступы">
                            <div style={{marginTop: 20}}>
                                <ChangePermissions user={user} setUser={setUser} setNotification={setNotification} />
                            </div>
                        </PivotItem>
                    }
                    {!canChangeTeamMember
                        ? <></>
                        : <PivotItem headerText="Вход в систему">
                            <div style={{marginTop: 20}}>
                                {!user.data.hasPassword
                                    ? <div style={{marginTop: 20}}>
                                        <div style={{ marginTop: 15 }}>
                                            <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> новый пользователь без доступа к системе</div>
                                        </div>
                                        <Separator />
                                        <div>
                                            <div style={{ fontSize: FontSizes.size14, marginBottom: 15, fontWeight: 600 }}>Выслать приглашение на email с паролем?</div>
                                            <PrimaryButton text="Выслать" iconProps={{ iconName: 'Mail' }} onClick={inviteUser} />
                                        </div>
                                    </div>
                                    : <div style={{marginTop: 20}}>
                                        <div style={{ marginTop: 15 }}>
                                            <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> пароль обновлён <span style={{ fontWeight: 600 }}>{formatDate(new Date(user.data.passwordResetAt ? user.data.passwordResetAt : user.data.createdAt))}</span></div>
                                        </div>
                                        <Separator />
                                        <div>
                                            <div style={{ fontSize: FontSizes.size14, marginBottom: 15, fontWeight: 600 }}>Сгенерировать новый пароль и отправить на email?</div>
                                            <DefaultButton text="Сгенерировать и отправить" iconProps={{ iconName: 'PasswordField' }} onClick={regenerateAndSendPassword} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </PivotItem>
                    }
                </Pivot>
            }
        </div>
    );
};
