import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import RequestCollection from "./Request/RequestCollection";
import Template from "./Theme/Template";
import ViewRequest from "./Request/ViewRequest/ViewRequest";
import MainPage from "./MainPage";
import AddRequest from "./Request/AddRequest";
import TeamCollection from "./Team/TeamCollection";
import ProjectCollection from "./Project/ProjectCollection";
import AddTeam from "./Team/AddTeam";
import ViewTeam from "./Team/ViewTeam/ViewTeam";
import ViewProject from "./Project/ViewProject/ViewProject";
import AddEmployee from "./User/Employee/AddEmployee";
import EmployeeCollection from "./User/Employee/EmployeeCollection";
import ViewEmployee from "./User/Employee/ViewEmployee";
import FreelancerCollection from "./User/Freelancer/FreelancerCollection";
import ViewFreelancer from "./User/Freelancer/ViewFreelancer";
import AddFreelancer from "./User/Freelancer/AddFreelancer";
import ViewTeamMember from "./Team/ViewTeam/ViewTeamMember";
import AddProject from "./Project/AddProject";
import TypeCollection from "./Docmaster/TypeCollection";
import DocumentCollection from "./Docmaster/DocumentCollection";
import Document from "./Docmaster/Document";
import PaymentCollection from "./Payment/PaymentCollection";
import MyProfile from "./User/MyProfile";
import PaymentView from "./Payment/PaymentView";
import FinanceCollection from "./Finance/FinanceCollection";
import ViewFinance from "./Finance/ViewFinance";
import PCUserCollection from "./User/PCUser/PCUserCollection";
import ViewPCUser from "./User/PCUser/ViewPCUser";
import MakeDocumentCollection from "./MakeDocument/MakeDocumentCollection";
import ViewMakeDocument from "./MakeDocument/ViewMakeDocument";
import ViewPaymentSteps from "./Finance/ViewPaymentSteps";
import ChatCollection from "./Chat/ChatCollection";
import Chat from "./Chat/Chat";
import ViewWaitingRealCostsProject from "./Finance/ViewWaitingRealCostsProject";
import ViewOnlinePayment from "./Finance/ViewOnlinePayment";

export const routes = {
    login: {
        path: "/login",
        component: <Login />
    },
    mainPage: {
        path: "/",
        component:  <Template component={<MainPage />}/>
    },
    requestCollection: {
        path: "/leads",
        component: <Template component={<RequestCollection />}/>
    },
    viewRequest: {
        path: "/leads/:id",
        component:  <Template component={<ViewRequest />}/>
    },
    addRequest: {
        path: "/leads/new",
        component:  <Template component={<AddRequest />}/>
    },
    // paymentCollection: {
    //     path: "/payments",
    //     component: <Template component={<PaymentCollection />}/>
    // },
    viewPayment: {
        path: "/finance/payments/:id/:onlinePaymentId",
        component: <Template component={<ViewOnlinePayment />}/>
    },
    financeCollection: {
        path: "/finance",
        component: <Template component={<FinanceCollection />}/>
    },
    viewFinance: {
        path: "/finance/:id",
        component:  <Template component={<ViewFinance />}/>
    },
    viewPaymentSteps: {
        path: "/finance/:id/only-payment-steps",
        component:  <Template component={<ViewPaymentSteps />}/>
    },
    viewWaitingRealCostsProject: {
        path: "/finance/:id/closing",
        component:  <Template component={<ViewWaitingRealCostsProject />}/>
    },
    projectCollection: {
        path: "/projects",
        component: <Template component={<ProjectCollection />}/>
    },
    viewProject: {
        path: "/projects/:id",
        component:  <Template component={<ViewProject />}/>
    },
    addProject: {
        path: "/projects/new",
        component:  <Template component={<AddProject />}/>
    },
    archivedProject: {
        path: "/projects/archived",
        component:  <Template component={<ProjectCollection />}/>
    },
    waitingDocumentsProjectCollection: {
        path: "/waiting-docs-projects",
        component: <Template component={<MakeDocumentCollection />}/>
    },
    waitingDocumentsProjectViewProject: {
        path: "/waiting-docs-projects/:id",
        component:  <Template component={<ViewMakeDocument />}/>
    },
    freelancerCollection: {
        path: "/freelancers",
        component: <Template component={<FreelancerCollection />}/>
    },
    viewFreelancer: {
        path: "/freelancers/:id",
        component:  <Template component={<ViewFreelancer />}/>
    },
    addFreelancer: {
        path: "/freelancers/new",
        component:  <Template component={<AddFreelancer />}/>
    },
    employeeCollection: {
        path: "/employees",
        component: <Template component={<EmployeeCollection />}/>
    },
    viewEmployee: {
        path: "/employees/:id",
        component:  <Template component={<ViewEmployee />}/>
    },
    chatCollection: {
        path: "/chats",
        component: <Template component={<ChatCollection />}/>
    },
    viewChat: {
        path: "/chats/:id",
        component: <Template component={<Chat />}/>
    },
    PCUserCollection: {
        path: "/pc-users",
        component: <Template component={<PCUserCollection />}/>
    },
    viewPCUser: {
        path: "/pc-users/:id",
        component:  <Template component={<ViewPCUser />}/>
    },
    addEmployee: {
        path: "/employees/new",
        component:  <Template component={<AddEmployee />}/>
    },
    teamCollection: {
        path: "/teams",
        component: <Template component={<TeamCollection />}/>
    },
    viewTeam: {
        path: "/teams/:id",
        component:  <Template component={<ViewTeam />}/>
    },
    viewTeamMember: {
        path: "/teams/:teamId/:id",
        component:  <Template component={<ViewTeamMember />}/>
    },
    addTeam: {
        path: "/teams/new",
        component:  <Template component={<AddTeam />}/>
    },
    myProfile: {
        path: "/my-profile",
        component:  <Template component={<MyProfile />}/>
    },
    docmaster: {
        path: "/docmaster",
        component:  <Template component={<TypeCollection />}/>
    },
    docmasterDocuments: {
        path: "/docmaster/:type",
        component:  <Template component={<DocumentCollection />}/>
    },
    docmasterDocument: {
        path: "/docmaster/documents/:id",
        component:  <Template component={<Document />}/>
    },
}

export default function AppRouter() {
    const routesList = Object.keys(routes);
    return (
        <Router>
            <Routes>
                {routesList.map(route => {
                    return <Route key={route} path={routes[route].path} element={routes[route].component} />
                })}
            </Routes>
        </Router>
    );
}
