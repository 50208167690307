export const datePickerStringsRu = {
    months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthDeclinations: ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"],
    shortMonths: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    shortDays: ["ВС", "ПН", "ВТ", "СР", "ЧT", "ПТ", "СБ"],
    goToToday: "Перейти к сегодня",
    weekNumberFormatString: "Номер недели {0}",
    prevMonthAriaLabel: "Go to previous month",
    nextMonthAriaLabel: "Go to next month",
    prevYearAriaLabel: "Go to previous year",
    nextYearAriaLabel: "Go to next year",
    prevYearRangeAriaLabel: "Previous year range",
    nextYearRangeAriaLabel: "Next year range",
    closeButtonAriaLabel: "Close date picker",
    selectedDateFormatString: "Selected date {0}",
    todayDateFormatString: "Today\\'s date {0}",
    monthPickerHeaderAriaLabel: "{0}, change year",
    yearPickerHeaderAriaLabel: "{0}, change month",
    dayMarkedAriaLabel: "marked",
    isRequiredErrorMessage: "Field is required",
    invalidInputErrorMessage: "Invalid date format",
    isResetStatusMessage: "Invalid entry, date reset"
};
