import * as React from "react";
import {ActivityItem, DocumentCard, DocumentCardType, Link, mergeStyleSets, TextField} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import storeFileMethod from "../../api/storeFileMethod";
import addProjectCommentMethod from "../../api/addProjectCommentMethod";
import {useState} from "react";

const activityClassNames = mergeStyleSets({
    exampleRoot: {
        marginTop: '20px',
    },
    nameText: {
        fontWeight: 'bold',
    },
});

export default function Comments({project, type, setNotification, reloadData, titleOff = false, buttonText = 'Добавить комментарий'}) {
    const [comment, setComment] = useState({text: '', fileIds: []});

    const addComment = async () => {
        const fileInput = document.getElementById('commentFileInput');
        const fileIds = [];

        if (fileInput.files.length > 0) {
            const file = document.getElementById('commentFileInput').files[0];
            let response = await storeFileMethod(file);
            fileIds.push(response.id);
        }

        addProjectCommentMethod(project.data.id, comment.text.replace(/\n/g, '<br/>'), fileIds, type).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Комментарий добавлен'});
            setComment({text: '', fileIds: []});
            reloadData();
        });
    };

    return <>
        {project.data.comments.map((item, key) => {
            if (item.type !== type) {
                return <></>;
            }

            return <ActivityItem
                activityDescription={[
                    <div style={{display: 'flex', gap: 8}} key={key}>
                        <div className={activityClassNames.nameText}>{item.issuerName}</div>
                        {item.timestamp}
                    </div>
                ]}
                activityPersonas={[{ imageUrl: item.avatarId ? process.env.REACT_APP_API_URL + '/files/' + item.avatarId : null, imageInitials: item.issuerInitials, text: item.issuerName }]}
                onRenderComments={(comment) => <div>
                    <div style={{fontSize: FontSizes.size16}} dangerouslySetInnerHTML={{__html: comment.comments}} />
                    {item.files.length > 0 ? <Link
                        className={activityClassNames.nameText}
                        onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.files[0].id)}
                    >
                        {item.files[0].name}
                    </Link> : ''}
                </div>}
                comments={item.text}
                key={key}
                className={activityClassNames.exampleRoot}
            />;
        })}
        <DocumentCard type={DocumentCardType.compact} style={{marginTop: 20, padding: 15, height: 'unset'}}>
            <div style={{flex: 1}}>
                {titleOff ? <></> : <div style={{marginBottom: 10}}>Ваш комментарий</div>}
                <TextField multiline rows={2} placeholder="Текст" value={comment.text} onChange={(e) => setComment({...comment, text: e.target.value})} />
                <div style={{marginTop: 10}}><input id="commentFileInput" type="file" /></div>
                <PrimaryButton style={{marginTop: 15}} text={buttonText} onClick={addComment} />
            </div>
        </DocumentCard>
    </>;
};
