export const initialRequest = {
    id: null,
    number: null,
    code: null,
    name: null,
    parentProject: null,
    request: {
        status: null,
        source: {
            type: null,
            pageName: null,
            pagePath: null,
            callToAction: null,
            utmSource: null,
            utmMedium: null,
            utmCampaign: null,
            utmTerm: null,
            utmContent: null,
            ymClientId: null,
        },
        statusHistory: [],
        services: [],
    },
    services: [],
    contact: {
        name: null,
        firstName: null,
        lastName: null,
        middleName: null,
        email: null,
        phoneNumber: null,
        telegramUsername: null,
        instagramUsername: null,
    },
    company: {
        name: null,
        taxIdentificationNumber: null, // ИНН
        registrationReasonCode: null, // КПП
        mainRegistrationNumber: null, // ОГРН
        organizationsClassifierCode: null, // ОКПО
        legalAddress: null,
        mailingAddress: null,
        bankName: null,
        bandIdentificationCode: null, // БИК
        checkingAccount: null,
        correspondentAccount: null,
    },
    finance: {
        summaryPrice: null,
        paid: null,
        leftToPay: null,
        summaryPriceSubtractCommission: null,
        summarySpending: null,
        clearSummaryPrice: null,
        commission: {
            percent: null,
            sum: null,
        },
        prepayment: {
            title: null,
            type: null,
            status: null,
            percent: null,
            sum: null,
            onlinePayment: null,
            statusHistory: null
        },
        postPayment: {
            title: null,
            type: null,
            status: null,
            percent: null,
            sum: null,
            onlinePayment: null,
            statusHistory: null
        },
    },
    files: [
        {
            id: null,
            name: null,
            type: 'COMMERCIAL_OFFER',
            status: 'ATTACHED',
            sizeInBytes: null,
            customerFeedback: null,
        }
    ],
    subprojects: [],
    hasCustomerCabinet: false,
    hasInCustomerCabinet: false,
    customerCabinetEmail: '',
    comments: [],
    participants: []
};
