import * as React from "react";
import {useEffect, useState} from "react";
import {Breadcrumb, PrimaryButton, Spinner, SpinnerSize, Stack, TextField} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import addEmployeeMethod from "../../../api/addEmployeeMethod";
import getSpecializationsMethod from "../../../api/getSpecializationsMethod";
import getCurrentUserMethod from "../../../api/getCurrentUserMethod";

const initialForm = {
    contact: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        telegramUsername: null
    },
    profile: {
        specialization: null,
        city: null,
    }
};

export default function AddEmployee() {
    const [form, setForm] = useState(initialForm);
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const navigate = useNavigate();
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[]}});

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }

        if (!specializations.loaded) {
            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });
        }
    });

    let canChangeEmployees = false;
    user.security.permissions.forEach((permission) => {
        if (permission.key === 'change_employee' && permission.type === 'ALL') {
            canChangeEmployees = true;
        }
    });

    if (user.contact.firstName.length === 0) {
        return <Spinner size={SpinnerSize.large} />;
    }

    if (!canChangeEmployees) {
        navigate('/employees');
    }

    const breadcrumbs = [
        { text: 'Сотрудники', key: 'employees', href: '/employees' },
        { text: 'Добавить', key: 'employee', href: '/employees/new', isCurrentItem: true },
    ];

    const submit = () => {
        addEmployeeMethod(form.contact, form.profile).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            navigate('/employees');
        });
    };

    return (<>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 20}}
        />
        <Stack horizontal tokens={{ childrenGap: 50 }}>
            <div>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    <Dropdown
                        placeholder="Выберите"
                        label="Специализация"
                        options={specializations.data}
                        style={{marginBottom: 10}}
                        selectedKey={form.profile.specialization}
                        onChange={(e, option) => setForm({...form, profile: {...form.profile, specialization: option.key}})}
                    />
                    <TextField label="Имя" value={form.contact.firstName} onChange={(e) => setForm({...form, contact: {...form.contact, firstName: e.target.value}})} />
                    <TextField label="Фамилия" value={form.contact.lastName} onChange={(e) => setForm({...form, contact: {...form.contact, lastName: e.target.value}})} />
                    <TextField label="Телефон" value={form.contact.phoneNumber} onChange={(e) => setForm({...form, contact: {...form.contact, phoneNumber: e.target.value}})} />
                    <TextField label="Email" value={form.contact.email} onChange={(e) => setForm({...form, contact: {...form.contact, email: e.target.value}})} />
                    <TextField label="Telegram username (без @)" value={form.contact.telegramUsername} onChange={(e) => setForm({...form, contact: {...form.contact, telegramUsername: e.target.value}})} />
                </Stack>
            </div>
        </Stack>
        <PrimaryButton style={{marginTop: 30, height: 40}} text="Сохранить" onClick={submit} />
    </>);
};
