import * as React from "react";
import { useState, useEffect } from "react";
import getRequestCollectionMethod from "../../api/getRequestCollectionMethod";
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react';
import { FontSizes } from '@fluentui/theme';
import RequestCollectionFilter from "./RequestCollectionFilter";
import {useNavigate, useSearchParams} from "react-router-dom";
import Pagination from "../Theme/Pagination";
import getUserCollectionMethod from "../../api/getUserCollectionMethod";

export default function RequestCollection() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const storedFilterSelection = localStorage.getItem('REQUEST_FILTER') ? JSON.parse(localStorage.getItem('REQUEST_FILTER')) : {createdAtFrom: null, createdAtTo: null, createdAtFastButtonId: null, status: 'ALL', sourcePage: 'ALL', utmSource: 'ALL', text: '', saleManager: 'ALL', sorting: 'CREATED_AT_NEW'};
    const [filterSelection, setFilterSelection] = useState(searchParams.get('status') ? {...storedFilterSelection, createdAtFrom: null, createdAtTo: null, createdAtFastButtonId: 'ALL', status: searchParams.get('status'), prepaymentStatus: searchParams.get('prepaymentStatus')} : {...storedFilterSelection, prepaymentStatus: null});
    const [requestCollection, setRequestCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [page, setPage] = useState(1);
    const [salesManagers, setSalesManagers] = useState({loaded: false, data: []});

    if (!filterSelection.sorting) {
        filterSelection.sorting = 'CREATED_AT_NEW';
    }

    useEffect(() => {
        if (!requestCollection.loaded) {
            getRequestCollectionMethod(
                filterSelection.text,
                filterSelection.createdAtFrom,
                filterSelection.createdAtTo,
                filterSelection.status,
                filterSelection.sourcePage,
                filterSelection.utmSource,
                filterSelection.saleManager,
                filterSelection.sorting,
                page,
                filterSelection.prepaymentStatus ?? null
            ).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                //setSearchParams();
                setRequestCollection({loaded: true, data: response});
            });
        }
        if (!salesManagers.loaded) {
            getUserCollectionMethod('NEWBIZ').then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSalesManagers({loaded: true, data: response});
            });
        }
    });

    const reloadCollection = (freshFilterSelection) => {
        getRequestCollectionMethod(
            freshFilterSelection.text,
            freshFilterSelection.createdAtFrom,
            freshFilterSelection.createdAtTo,
            freshFilterSelection.status,
            freshFilterSelection.sourcePage,
            freshFilterSelection.utmSource,
            freshFilterSelection.saleManager,
            freshFilterSelection.sorting,
            page
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setRequestCollection({loaded: true, data: response});
        });
    };

    const renewPage = (page) => {
        setPage(page);
        getRequestCollectionMethod(
            filterSelection.text,
            filterSelection.createdAtFrom,
            filterSelection.createdAtTo,
            filterSelection.status,
            filterSelection.sourcePage,
            filterSelection.utmSource,
            filterSelection.saleManager,
            filterSelection.sorting,
            page,
            filterSelection.prepaymentStatus ?? null
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setRequestCollection({loaded: true, data: response});
        });
    };

    // useEffect(() => {
    //     if (lazyLoadData.loading) {
    //         getRequestCollectionMethod(null, null, null, null, lazyLoadData.page).then(response => {
    //             if (response.error) {
    //                 console.log(response.error.message);
    //                 return;
    //             }
    //
    //             setLazyLoadData({page: lazyLoadData.page, loading: false});
    //             const requestCollectionLocal = requestCollection.data.concat(response);
    //
    //             setRequestCollection({loaded: true, data: requestCollectionLocal});
    //         });
    //     }
    // }, [lazyLoadData, requestCollection]);

    const sorByColumn = (ev, column) => console.log(123);

    const columns = [
        {
            key: 'date',
            name: 'Дата',
            fieldName: 'date',
            maxWidth: 90,
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
        },
        {
            key: 'source',
            name: 'Источник',
            fieldName: 'source',
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'name',
            name: 'Имя',
            fieldName: 'name',
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'phoneNumber',
            name: 'Телефон',
            fieldName: 'phoneNumber',
            minWidth: 120,
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'email',
            name: 'Email',
            fieldName: 'email',
            minWidth: 150,
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'status',
            name: 'Статус',
            fieldName: 'status',
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item.status === 'WIN' || item.status === 'PAID') {
                    return <div style={{color: 'green'}}>{item.status}</div>
                } else if (item.status === 'LOSE' || item.status === 'SPAM') {
                    return <div style={{color: 'red'}}>{item.status}</div>
                } else if (item.status === 'PENDING') {
                    return <div style={{color: 'blue'}}>{item.status}</div>
                }

                return <div>{item.status}</div>
            }
        },
        {
            key: 'utmSource',
            name: 'Рекл. канал',
            fieldName: 'utmSource',
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'utmCampaign',
            name: 'Рекл. кампания',
            fieldName: 'utmCampaign',
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'utmTerm',
            name: 'UTM term',
            fieldName: 'utmTerm',
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'utmContent',
            name: 'UTM content',
            fieldName: 'utmContent',
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'lastComment',
            name: 'Комментарий',
            fieldName: 'lastComment',
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            // isSortedDescending: true,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: sorByColumn,
            data: 'string',
            isPadded: true,
        },
    ];

    const requestsCountLastDigit = parseInt(requestCollection.data.count.toString().slice(-1));
    let requestsCountWord = '';
    if (requestsCountLastDigit === 1) {
        requestsCountWord = 'запись';
    } else if (requestsCountLastDigit > 1 && requestsCountLastDigit < 5) {
        requestsCountWord = 'записи';
    } else {
        requestsCountWord = 'записей';
    }

    return (
        <>
            <div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Лиды</div>
            <RequestCollectionFilter salesManagers={salesManagers.data} filterSelection={filterSelection} setFilterSelection={setFilterSelection} reloadCollection={reloadCollection} />
            <div>Найдено: <span style={{fontWeight: 600}}>{requestCollection.data.count}</span> {requestsCountWord}</div>
            <DetailsList
                items={requestCollection.data.collection}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onActiveItemChanged={(item) => navigate('/leads/' + item.id)}
            />
            <div style={{marginTop: 20}}>
                <Pagination page={page} lastPage={requestCollection.data.lastPage} renewPage={renewPage} />
            </div>
        </>
    );
};
