import * as React from "react";
import {useState} from "react";
import {Breadcrumb, PrimaryButton, Stack, TextField} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import addProjectMethod from "../../api/addProjectMethod";

const initialForm = {
    name: null,
};

export default function AddProject() {
    const [form, setForm] = useState(initialForm);
    const navigate = useNavigate();

    const breadcrumbs = [
        { text: 'Проекты', key: 'projects', href: '/projects' },
        { text: 'Добавить', key: 'project', href: '/projects/new', isCurrentItem: true },
    ];

    const submit = () => {
        addProjectMethod(form.name).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            navigate('/projects/' + response.id);
        });
    };

    return (<>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 20}}
        />
        <Stack horizontal tokens={{ childrenGap: 50 }}>
            <div>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    <TextField label="Название проекта" value={form.name} onChange={(e) => setForm({...form, name: e.target.value})} />
               </Stack>
            </div>
        </Stack>
        <PrimaryButton style={{marginTop: 30, height: 40}} text="Создать проект" onClick={submit} />
    </>);
};
