import * as React from "react";
import {useState} from "react";
import {Breadcrumb, Checkbox, PrimaryButton, Stack, TextField} from "@fluentui/react";
import addRequestMethod from "../../api/addRequestMethod";
import {useNavigate} from "react-router-dom";
import {Dropdown} from "@fluentui/react/lib/Dropdown";

const initialForm = {
    contact: {
        name: null,
        email: null,
        phoneNumber: null,
        telegramUsername: null,
        instagramUsername: null
    },
    sourceType: null,
    companyName: null
};

export default function AddRequest() {
    const [form, setForm] = useState(initialForm);
    const [sendNotification, setSendNotification] = useState(1);
    const navigate = useNavigate();

    const breadcrumbs = [
        { text: 'Лиды', key: 'leads', href: '/leads' },
        { text: 'Добавить', key: 'lead', href: '/leads/new', isCurrentItem: true },
    ];

    const submit = () => {
        addRequestMethod(form.contact, form.sourceType, form.companyName, sendNotification).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            navigate('/leads/' + response.id);
        });
    };

    return (<>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 20}}
        />
        <Stack horizontal tokens={{ childrenGap: 50 }}>
            <div>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    <Dropdown
                        placeholder="Выберите"
                        label="Источник"
                        options={[
                            {key: 'DEPOT', text: 'Depot'},
                            {key: 'CHROME', text: 'ХРОМ'},
                            {key: 'UPSELL', text: 'Upsell'},
                            {key: 'WEBSITE', text: 'Сайт Brand Hub'},
                            {key: 'WHATSAPP', text: 'Написали в WhatsApp'},
                            {key: 'TELEGRAM', text: 'Написали в Telegram'},
                            {key: 'INSTAGRAM', text: 'Написали в Instagram'},
                            {key: 'EMAIL', text: 'Написали на почту'},
                            {key: 'CALL', text: 'Позвонили'},
                        ]}
                        style={{marginBottom: 10}}
                        selectedKey={form.sourceType}
                        onChange={(e, option) => setForm({...form, sourceType: option.key})}
                    />
                    <TextField label="Имя" value={form.contact.name} onChange={(e) => setForm({...form, contact: {...form.contact, name: e.target.value}})} />
                    <TextField label="Телефон" value={form.contact.phoneNumber} onChange={(e) => setForm({...form, contact: {...form.contact, phoneNumber: e.target.value}})} />
                    <TextField label="Email" value={form.contact.email} onChange={(e) => setForm({...form, contact: {...form.contact, email: e.target.value}})} />
                    <TextField label="Telegram username" value={form.contact.telegramUsername} onChange={(e) => setForm({...form, contact: {...form.contact, telegramUsername: e.target.value}})} />
                    <TextField label="Instagram username" value={form.contact.instagramUsername} onChange={(e) => setForm({...form, contact: {...form.contact, instagramUsername: e.target.value}})} />
                    <Checkbox label="Отправить уведомление о заявке" checked={sendNotification} onChange={(e) => setSendNotification(e.target.checked ? 1 : 0)} />
                </Stack>
            </div>
        </Stack>
        <PrimaryButton style={{marginTop: 30, height: 40}} text="Сохранить" onClick={submit} />
    </>);
};
