import React from "react";
import PropTypes from "prop-types";
import {TextField} from "@fluentui/react";

class MoneyField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isEditing: false };
    }

    onChange(event) {
        this.props.onChange(event);
    }

    toCurrency(number) {
        if (number === '' || number === null) {
            return null;
        }
        return new Intl.NumberFormat().format(number);
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    render() {
        return (
            <div>
                {this.state.isEditing ? (
                    <TextField
                        type="number"
                        label={this.props.label}
                        value={this.props.value}
                        onChange={this.onChange.bind(this)}
                        onBlur={this.toggleEditing.bind(this)}
                        suffix="руб."
                    />
                ) : (
                    <TextField
                        type="text"
                        label={this.props.label}
                        value={this.props.value === null ? '' : this.toCurrency(this.props.value)}
                        onFocus={this.toggleEditing.bind(this)}
                        suffix="руб."
                        disabled={this.props.disabled}
                        readOnly
                        style={this.props.disabled ? {color: this.props.style && this.props.style.color ? this.props.style.color : 'black'} : {}}
                    />
                )}
            </div>
        );
    }
}

MoneyField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func
};

export default MoneyField;
