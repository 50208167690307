import {MessageBar, MessageBarType} from "@fluentui/react";
import * as React from "react";

export default function Notification({type, text}) {
    if (type === 'hidden') {
        return <></>;
    }

    return <MessageBar messageBarType={MessageBarType[type]} isMultiline={false} dismissButtonAriaLabel="Закрыть">{text}</MessageBar>;
};
