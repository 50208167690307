import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    ActionButton,
    Breadcrumb,
    DetailsList, Stack,
    DetailsListLayoutMode, Popup, PrimaryButton,
    SelectionMode, TextField,
    TooltipHost, NormalPeoplePicker
} from "@fluentui/react";
import getSingleTeamMethod from "../../../api/getSingleTeamMethod";
import {FontSizes} from "@fluentui/theme";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeTeamMemberMethod from "../../../api/removeTeamMemberMethod";
import { useBoolean } from '@fluentui/react-hooks';
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import addEmployeeMethod from "../../../api/addEmployeeMethod";
import addTeamMemberMethod from "../../../api/addTeamMemberMethod";
import getSpecializationsMethod from "../../../api/getSpecializationsMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";
import addUserToTeamMethod from "../../../api/addUserToTeamMethod";
import getBrandHubEmployeeCollectionMethod from "../../../api/getBrandHubEmployeeCollectionMethod";
import getUserCollectionMethod from "../../../api/getUserCollectionMethod";

export default function AddTeamMember({teamId, reloadData, toggleIsPopupVisible, isMyTeam}) {
    const DEFAULT_TYPE = isMyTeam ? 'NEW' : null;
    const initialForm = {
        userId: null,
        contact: {
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: null,
            telegramUsername: null
        },
        profile: {
            specialization: null,
            city: null,
        },
        type: isMyTeam ? 'LINEAR' : null,
    };

    const [type, setType] = useState(DEFAULT_TYPE);
    const [userForm, setUserForm] = useState(initialForm);
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const [userCollection, setUserCollection] = useState({loaded: false, data: []});

    useEffect(() => {
        if (!specializations.loaded) {
            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });

            getUserCollectionMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUserCollection({loaded: true, data: response});
            });
        }
    });

    const submitNew = async () => {
        const response = await addTeamMemberMethod(teamId, userForm.type, userForm.contact, userForm.profile);
        if (response.error) {
            console.log(response.error.message);
        }

        setType(DEFAULT_TYPE);
        setUserForm(initialForm);
        reloadData();
        toggleIsPopupVisible();
    };

    const submitExistent = async () => {
        const response = await addUserToTeamMethod(teamId, userForm.type, userForm.userId);
        if (response.error) {
            console.log(response.error.message);
        }

        setType(DEFAULT_TYPE);
        setUserForm(initialForm);
        reloadData();
        toggleIsPopupVisible();
    };

    if (type === null) {
        return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
            <DefaultButton style={{height: 40}} text="Создать нового пользователя" onClick={() => setType('NEW')} />
            <DefaultButton style={{height: 40}} text="Выбрать из существующих" onClick={() => setType('EXISTENT')} />
        </Stack>;
    }

    if (type === 'NEW') {
        return <div>
            <Stack horizontal tokens={{ childrenGap: 50 }}>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    <TextField label="Имя" value={userForm.contact.firstName} onChange={(e) => setUserForm({...userForm, contact: {...userForm.contact, firstName: e.target.value}})} />
                    <TextField label="Фамилия" value={userForm.contact.lastName} onChange={(e) => setUserForm({...userForm, contact: {...userForm.contact, lastName: e.target.value}})} />
                    <TextField label="Телефон" value={userForm.contact.phoneNumber} onChange={(e) => setUserForm({...userForm, contact: {...userForm.contact, phoneNumber: e.target.value}})} />
                    <TextField label="Email" value={userForm.contact.email} onChange={(e) => setUserForm({...userForm, contact: {...userForm.contact, email: e.target.value}})} />
                    <TextField label="Telegram username (без @)" value={userForm.contact.telegramUsername} onChange={(e) => setUserForm({...userForm, contact: {...userForm.contact, telegramUsername: e.target.value}})} />
                </Stack>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    {isMyTeam
                        ? <></>
                        : <Dropdown
                            placeholder="Выберите"
                            label="Тип аккаунта"
                            options={[
                                {key: 'CHIEF', text: 'Главный'},
                                {key: 'LINEAR', text: 'Линейный'},
                            ]}
                            style={{marginBottom: 10}}
                            selectedKey={userForm.type}
                            onChange={(e, option) => setUserForm({...userForm, type: option.key})}
                        />
                    }
                    <Dropdown
                        placeholder="Выберите"
                        label="Специализация"
                        options={specializations.data}
                        style={{marginBottom: 10}}
                        selectedKey={userForm.profile.specialization}
                        onChange={(e, option) => setUserForm({...userForm, profile: {...userForm.profile, specialization: option.key}})}
                    />
                </Stack>
            </Stack>
            <PrimaryButton style={{marginTop: 30, height: 40}} text="Добавить" onClick={submitNew} />
        </div>;
    }

    if (type === 'EXISTENT') {
        const people = userCollection.data.map((user) => {
            const selectedSpecializations = specializations.data.filter((item) => item.key === user.profile.specialization);
            const secondaryText = selectedSpecializations.length > 0 ? selectedSpecializations[0].text : null;

            return {
                isValid: true,
                key: user.id,
                text: user.firstName + ' ' + (user.lastName ?? ''),
                secondaryText: secondaryText,
            };
        });

        function doesTextStartWith(text, filterText) {
            return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
        }

        function removeDuplicates(personas, possibleDupes) {
            return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
        }

        function listContainsPersona(persona, personas) {
            if (!personas || !personas.length || personas.length === 0) {
                return false;
            }
            return personas.filter(item => item.text === persona.text).length > 0;
        }

        const filterPersonasByText = (filterText) => {
            return people.filter(item => doesTextStartWith(item.text, filterText));
        };

        const filterPromise = (personasToReturn) => {
            return personasToReturn;
        };

        const onFilterChanged = (filterText, currentPersonas, limitResults) => {
            if (filterText) {
                let filteredPersonas = filterPersonasByText(filterText);

                filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
                filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
                return filterPromise(filteredPersonas);
            } else {
                return [];
            }
        };

        const returnMostRecentlyUsed = (currentPersonas) => {
            return filterPromise(removeDuplicates(people, currentPersonas));
        };

        return <div>
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                <Dropdown
                    placeholder="Выберите"
                    label="Тип аккаунта"
                    options={[
                        {key: 'CHIEF', text: 'Главный'},
                        {key: 'LINEAR', text: 'Линейный'},
                    ]}
                    style={{marginBottom: 10}}
                    selectedKey={userForm.type}
                    onChange={(e, option) => setUserForm({...userForm, type: option.key})}
                />
                <div>
                    <div className="ms-fontWeight-semibold" style={{marginBottom: 5}}>Пользователь</div>
                    <NormalPeoplePicker
                        onResolveSuggestions={onFilterChanged}
                        onEmptyInputFocus={returnMostRecentlyUsed}
                        className={'ms-PeoplePicker'}
                        key={'normal'}
                        selectionAriaLabel={'Selected contacts'}
                        removeButtonAriaLabel={'Remove'}
                        onChange={(values) => values.length > 0 ? setUserForm({...userForm, userId: values[0].key}) : null}
                        itemLimit={1}
                    />
                </div>
            </Stack>
            <PrimaryButton style={{marginTop: 30, height: 40}} text="Добавить" onClick={submitExistent} />
        </div>;
    }
};
