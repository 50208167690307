const serverUrl = process.env.REACT_APP_API_URL;

export default function loginMethod(email, password) {
    const url = new URL(serverUrl + '/api/v2/pc/login');

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({email: email, password: password}),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }).then(response => response.json());
}
