import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {ActionButton, DetailsList, DetailsListLayoutMode, SelectionMode, Stack} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import getChatCollectionMethod from "../../api/chat/getChatCollectionMethod";
import getCurrentUserMethod from "../../api/getCurrentUserMethod";
import CallOut from "../Theme/CallOut";
import getBrandHubEmployeeCollectionMethod from "../../api/getBrandHubEmployeeCollectionMethod";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import createChatMethod from "../../api/chat/createChatMethod";
import {PrimaryButton} from "@fluentui/react/lib/Button";

export default function ChatCollection() {
    const navigate = useNavigate();
    const [chatCollection, setChatCollection] = useState({loaded: false, data: []});
    const [page, setPage] = useState(1);
    const [user, setUser] = useState({id:null,contact:{firstName:'',lastName:'',email:''},security:{permissions:[]}});
    const [userCollection, setUserCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [newChatForm, setNewChatForm] = useState({userId: null});

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
        if (!chatCollection.loaded) {
            getChatCollectionMethod(page).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setChatCollection({loaded: true, data: response});
            });
        }
        if (!userCollection.loaded) {
            getBrandHubEmployeeCollectionMethod(page).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUserCollection({loaded: true, data: response});
            });
        }
    });

    const columns = [
        {
            key: 'name',
            name: 'Название чата',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 220,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                let persona = <></>;

                if (item.avatarId) {
                    persona = <Persona imageUrl={process.env.REACT_APP_API_URL + '/files/' + item.avatarId} text={item.chatName} size={PersonaSize.size32} />;
                } else {
                    persona = <Persona text={item.chatName} size={PersonaSize.size32} />;
                }

                if (item.lastMessage) {
                    return <div>
                        {persona}
                        <div style={{marginTop: 10, color: 'gray'}}>{item.lastMessage.text}</div>
                    </div>;
                } else {
                    return <div>
                        {persona}
                        <div style={{marginTop: 10, color: 'gray'}}>нет сообщений</div>
                    </div>;
                }
            }
        },
    ];

    const users = userCollection.data.collection.map((item) => {
        return {key: item.id, text: item.firstName + ' ' + (item.lastName ?? '')}
    });

    return (<>
        <div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Чаты</div>
        <CallOut component={<>
            <Stack tokens={{ childrenGap: 15 }}>
                <Dropdown
                    placeholder="Все"
                    label="Собеседник"
                    options={users}
                    styles={{dropdown: { width: 180 }}}
                    selectedKey={newChatForm.userId}
                    onChange={(e, option) => setNewChatForm({...newChatForm, userId: option.key})}
                />
                <PrimaryButton text="Создать чат" onClick={() => createChatMethod('PERSONAL', null, [user.id, newChatForm.userId])} />
            </Stack>
        </>} buttonText="Новый чат" buttonIconName="Add" />
        <DetailsList
            items={chatCollection.data}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => item.key}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onActiveItemChanged={(item) => navigate('/chats/' + item.id)}
        />
    </>);
};
