import * as React from "react";
import {useEffect, useState} from "react";
import getCurrentUserMethod from "../../api/getCurrentUserMethod";
import changeUserContactMethod from "../../api/changeUserContactMethod";
import {Stack, TextField} from "@fluentui/react";
import Notification from "../notifications/Notification";
import {FontSizes} from "@fluentui/theme";
import {Persona} from "@fluentui/react/lib/Persona";
import changeUserProfileMethod from "../../api/changeUserProfileMethod";
import storeFileMethod from "../../api/storeFileMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeFileMethod from "../../api/removeFileMethod";

export default function MyProfile() {
    const [user, setUser] = useState({id: null, contact: {firstName: '', lastName: '', email: ''}, profile: {avatarId: null}});
    const [notification, setNotification] = useState({type: 'hidden', text: ''});

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    const changeContact = (key, value) => {
        const contact = {...user.contact, [key]: value};
        setUser({...user, contact: contact});

        changeUserContactMethod(user.id, contact).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные контакта сохранены'});
        });
    };

    const handleAvatarChange = async (e) => {
        if (e.target.files) {
            if (user.profile.avatarId !== null) {
                await removeFileMethod(user.profile.avatarId);
            }

            const file = e.target.files[0];
            let response = await storeFileMethod(file);

            await changeAvatarId(response.id);
        }
    };

    const changeAvatarId = async (avatarId) => {
        const profile = {...user.profile, avatarId: avatarId};
        setUser({...user, profile: profile});

        changeUserProfileMethod(user.id, profile).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные профиля сохранены'});
        });
    };

    return (<div>
        <div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Мой профиль</div>
        <Notification type={notification.type} text={notification.text} />
        <div style={{marginTop: 10}}></div>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
            <Stack horizontal style={{alignItems: 'center'}}>
                {user.profile.avatarId !== null
                    ? <Persona imageUrl={process.env.REACT_APP_API_URL + '/files/' + user.profile.avatarId} />
                    : <Persona imageInitials={(user.contact.firstName ? user.contact.firstName[0] : '') + (user.contact.lastName ? user.contact.lastName[0] : '')} />
                }
                <input id="avatarInput" type="file" onChange={handleAvatarChange} hidden />
                <DefaultButton text="Сменить аватар" onClick={() => document.getElementById("avatarInput").click()} />
                {user.profile.avatarId !== null
                    ?  <div style={{marginLeft: 15}}>
                        <FontIcon aria-label="Delete" iconName="Delete" style={{cursor: 'pointer'}} onClick={async () => {
                            await removeFileMethod(user.profile.avatarId);
                            await changeAvatarId(null);
                        }} />
                    </div>
                    : <></>
                }
            </Stack>
            <TextField label="Email" disabled value={user.contact.email} onChange={(e) => changeContact('email', e.target.value)} />
            <TextField label="Имя" value={user.contact.firstName} onChange={(e) => changeContact('firstName', e.target.value)} />
            <TextField label="Фамилия" value={user.contact.lastName} onChange={(e) => changeContact('lastName', e.target.value)} />
            <TextField label="Телефон" value={user.contact.phoneNumber} onChange={(e) => changeContact('phoneNumber', e.target.value)} />
            <TextField label="Telegram username (без @)" value={user.contact.telegramUsername} onChange={(e) => changeContact('telegramUsername', e.target.value)} />
        </Stack>
    </div>);
};
