import * as React from "react";
import {useEffect, useState} from "react";
import getChatMessageCollectionMethod from "../../api/chat/getChatMessageCollectionMethod";
import {useParams} from "react-router-dom";
import {DocumentCard, DocumentCardType, TextField} from "@fluentui/react";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import addChatMessageMethod from "../../api/chat/addChatMessageMethod";
import {io} from "socket.io-client";
import getCurrentUserMethod from "../../api/getCurrentUserMethod";
import {getAuthToken} from "../../functions/authToken";

// const socket = io('127.0.0.1:3001', {path: '/socket/'});

export default function Chat() {
    const [messagesCollection, setMessagesCollection] = useState({loaded: false, data: []});
    const [user, setUser] = useState({id:null,contact:{firstName:'',lastName:'',email:''},security:{permissions:[]}});
    const [message, setMessage] = useState('');
    const params = useParams();

    const socket = io('127.0.0.1:3001', {path: '/socket/'});

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
                socket.emit('register', response.id);
            });
        }
        if (!messagesCollection.loaded) {
            getChatMessageCollectionMethod(params.id, 1).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setMessagesCollection({loaded: true, data: response});

                socket.on('share-message', (message) => {
                    if (message.chatId === params.id) {
                        response.push(message);
                        setMessagesCollection({loaded: true, data: response})
                    }
                });
            });
        }
    });

    // const addMessage = () => {
    //     socket.emit('send-message', params.id, user.id, message);
    //     // addChatMessageMethod(params.id, message, [])
    // };

    return <>
        {messagesCollection.data.map((item, index) => {
           return <div key={index} style={{marginBottom: 10}}>
               <div>{item.createdAt}</div>
               <div>{item.sender.firstName} {item.sender.lastName}</div>
               <div>{item.text}</div>
           </div>;
        })}
        <DocumentCard type={DocumentCardType.compact} style={{marginTop: 20, padding: 15, height: 'unset'}}>
            <div style={{flex: 1}}>
                <div style={{marginBottom: 10}}>Ваше сообщение</div>
                <TextField multiline rows={2} placeholder="Текст" value={message} onChange={(e) => setMessage(e.target.value)} />
                <div style={{marginTop: 10}}><input id="commentFileInput" type="file" /></div>
                <PrimaryButton style={{marginTop: 15}} text="Отправить" onClick={() => socket.emit('send-message', params.id, user.id, message, getAuthToken())} />
            </div>
        </DocumentCard>
    </>;
};
