import * as React from "react";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {ActionButton, Callout} from "@fluentui/react";
import {DefaultButton} from "@fluentui/react/lib/Button";

export default function CallOut({component, buttonText, buttonIconName, isActionButton = false}) {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');

    const button = isActionButton
        ? <ActionButton id={buttonId} iconProps={{ iconName: buttonIconName }} allowDisabledFocus onClick={toggleIsCalloutVisible} style={{marginTop: 6}}>
            {buttonText}
        </ActionButton>
        :  <DefaultButton id={buttonId} iconProps={{ iconName: buttonIconName }} allowDisabledFocus onClick={toggleIsCalloutVisible} style={{marginTop: 6}}>
            {buttonText}
        </DefaultButton>;

    return <>
        {button}
        {isCalloutVisible && (
            <Callout
                role="dialog"
                gapSpace={0}
                target={`#${buttonId}`}
                onDismiss={toggleIsCalloutVisible}
                setInitialFocus
            >
                <div style={{padding: 20, paddingBottom: 30}}>
                    {component}
                </div>
            </Callout>
        )}
    </>;
};
