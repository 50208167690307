import * as React from "react";
import {
    mergeStyleSets,
    DefaultButton,
    FocusTrapZone,
    Layer,
    Overlay,
    Popup,
    Stack,
    TextField,
    PersonaPresence, DetailsList, SelectionMode, DetailsListLayoutMode, Rating, RatingSize
} from '@fluentui/react';
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useEffect, useRef, useState} from "react";
import getFreelancersCollectionMethod from "../../../../api/getFreelancersCollectionMethod";
import {Persona, PersonaSize} from "@fluentui/react/lib/Persona";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import Pagination from "../../../Theme/Pagination";

const popupStyles = mergeStyleSets({
    root: {
        background: 'rgba(0, 0, 0, 0.2)',
        bottom: '0',
        left: '0',
        position: 'fixed',
        right: '0',
        top: '0',
    },
    content: {
        background: 'white',
        left: '50%',
        maxWidth: '400px',
        padding: '0 2em 2em',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
});

const dropdownStyles = {
    dropdown: { width: 180 },
};

export default function FreelancersPopup({specializations, isPopupVisible, hidePopup, addFreelancer, canSeeFreelancers}) {
    const [userCollection, setUserCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [filterSelection, setFilterSelection] = useState({specialization: 'ALL', type: 'ALL', name: '', competencies: []});
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({loaded: false, specializations: [], types: [{key: 'Проверенный', text: 'Проверенный'},{key: 'Новый', text: 'Новый'}]});
    const specializations2 = [{key: 'ALL', text: 'Все'}, ...specializations.data];
    const types = [...filters.types];
    types.unshift({key: 'ALL', text: 'Все'});

    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.classList.contains('ms-Button-flexContainer') && !event.target.classList.contains('ms-Dropdown-optionText') && event.target.nodeName !== 'INPUT') {
            hidePopup();
        }
    };

    useEffect(() => {
        if (!userCollection.loaded && canSeeFreelancers) {
            getFreelancersCollectionMethod(filterSelection.name, filterSelection.specialization, filterSelection.type, page, 10, filterSelection.competencies).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUserCollection({loaded: true, data: response});
            });
        }

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [canSeeFreelancers]);

    const reloadCollection = (freshFilterSelection) => {
        getFreelancersCollectionMethod(freshFilterSelection.name, freshFilterSelection.specialization, freshFilterSelection.type, page, 10, freshFilterSelection.competencies).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setUserCollection({loaded: true, data: response});
        });
    };

    const renewPage = (page) => {
        setPage(page);
        getFreelancersCollectionMethod(filterSelection.name, filterSelection.specialization, filterSelection.type, page, 10, filterSelection.competencies).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setUserCollection({loaded: true, data: response});
        });
    };

    const changeFilter = (newFilterSelection) => {
        setFilterSelection(newFilterSelection);
        reloadCollection(newFilterSelection);
    };

    const columns = [
        {
            key: 'name',
            name: 'Имя',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 220,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                const selectedSpecializations = specializations.data.filter((el) => el.key === item.profile.specialization);
                const secondaryText = selectedSpecializations.length > 0 ? selectedSpecializations[0].text : null;
                const text = item.firstName + ' ' + (item.lastName ?? '');

                if (item.projectsCount === 1) {
                    return <Persona text={text} secondaryText={secondaryText} size={PersonaSize.size40} presence={PersonaPresence.away} />
                }

                if (item.projectsCount >= 2) {
                    return <Persona text={text} secondaryText={secondaryText} size={PersonaSize.size40} presence={PersonaPresence.busy} />
                }

                return <Persona text={text} secondaryText={secondaryText} size={PersonaSize.size40} presence={PersonaPresence.online} />
            }
        },
        {
            key: 'score',
            name: 'Рейтинг',
            fieldName: 'score',
            minWidth: 100,
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                return <Rating
                    max={5}
                    size={RatingSize.Large}
                    rating={item.score}
                    allowZeroStars
                    ariaLabel="Оценка"
                    style={{pointerEvents: 'none'}}
                />;
            }
        },
        {
            key: 'projectsTotalCount',
            name: 'Общее кол-во проектов',
            fieldName: 'projectsTotalCount',
            minWidth: 80,
            maxWidth: 120,
            isRowHeader: true,
            data: 'string',
            onRender: (user) => {
                if (user.projectsTotalCount === 0) {
                    return 'нет проектов';
                }

                const projectsCountLastDigit = parseInt(user.projectsTotalCount.toString().slice(-1));
                let projectsCountWord = '';
                if (projectsCountLastDigit === 1) {
                    projectsCountWord = 'проект';
                } else if (projectsCountLastDigit > 1 && projectsCountLastDigit < 5) {
                    projectsCountWord = 'проекта';
                } else {
                    projectsCountWord = 'проектов';
                }

                return <div>{user.projectsTotalCount} {projectsCountWord}</div>
            }
        },
    ];

    if (!isPopupVisible) {
        return <></>;
    }

    const freelancersCountLastDigit = parseInt(userCollection.data.count.toString().slice(-1));
    let freelancersCountWord = '';
    if (freelancersCountLastDigit === 1) {
        freelancersCountWord = 'исполнитель';
    } else if (freelancersCountLastDigit > 1 && freelancersCountLastDigit < 5) {
        freelancersCountWord = 'исполнителя';
    } else {
        freelancersCountWord = 'исполнителей';
    }

    return <Layer>
        <Popup
            className={popupStyles.root}
            role="dialog"
            aria-modal="true"
            onDismiss={hidePopup}
            enableAriaHiddenSiblings={true}
        >
            <Overlay />
            {/*<FocusTrapZone>*/}
                <div ref={popupRef} role="document" className={popupStyles.content} style={{maxWidth: '100%', width: '50%', overflowY: 'auto', maxHeight: '90vh', minHeight: '60vh'}}>
                    <Stack horizontal tokens={{ childrenGap: 20 }} style={{marginBottom: 1, alignItems: 'center', justifyContent: 'space-between'}}>
                        <h2>Добавить исполнителя</h2>
                        <DefaultButton onClick={hidePopup}>Закрыть</DefaultButton>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }} style={{marginBottom: 1}}>
                        <TextField label="Поиск" styles={{root: {width: 220}}} value={filterSelection.name} onChange={(e) => changeFilter({...filterSelection, name: e.target.value})} />
                        <Dropdown
                            placeholder="Все"
                            label="Направление"
                            options={[
                                {key: 'ALL', text: 'Все'},
                                {key: 'DESIGNER', text: 'Дизайн'},
                                {key: 'STRATEGIST', text: 'Стратегия'},
                                {key: 'COPYWRITER', text: 'Копирайтинг'},
                            ]}
                            styles={dropdownStyles}
                            selectedKey={filterSelection.specialization}
                            onChange={(e, option) => changeFilter({...filterSelection, specialization: option.key})}
                        />
                        <Dropdown
                            placeholder="Не выбрана"
                            label="Специализация"
                            options={[
                                {key: 'арт-директоринг', text: 'Арт-директоринг'},
                                {key: 'айдентика', text: 'Айдентика'},
                                {key: 'упаковка', text: 'Упаковка'},
                                {key: 'web', text: 'WEB'},
                                {key: 'иллюстрация', text: 'Иллюстрация'},
                                {key: 'бренд-стратегия', text: 'Бренд-стратегия'},
                                {key: 'нейминг', text: 'Нейминг'},
                                {key: 'копирайт', text: 'Копирайт'},
                            ]}
                            styles={dropdownStyles}
                            selectedKeys={filterSelection.competencies}
                            onChange={(e, option) => changeFilter({...filterSelection, competencies: option.selected ? [...filterSelection.competencies, option.key] : filterSelection.competencies.filter(key => key !== option.key)})}
                            multiSelect={true}
                        />
                    </Stack>
                    <div style={{marginTop: 5}}>Найдено <span style={{fontWeight: 600}}>{userCollection.data.count}</span> {freelancersCountWord}</div>
                    <DetailsList
                        items={userCollection.data.collection}
                        compact={false}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        getKey={(item, index) => item.key}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onActiveItemChanged={async (item) => {
                            await addFreelancer(item.id);
                            hidePopup();
                        }}
                    />
                    <div style={{marginTop: 20}}>
                        <Stack horizontal tokens={{ childrenGap: 15 }}>
                            <DefaultButton text="Назад" onClick={() => renewPage(page - 1)} disabled={page === 1} />
                            <PrimaryButton text="Далее" onClick={() => renewPage(page + 1)} disabled={page === userCollection.data.lastPage} />
                        </Stack>
                    </div>
                </div>
            {/*</FocusTrapZone>*/}
        </Popup>
    </Layer>;
};
