import * as React from "react";
import {
    ActionButton,
    DetailsList,
    DetailsListLayoutMode, mergeStyleSets,
    PrimaryButton,
    SelectionMode, Stack,
    TooltipHost
} from "@fluentui/react";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeCommercialOfferMethod from "../../../api/removeCommercialOfferMethod";
import storeFileMethod from "../../../api/storeFileMethod";
import attachCommercialOfferMethod from "../../../api/attachCommercialOfferMethod";
import sendCommercialOfferMethod from "../../../api/sendCommercialOfferMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {fileIconClassNames} from "../../data/fileIconClassNames";
import {getFileIcon} from "../../data/getFileIcon";

export default function CommercialOffer({reloadData, request}) {
    const getCommercialOffers = () => request.data.files.filter((file) => file.type === 'COMMERCIAL_OFFER');

    const handleCommercialOfferChange = async (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            let response = await storeFileMethod(file);
            response = await attachCommercialOfferMethod(request.data.id, response.id);

            if (response.status === 'ok') {
                reloadData();
            }
        }
    };

    const commercialOfferFileColumns = [
        {
            key: 'fileType',
            name: 'Тип файла',
            className: fileIconClassNames.fileIconCell,
            iconClassName: fileIconClassNames.fileIconHeaderIcon,
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item) => (
                <TooltipHost content={`${item.extension} file`} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                    <img src={getFileIcon(item.extension).url} className={fileIconClassNames.fileIconImg} alt={`${item.extension} file icon`} />
                </TooltipHost>
            ),
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>{item.name}</span>,
        },
        {
            key: 'status',
            name: 'Статус',
            fieldName: 'status',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                let status = '';

                if (item.status === 'ATTACHED') {
                    status = 'Видно клиенту в ЛК, можно отправить по e-mail';
                } else if (item.status === 'SENT_TO_CUSTOMER') {
                    status = <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                        <FontIcon aria-label="Accept" iconName="Accept" />
                        <div>Видно клиенту в ЛК, и отправлено по email</div>
                    </div>;
                } else if (item.status === 'ACCEPTED') {
                    status = <div style={{display: 'flex', alignItems: 'center', gap: 8, color: 'green'}}>
                        <FontIcon aria-label="Accept" iconName="Accept" />
                        <div>Клиент согласовал это в ЛК</div>
                    </div>;
                } else if (item.status === 'REJECTED') {
                    status = <div style={{display: 'flex', alignItems: 'center', gap: 8, color: 'red'}}>
                        <FontIcon aria-label="Accept" iconName="StatusErrorFull" />
                        <div>Клиент отклонил это в ЛК</div>
                    </div>;
                }

                return <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>{status}</span>
            },
        },
        {
            key: 'action',
            name: 'Действие',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <FontIcon aria-label="Delete" iconName="Delete" style={{cursor: 'pointer'}} onClick={async () => {
                    await removeCommercialOfferMethod(request.data.id, item.id);
                    reloadData();
                }} />
            },
        },
    ];

    const sendCommercialOffer = async () => {
        const response = await sendCommercialOfferMethod(request.data.id);

        if (response.status === 'ok') {
            reloadData();
        }
    };

    return (<>
        <Stack tokens={{ childrenGap: 20 }}>
            {getCommercialOffers().length === 0
                ? <div>
                    <input id="commercialOfferFileInput" type="file" onChange={handleCommercialOfferChange} hidden />
                    <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={() => document.getElementById("commercialOfferFileInput").click()}>
                        Прикрепить файл
                    </ActionButton>
                </div>
                : <>
                    <DetailsList
                        items={getCommercialOffers()}
                        compact={false}
                        columns={commercialOfferFileColumns}
                        selectionMode={SelectionMode.none}
                        getKey={(item, index) => item.key}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={false}
                    />
                    {getCommercialOffers()[0].status === 'ATTACHED'
                        ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <PrimaryButton
                                text="Отправить по e-mail"
                                iconProps={{ iconName: 'Mail' }}
                                allowDisabledFocus
                                onClick={sendCommercialOffer}
                            />
                            <DefaultButton text="Скопировать ссылку" iconProps={{ iconName: 'Copy' }} onClick={(e) => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/files/' + getCommercialOffers()[0].id)} />
                        </Stack>
                        : <></>
                    }
                </>
            }
        </Stack>
    </>);
};
