import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    Breadcrumb, DetailsList, DetailsListLayoutMode, mergeStyleSets, Pivot, PivotItem, SelectionMode,
    Separator,
    Stack,
    TextField, TooltipHost
} from "@fluentui/react";
import {InitialProjectData} from "../Project/ViewProject/Data/InitialProjectData";
import getSingleProjectMethod from "../../api/getSingleProjectMethod";
import Notification from "../notifications/Notification";
import Finance from "../Project/ViewProject/Tabs/Finance/Finance";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {datePickerStringsRu} from "../data/datePickerStringsRu";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeDocumentMethod from "../../api/removeDocumentMethod";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import makeProjectFinanceSnapshotMethod from "../../api/makeProjectFinanceSnapshotMethod";
import signProjectRealCostsMethod from "../../api/signProjectRealCostsMethod";

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});

export default function ViewWaitingRealCostsProject() {
    const params = useParams();
    const [project, setProject] = useState({loaded: false, data: InitialProjectData});
    const [notification, setNotification] = useState({type: 'hidden', text: ''});

    const reloadData = () => setProject({loaded: false, data: {...project.data}});

    useEffect(() => {
        if (!project.loaded) {
            getSingleProjectMethod(params.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setProject({loaded: true, data: response});
            });
        }
    });

    const navigate = useNavigate();

    const breadcrumbs = [
        { text: 'Финансы', key: 'financeCollection', href: '/finance#closing', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: project.data.code ?? project.data.id, key: 'viewFinance', href: '/finance/' + project.data.id, isCurrentItem: true },
    ];

    const statuses = [
        { key: 'IN_PROGRESS', text: 'В работе' },
        { key: 'HOLD', text: 'Холд' },
        { key: 'DONE', text: 'Закрыт' },
    ];

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    const getStartDate = () => {
        if (!project.data.startedAt) {
            return null;
        }

        return new Date(project.data.startedAt);
    };

    let customer = '';

    if (project.data.company !== null && project.data.company.name !== null) {
        customer = project.data.company.name;
    }

    if (project.data.contact.name !== null) {
        customer = (project.data.contact.lastName ?? '') + ' ' + (project.data.contact.firstName ?? project.data.contact.name) + ' ' + (project.data.contact.middleName ?? '');
    }

    const getFileIcon = (docType) => {
        return {
            docType,
            url: `https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/item-types/16/${docType}.svg`,
        };
    };

    const formatDateTime = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = `${date.getHours()}`.padStart(2, "0");
        const minutes = `${date.getMinutes()}`.padStart(2, "0");

        return `${day} ${month} ${year}, в ${hours}:${minutes}`;
    }

    const getFinanceSnapshots = () => project.data.files.filter((file) => file.type === 'FINANCE_SNAPSHOT');

    const financeSnapshotColumns = [
        {
            key: 'fileType',
            name: 'Тип файла',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item) => (
                <TooltipHost content={`${item.extension} file`} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                    <img src={getFileIcon(item.extension).url} className={classNames.fileIconImg} alt={`${item.extension} file icon`} />
                </TooltipHost>
            ),
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>{item.addedAt === null ? item.name : 'Снимок данных от ' + formatDateTime(new Date(item.addedAt))}</span>,
        },
    ];

    const financeSnapShotLength = getFinanceSnapshots().length;
    const lastFinanceSnapShot = getFinanceSnapshots()[financeSnapShotLength - 1];

    return (
        <div>
            <Breadcrumb
                items={breadcrumbs}
                maxDisplayedItems={10}
                ariaLabel="Навигация"
                overflowAriaLabel="Показать больше"
                style={{marginBottom: 20}}
            />
            <Notification type={notification.type} text={notification.text} />
            <div style={{marginTop: 20}}>
                <PrimaryButton text="Подтвердить закрытие проекта" iconProps={{ iconName: 'Accept' }} onClick={async () => {
                    const response = await signProjectRealCostsMethod(project.data.id);
                    if (response.error) {
                        setNotification({type: 'error', text: response.error});
                        return;
                    }

                    if (response.status === 'ok') {
                        setNotification({type: 'success', text: 'Проект закрыт'});
                        setTimeout(() =>  navigate('/finance#closing'), 500);
                    }
                }}/>
            </div>
            <div style={{marginTop: 20}}>
                <Stack horizontal tokens={{ childrenGap: 30 }} style={{marginBottom: 15}}>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Название проекта" value={project.data.name} disabled={true} style={{color: 'black'}} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Менеджер" value={project.data.manager ? project.data.manager.name : 'Не указан'} disabled={true} style={{color: 'black'}} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Заказчик" value={customer} disabled={true} style={{color: 'black'}} />
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 30 }}>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Номер проекта" value={project.data.code} disabled={true} style={{color: 'black'}} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <Dropdown
                            placeholder="Выберите"
                            label="Статус"
                            options={statuses}
                            selectedKey={project.data.status}
                            disabled={true}
                            styles={{ title: {color: 'black'}}}
                        />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Дата старта" value={formatDate(getStartDate())} disabled={true} style={{color: 'black'}} />
                    </Stack>
                </Stack>
                <Separator />
                <Pivot aria-label="Меню">
                    <PivotItem headerText="Текущие данные">
                        <div style={{marginTop: 20}}>
                            <div style={{marginTop: 20, marginBottom: 20, display: 'flex', gap: 15, alignItems: 'center'}}>
                                <DefaultButton text="Сделать снимок данных в Excel" iconProps={{ iconName: 'Save' }} onClick={async () => {
                                    const response = await makeProjectFinanceSnapshotMethod(project.data.id);
                                    if (response.exception || response.error) {
                                        console.log(response.error ? response.error.message : response.exception);
                                        setNotification({type: 'error', text: response.error});
                                        return;
                                    }

                                    if (response.status === 'ok') {
                                        reloadData();
                                        setNotification({type: 'success', text: 'Данные зафиксированы и будут храниться в истории'});
                                    }
                                }}  />
                                {financeSnapShotLength > 0 && lastFinanceSnapShot.addedAt !== null
                                    ? <div>Последний снимок <strong>{formatDateTime(new Date(lastFinanceSnapShot.addedAt))}</strong></div>
                                    : <></>
                                }
                            </div>
                            <Separator />
                            <Finance project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} canChangeFinanceFact={true} />
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Снимки данных в Excel">
                        <div style={{marginTop: 20}}>
                            {financeSnapShotLength > 0
                                ? <DetailsList
                                    items={getFinanceSnapshots().reverse()}
                                    compact={false}
                                    columns={financeSnapshotColumns}
                                    selectionMode={SelectionMode.none}
                                    getKey={(item, index) => item.key}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={false}
                                />
                                : <>История отсутствует</>
                            }
                        </div>
                    </PivotItem>
                </Pivot>
            </div>
        </div>
    );
}
