import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Notification from "../../notifications/Notification";
import Breadcrumbs from "./Breadcrumbs";
import getSingleProjectMethod from "../../../api/getSingleProjectMethod";
import {
    ActionButton,
    ActivityItem,
    DetailsList,
    DetailsListLayoutMode, DocumentCard, DocumentCardDetails, DocumentCardTitle, DocumentCardType, Link, mergeStyleSets,
    Pivot,
    PivotItem, ProgressIndicator,
    SelectionMode,
    Separator, Spinner, SpinnerSize,
    Stack,
    TextField, TooltipHost
} from "@fluentui/react";
import Contact from "./Tabs/Contact";
import {InitialProjectData} from "./Data/InitialProjectData";
import Participants from "./Tabs/Participants";
import Results from "./Tabs/Results";
import General from "./Tabs/General";
import Company from "./Tabs/Company";
import Finance from "./Tabs/Finance/Finance";
import {FontSizes} from "@fluentui/theme";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {fileIconClassNames} from "../../data/fileIconClassNames";
import storeFileMethod from "../../../api/storeFileMethod";
import attachDocumentMethod from "../../../api/attachDocumentMethod";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeDocumentMethod from "../../../api/removeDocumentMethod";
import sendPrepaymentDocumentsMethod from "../../../api/sendPrepaymentDocumentsMethod";
import getCurrentUserMethod from "../../../api/getCurrentUserMethod";
import CustomerCabinet from "./Tabs/CustomerCabinet";
import Links from "./Tabs/Links";
import attachBriefMethod from "../../../api/attachBriefMethod";
import Comments from "../../Shared/Comments";
import {datePickerStringsRu} from "../../data/datePickerStringsRu";
import {shortMonths} from "../../data/shortMonths";

export default function ViewProject() {
    const params = useParams();
    const [project, setProject] = useState({loaded: false, data: InitialProjectData});
    const [notification, setNotification] = useState({type: 'hidden', text: ''});
    const [user, setUser] = useState({id:null,teamId:null,type:'',contact:{firstName:'',lastName:'',email:''},security:{permissions:[]}});
    const [teamId, setTeamId] = useState(null);
    const navigate = useNavigate();
    const [percentComplete, setPercentComplete] = React.useState(0);
    const [briefPercentComplete, setBriefPercentComplete] = React.useState(0);

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    let canChangeArtDirector = false;
    let canChangeManager = false;
    let canChangeWorkers = false;
    let canSeeFreelancers = false;
    let canChangeFinance = false;
    let canChangeFinanceFact = false;
    user.security.permissions.forEach((permission) => {
        if (permission.key === 'change_project_artdir' && permission.type === 'ALL') {
            canChangeArtDirector = true;
        }

        if (permission.key === 'change_project_manager' && permission.type === 'ALL') {
            canChangeManager = true;
        }

        if (permission.key === 'change_project_workers' && permission.type === 'ALL') {
            canChangeWorkers = true;
        }

        if (permission.key === 'see_freelancers' && permission.type === 'ALL') {
            canSeeFreelancers = true;
        }

        if (permission.key === 'change_project_finance' && permission.type === 'ALL') {
            canChangeFinance = true;
        }

        if (teamId === null && permission.key === 'see_teams' && permission.type === 'TEAM') {
            setTeamId(permission.teamId);
        }

        if (permission.key === 'see_finance' && permission.type === 'ALL') {
            canChangeFinanceFact = true;
        }
    });

    const reloadData = () => setProject({loaded: false, data: {...project.data}});

    useEffect(() => {
        if (!project.loaded) {
            getSingleProjectMethod(params.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setProject({loaded: true, data: response});
            });
        }
    });

    // const getBriefs = () => project.data.files.filter((file) => file.type === 'BRIEF');
    const getBriefs = () => project.data.brief ? [project.data.brief] : [];
    // const getCommercialOffers = () => project.data.files.filter((file) => file.type === 'COMMERCIAL_OFFER');
    const getCommercialOffers = () => project.data.commercialOffer ? [project.data.commercialOffer] : [];
    // const getDocuments = () => project.data.files.filter((file) => file.type === 'DOCUMENT');
    const getDocuments = () => project.data.documents !== undefined ? project.data.documents : [];
    // const getSignedDocuments = () => project.data.files.filter((file) => file.type === 'SIGNED_DOCUMENT');
    const getSignedDocuments = () => project.data.signedDocuments !== undefined ? project.data.signedDocuments : [];

    const briefFileColumns = [
        {
            key: 'fileType',
            name: 'Тип файла',
            className: fileIconClassNames.fileIconCell,
            iconClassName: fileIconClassNames.fileIconHeaderIcon,
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item) => (
                <TooltipHost content={`${item.extension} file`} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                    <img src={getFileIcon(item.extension).url} className={fileIconClassNames.fileIconImg} alt={`${item.extension} file icon`} />
                </TooltipHost>
            ),
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>Посмотреть бриф</span>,
        },
    ];

    const commercialOfferFileColumns = [
        {
            key: 'fileType',
            name: 'Тип файла',
            className: fileIconClassNames.fileIconCell,
            iconClassName: fileIconClassNames.fileIconHeaderIcon,
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item) => (
                <TooltipHost content={`${item.extension} file`} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                    <img src={getFileIcon(item.extension).url} className={fileIconClassNames.fileIconImg} alt={`${item.extension} file icon`} />
                </TooltipHost>
            ),
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>{item.name}</span>,
        },
    ];

    let documentsRender = '';

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
    });

    // DOCUMENTS

    const handleDocumentChange = async (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            let response = await storeFileMethod(file, setPercentComplete);
            response = await attachDocumentMethod(project.data.id, response.id);

            if (response.status === 'ok') {
                reloadData();
            }
        }
    };

    const handleBriefChange = async (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            let response = await storeFileMethod(file, setBriefPercentComplete);
            response = await attachBriefMethod(project.data.id, response.id);

            if (response.status === 'ok') {
                reloadData();
            }
        }
    };

    const getFileIcon = (docType) => {
        return {
            docType,
            url: `https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/item-types/16/${docType}.svg`,
        };
    };

    const documentsColumns = [
        {
            key: 'fileType',
            name: 'Тип файла',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item) => (
                <TooltipHost content={`${item.extension} file`} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                    <img src={getFileIcon(item.extension).url} className={classNames.fileIconImg} alt={`${item.extension} file icon`} />
                </TooltipHost>
            ),
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>{item.name}</span>,
        },
        {
            key: 'status',
            name: 'Статус',
            fieldName: 'status',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item.type === 'SIGNED_DOCUMENT') {
                    return <></>;
                }

                return <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                {item.status === 'ATTACHED'
                    ? 'Виден клиенту в ЛК, можно отправить по e-mail'
                    : <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                        <FontIcon aria-label="Accept" iconName="Accept" />
                        <div>Виден клиенту и отправлено по e-mail</div>
                    </div>}
            </span>
            },
        },
        {
            key: 'action',
            name: 'Действие',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                // if (project.data.finance.prepayment.status === 'PAID') {
                //     return <></>;
                // }

                return <FontIcon aria-label="Delete" iconName="Delete" style={{cursor: 'pointer'}} onClick={async () => {
                    await removeDocumentMethod(project.data.id, item.id);
                    reloadData();
                }} />
            },
        },
    ];

    const sendDocuments = async () => {
        const response = await sendPrepaymentDocumentsMethod(project.data.id);
        if (response.exception || response.error) {
            console.log(response.error ? response.error.message : response.exception);
            setNotification({type: 'error', text: response.error});
            return;
        }

        if (response.status === 'ok') {
            reloadData();
        }
    };

    const hasNotSentDocuments = () => {
        let hasNotSent = false;

        getDocuments().forEach((item) => {
            if (item.status === 'ATTACHED') {
                hasNotSent = true;
            }
        });

        return hasNotSent;
    };

    documentsRender = <>
        {getDocuments().length > 0
            ? <DetailsList
                items={getDocuments()}
                compact={false}
                columns={documentsColumns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
            />
            : <></>
        }
        {getDocuments().length > 0
            ? <div style={{display: 'flex', alignItems: 'center', gap: 20, marginTop: 20}}>
                <div>
                    <input id="documentFileInput" type="file" onChange={handleDocumentChange} hidden />
                    {percentComplete > 0
                        ? <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            {percentComplete === 1
                                ? <ProgressIndicator description="Шаг 2 из 2. Обработка и сохранение файла в системе" />
                                : <ProgressIndicator description="Шаг 1 из 2. Загрузка файла" percentComplete={percentComplete} />
                            }
                        </Stack>
                        : <DefaultButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={() => document.getElementById("documentFileInput").click()}>
                            Прикрепить ещё файл
                        </DefaultButton>
                    }
                </div>
                {hasNotSentDocuments()
                    ? <PrimaryButton
                        text="Отправить документы по e-mail"
                        iconProps={{ iconName: 'Mail' }}
                        allowDisabledFocus
                        onClick={sendDocuments}
                    />
                    : <></>}
            </div>
            : <div style={{marginTop: 10}}>
                <input id="documentFileInput" type="file" onChange={handleDocumentChange} hidden />
                {percentComplete > 0
                    ? <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        {percentComplete === 1
                            ? <ProgressIndicator description="Шаг 2 из 2. Обработка и сохранение файла в системе" />
                            : <ProgressIndicator description="Шаг 1 из 2. Загрузка файла" percentComplete={percentComplete} />
                        }
                    </Stack>
                    : <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={() => document.getElementById("documentFileInput").click()}>
                        Прикрепить файл
                    </ActionButton>
                }
            </div>
        }
    </>;

    // END DOCUMENTS

    if (user.contact.email.length === 0 || project.data.id === null) {
        return <div>
            <Breadcrumbs id={project.data.id} code={project.data.code} goBack={() => navigate('/projects')} />
            <Notification type={notification.type} text={notification.text} />
            <Separator />
            <Pivot aria-label="Меню запроса">
                <PivotItem headerText="Информация по проекту">
                    <div style={{marginTop: 50}}>
                        <Spinner size={SpinnerSize.large} />
                    </div>
                </PivotItem>
            </Pivot>
        </div>;
    }

    const subprojectColumns = [
        {
            key: 'code',
            name: 'Номер',
            fieldName: 'code',
            maxWidth: 80,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: 'startedAt',
            name: 'Дата старта',
            fieldName: 'startedAt',
            maxWidth: 20,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.startedAt === null) {
                    return <div>не указано</div>;
                }

                const date = new Date(item.startedAt);

                if ((new Date()).getFullYear() === date.getFullYear()) {
                    return <div>{date.getDate()} {datePickerStringsRu.monthDeclinations[date.getMonth()]}</div>;
                }

                return <div>{date.getDate()} {shortMonths[date.getMonth()]} {date.getFullYear()}</div>;
            }
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            maxWidth: 200,
            minWidth: 140,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.name === null) {
                    return <div>не указано</div>;
                }

                return <div>{item.name}</div>;
            }
        },
        {
            key: 'status',
            name: 'Статус',
            fieldName: 'status',
            maxWidth: 90,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.status === 'IN_PROGRESS') {
                    return <div>В работе</div>;
                }

                if (item.status === 'HOLD') {
                    return <div>Холд</div>;
                }

                return <div>Закрыт</div>;
            }
        },
        {
            key: 'milestone',
            name: 'Этап',
            fieldName: 'milestone',
            maxWidth: 90,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.milestone === null) {
                    return <div>не указано</div>;
                }

                return <div>{item.milestone}</div>;
            }
        },
        {
            key: 'service',
            name: 'Услуги',
            fieldName: 'service',
            maxWidth: 90,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item.services.length === 0) {
                    return <div>не выбраны</div>;
                }

                const services = item.services.map((service) => <div>{service.name}</div>);

                return <div>{services}</div>
            }
        },
        {
            key: 'manager',
            name: 'Менеджер',
            fieldName: 'manager',
            isRowHeader: true,
            data: 'string',
            minWidth: 120,
            isPadded: true,
            onRender: (item) => {
                if (item.manager === null) {
                    return <div>нет</div>;
                }

                return <div>{item.manager}</div>;
            }
        },
        {
            key: 'artdir',
            name: 'Арт-директор',
            fieldName: 'artdir',
            isRowHeader: true,
            data: 'string',
            minWidth: 120,
            isPadded: true,
            onRender: (item) => {
                if (item.artdir === null) {
                    return <div>нет</div>;
                }

                return <div>{item.artdir}</div>;
            }
        },
    ];

    return (
        <div>
            <Breadcrumbs id={project.data.id} code={project.data.code} goBack={() => navigate('/projects')} />
            <Notification type={notification.type} text={notification.text} />
            <Separator />
            <Pivot aria-label="Меню запроса">
                <PivotItem headerText="Информация по проекту">
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {user.type === 'FREELANCER' ? <></> : <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 10, fontWeight: 'bold' }}>Основное</div>}
                        <General userType={user.type} currentUser={user} project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} />
                    </div>
                    {user.type === 'FREELANCER'
                        ? <></>
                        : <>
                            <Separator />
                            <div style={{marginBottom: 20}}>
                                <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 10, fontWeight: 'bold' }}>Участники проекта</div>
                                {teamId === null
                                    ? <Participants currentUser={user} canSeeFreelancers={canSeeFreelancers} canChangeManager={canChangeManager} canChangeWorkers={canChangeWorkers} canChangeArtDirector={canChangeArtDirector} project={project} reloadData={reloadData} setNotification={setNotification} />
                                    : <Participants currentUser={user} canSeeFreelancers={canSeeFreelancers} canChangeManager={canChangeManager} canChangeWorkers={canChangeWorkers} canChangeArtDirector={canChangeArtDirector} project={project} reloadData={reloadData} setNotification={setNotification} />
                                }
                            </div>
                        </>
                    }
                    {user.type === 'FREELANCER' || project.data.subprojects.length === 0
                        ? <></>
                        : <>
                            <Separator />
                            <div style={{marginBottom: 20}}>
                                <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 10, fontWeight: 'bold' }}>Подпроекты</div>
                                <DetailsList
                                    items={project.data.subprojects}
                                    compact={false}
                                    columns={subprojectColumns}
                                    selectionMode={SelectionMode.none}
                                    getKey={(item, index) => item.key}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    onRenderRow={(props, Row) => {
                                        return <div style={{cursor: 'pointer'}} onClick={(e) => {
                                            navigate('/projects/' + props.item.id);
                                            reloadData();
                                        }}>
                                            <Row {...props} />
                                        </div>;
                                    }}
                                />
                            </div>
                        </>
                    }
                    {user.type === 'FREELANCER'
                        ? <></>
                        : <>
                            <Separator />
                            <div style={{marginTop: 40, marginBottom: 50}}>
                                <div style={{ fontSize: FontSizes.size14, marginBottom: 10, fontWeight: 'bold' }}>Комментарии от Newbiz</div>
                                <Comments project={project} reloadData={reloadData} type="REQUEST" setNotification={setNotification} />
                            </div>
                        </>
                    }
                </PivotItem>
                {canChangeFinance
                    ? <PivotItem headerText="Финансы">
                        <div style={{marginTop: 20}}>
                            <Finance project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} canChangeFinanceFact={canChangeFinanceFact} />
                        </div>
                    </PivotItem>
                    : <></>
                }
                {user.type === 'FREELANCER'
                    ? <></>
                    : <PivotItem headerText="Данные клиента">
                        <div style={{marginTop: 20}}>
                            <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 10, fontWeight: 'bold' }}>Контакты</div>
                            <Contact project={project} setProject={setProject} setNotification={setNotification} />
                        </div>
                        <div style={{marginTop: 20}}>
                            <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 30, fontWeight: 'bold' }}>Реквизиты</div>
                            <Company project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} />
                        </div>
                    </PivotItem>
                }
                <PivotItem headerText="Файлы и ссылки">
                    <div style={{marginTop: 20}}>
                        <Pivot aria-label="Меню файлов">
                            {user.type === 'FREELANCER'
                                ? <PivotItem headerText="Бриф">
                                    <div style={{marginTop: 20}}>
                                        {getBriefs().length === 0
                                            ? <div>
                                                <div style={{ fontSize: FontSizes.size14, marginBottom: 10 }}>Бриф не прикреплён</div>
                                            </div>
                                            : <DetailsList
                                                items={getBriefs()}
                                                compact={false}
                                                columns={briefFileColumns}
                                                selectionMode={SelectionMode.none}
                                                getKey={(item, index) => item.key}
                                                setKey="none"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                isHeaderVisible={false}
                                            />
                                        }
                                    </div>
                                </PivotItem>
                                : <PivotItem headerText="Документы">
                                    <div style={{marginTop: 20}}>
                                        <Stack tokens={{ childrenGap: 20 }}>
                                            {getCommercialOffers().length === 0
                                                ? <></>
                                                : <div>
                                                    <div style={{ fontSize: FontSizes.size14, marginBottom: 10, fontWeight: 'bold' }}>Согласованное КП</div>
                                                    <DetailsList
                                                        items={getCommercialOffers()}
                                                        compact={false}
                                                        columns={commercialOfferFileColumns}
                                                        selectionMode={SelectionMode.none}
                                                        getKey={(item, index) => item.key}
                                                        setKey="none"
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        isHeaderVisible={false}
                                                    />
                                                </div>
                                            }
                                            {getBriefs().length === 0
                                                ? <div>
                                                    <div style={{ fontSize: FontSizes.size14, marginBottom: 10, fontWeight: 'bold' }}>Бриф не заполнен</div>
                                                    <div style={{marginBottom: 20}}>Вы можете скопировать персональную ссылку ниже и отправить клиенту.<br/>После заполнения бриф появится в этом разделе.</div>
                                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                                        <DefaultButton text="Скопировать ссылку" iconProps={{ iconName: 'Copy' }} onClick={(e) => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-brief?projectId=' + project.data.id)} />
                                                    </Stack>
                                                    <div style={{marginTop: 20}}>Или вы можете прикрепить иной заполненный бриф</div>
                                                    <div style={{marginTop: 10}}>
                                                        <input id="briefFileInput" type="file" onChange={handleBriefChange} hidden />
                                                        {briefPercentComplete > 0
                                                            ? <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                                                {briefPercentComplete === 1
                                                                    ? <ProgressIndicator description="Шаг 2 из 2. Обработка и сохранение файла в системе" />
                                                                    : <ProgressIndicator description="Шаг 1 из 2. Загрузка файла" percentComplete={briefPercentComplete} />
                                                                }
                                                            </Stack>
                                                            : <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={() => document.getElementById("briefFileInput").click()}>
                                                                Прикрепить бриф
                                                            </ActionButton>
                                                        }
                                                    </div>
                                                </div>
                                                : <div>
                                                    <div style={{ fontSize: FontSizes.size14, fontWeight: 'bold', marginBottom: 10 }}>Бриф</div>
                                                    <DetailsList
                                                        items={getBriefs()}
                                                        compact={false}
                                                        columns={briefFileColumns}
                                                        selectionMode={SelectionMode.none}
                                                        getKey={(item, index) => item.key}
                                                        setKey="none"
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        isHeaderVisible={false}
                                                    />
                                                </div>
                                            }
                                            <div>
                                                <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 15, fontWeight: 'bold' }}>Договор, приложение, счёт и т.п.</div>
                                                {documentsRender}
                                                {/*{project.data.finance.summaryPrice === null ? <div>Если оплата будет по договору, нужно будет прикрепить договор и другие документы сюда.</div> : ''}*/}
                                                {/*{project.data.finance.summaryPrice !== null && project.data.finance.prepayment.type !== 'CLASSIC' ? <div>Оплата онлайн. Договор не нужен.</div> : ''}*/}
                                                {/*{project.data.finance.summaryPrice !== null && project.data.finance.prepayment.type === 'CLASSIC' ? documentsRender : ''}*/}
                                            </div>
                                            {getSignedDocuments().length > 0
                                                ? <div style={{ marginBottom: 40}}>
                                                    <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 15, fontWeight: 'bold' }}>Подписанные документы от клиента</div>
                                                    <DetailsList
                                                        items={getSignedDocuments()}
                                                        compact={false}
                                                        columns={documentsColumns}
                                                        selectionMode={SelectionMode.none}
                                                        getKey={(item, index) => item.key}
                                                        setKey="none"
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        isHeaderVisible={false}
                                                    />
                                                </div>
                                                : <></>
                                            }
                                        </Stack>
                                    </div>
                                </PivotItem>
                            }
                            {user.type === 'FREELANCER'
                                ? <></>
                                : <PivotItem headerText="Исходники">
                                    <div style={{marginTop: 20}}>
                                        <Results userType={user.type} project={project} reloadData={reloadData} type="PROJECT_FILE" />
                                    </div>
                                </PivotItem>
                            }
                            <PivotItem headerText="Драфты">
                                <div style={{marginTop: 20}}>
                                    <Results userType={user.type} project={project} reloadData={reloadData} type="DRAFT" />
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Файлы от клиента">
                                <div style={{marginTop: 20}}>
                                    <Results userType={user.type} project={project} reloadData={reloadData} type="CUSTOMER_FILE" />
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Ссылки">
                                <div style={{marginTop: 20}}>
                                    <Links userType={user.type} project={project} reloadData={reloadData} setNotification={setNotification} />
                                </div>
                            </PivotItem>
                        </Pivot>
                    </div>
                </PivotItem>
                <PivotItem headerText="Комментарии">
                    <div style={{marginTop: 20}}>
                        <Comments project={project} reloadData={reloadData} type="PROJECT" setNotification={setNotification} />
                    </div>
                </PivotItem>
                {user.type === 'FREELANCER'
                    ? <></>
                    : <PivotItem headerText="Кабинет клиента">
                        <div style={{marginTop: 20}}>
                            <CustomerCabinet project={project} reloadData={reloadData} setNotification={setNotification} />
                        </div>
                    </PivotItem>
                }
            </Pivot>
        </div>
    );
}
